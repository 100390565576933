import debounce from 'lodash.debounce'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { calculateArea, calculateWeight, getSpread } from '../util/calc'
import Button from './Button'
import IconButton from './IconButton'
import MinusIcon from './icons/minus'
import PlusIcon from './icons/plus'

const rowHeaders = [
    'PCODE',
    'BOX',
    'MATERIAL',
    'L',
    'B',
    'H',
    'H_EXTRA',
    'AMOUNT',
    'FPRICE',
    'PRINT',
    'PRODUCTION_CODE',
    'DENSITY',
    'WEIGHT',
    'AREA',
]

const OrderItemsList = ({
    items,
    onRowSelect,
    onSendProduction,
    onAddRow,
    onDuplicateRow,
    onDeleteRow,
}) => {

    const { t } = useTranslation()
    const [selectedRow, setSelectedRow] = useState()
    const tableRef = useRef()

    useEffect(() => {
        setLastPosition()
    }, [])

    useEffect(() => {
        window.addEventListener('keydown', handleArrowKey)

        return () => {
            window.removeEventListener('keydown', handleArrowKey)
        }
    }, [selectedRow])

    const setLastPosition = () => {
        if (items?.length > 0) {
            const item = items[items.length - 1]
            handleRowSelect(item)
            return
        }
        handleRowSelect(null)
        setTimeout(() => {
            tableRef.current?.querySelector(`.order-row--selected`)?.scrollIntoView(false)
        }, 1000)
    }

    const handleRowSelect = (row) => {
        setSelectedRow(row)
        if (onRowSelect) onRowSelect(row)
    }

    const getRowColumnValue = (row, field) => {
        if (field === 'PCODE') {
            return `P-${row.ID}-${row.CREATION_YEAR}`
        }
        if (field === 'WEIGHT') {
            return calculateWeight(row.AL, row.AB, row.DENSITY)
        }
        if (field === 'AREA') {
            const spread = getSpread(row)
            const area = (calculateArea(spread.length, spread.width, parseInt(row?.PER_SHEET))).toFixed(4)
            return area ? area : '0'
        }
        if (field === 'FPRICE') {
            return (parseFloat(row.FPRICE) + (!isNaN(parseFloat(row.TRANSPORT)) ? parseFloat(row.TRANSPORT) : 0)).toFixed(3)
        }
        return row[field]
    }

    const handleDuplicate = async () => {
        if (!onDuplicateRow) return
        const newRow = await onDuplicateRow(selectedRow)
        if (newRow) handleRowSelect(newRow)
    }

    const handleDelete = async () => {
        if (!onDeleteRow) return
        await onDeleteRow(selectedRow)
        setLastPosition()
    }

    const handleAdd = async () => {
        if (!onAddRow) return
        const newRow = await onAddRow()
        if (newRow) handleRowSelect(newRow)
    }

    const handleProduction = () => {
        if (!onSendProduction) return
        onSendProduction()
    }

    const handleArrowKey = (e) => {
        if (e.key === 'ArrowUp') {
            e.preventDefault()
        
            const currentIndex = items.findIndex(x => x.ID === selectedRow?.ID)
            let row = null

            const index = currentIndex - 1
            if (index <= items.length - 1 && index >= 0) {
                row = items[index]
                handleRowSelect(row)
            }
        }
        if (e.key === 'ArrowDown') {
            e.preventDefault()
        
            const currentIndex = items.findIndex(x => x.ID === selectedRow?.ID)
            let row = null
            
            const index = currentIndex + 1
            if (index <= items.length - 1 && index >= 0) {
                row = items[index]
                handleRowSelect(row)
            }
        }

        tableRef.current?.querySelector(`.order-row--selected`)?.scrollIntoView(false)
    }

    const handleMwheel = (e) => {
        const delta = Math.sign(e.deltaY)
        const currentIndex = items.findIndex(x => x.ID === selectedRow?.ID)

        console.log('currnt index', currentIndex)
        let row = null
        

        if (delta <= 0) {
            const index = currentIndex - 1
            if (index <= items.length - 1 && index >= 0) {
                console.log('moving up index:', index)
                row = items[index]
                handleRowSelect(row)
            }
        }
        if (delta > 0) {
            const index = currentIndex + 1
            if (index <= items.length - 1 && index >= 0) {
                console.log('moving down index:', index)
                row = items[index]
                handleRowSelect(row)
            }
        }

        tableRef.current?.querySelector(`.order-row--selected`)?.scrollIntoView(false)
    }

    const debouceMwheel = useMemo( () => debounce(handleMwheel, 50), [selectedRow])

    return (
        <div className='table-container'>
            <div className='table-controls'>
                <div className='field-row'>
                    <IconButton
                        label={'Lisa'}
                        icon={<PlusIcon />}
                        onClick={handleAdd}
                    />
                    <IconButton
                        label={'Kustuta'}
                        icon={<MinusIcon />}
                        disabled={!selectedRow}
                        onClick={handleDelete}
                    />
                </div>
                <Button
                    label={t('Tootmisesse')}
                    disabled={items?.length < 0}
                    onClick={handleProduction}
                />

                <Button
                    label={'Kopeeri'}
                    onClick={handleDuplicate}
                    disabled={!selectedRow}
                />
            </div>
            { items?.length > 0 ?
                <div ref={tableRef} className='table-wrapper'>
                    <table className='order-rows'>
                        <thead
                            className='order-rows--header'
                            style={{
                                gridTemplateColumns: `repeat(${rowHeaders.length}, 1fr)`
                            }}
                        >
                            <tr>
                            {
                                rowHeaders.map((headerItem) =>
                                    <th key={`header-${headerItem}`} className='order-rows--header-item'>
                                        { t(headerItem) }
                                    </th>
                                )
                            }
                            </tr>
                        </thead>
                        <tbody className='order-rows--content'>
                            {
                                items.map((row, index) =>
                                    <tr
                                        onClick={(e) => {
                                            if (e.target.classList.contains('btn')) return
                                            handleRowSelect(row)
                                        }}
                                        key={`order-row-${index}`}
                                        className={`order-row${selectedRow?.ID === row.ID ? ' order-row--selected' : ''}`}
                                        style={{
                                            gridTemplateColumns: `repeat(${rowHeaders.length}, 1fr)`
                                        }}
                                        data-id={row.ID}
                                    >
                                        {
                                            rowHeaders.map((headerItem, itemIndex) =>
                                                <td
                                                    key={`order-row-${index}-${itemIndex}`}
                                                    className='order-row--item'
                                                >
                                                    { getRowColumnValue(row, headerItem) }
                                                </td>
                                            )
                                        }
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
                :
                <div className='empty-result'>
                    { t('Tellimuse read puuduvad') }
                </div>
            }
        </div>
    )
}

export default OrderItemsList