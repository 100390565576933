import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../providers/Auth'
import Button from './Button'

const AppHeader = () => {

    const { t } = useTranslation()
    const { user, signOut, setTheme, theme } = useAuth()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const [navOpen, setNavOpen] = useState(false)

    const toggleMenu = () => setNavOpen(!navOpen)
    const closeMenu = () => setNavOpen(false)

    return (
        <header className={`app-header${navOpen ? ' nav-open' : ''}`}>
            <div className='app-header--inner'>
                <div className='brand'>
                    <div onClick={() => navigate('/')} className='brand-logo'></div>
                </div>
                <nav>
                    <div className='nav-sub'>
                        <a className={['/orders', '/sales', '/productions', '/invoices', '/deliverynotes'].includes(pathname) ? 'active' : ''}>
                            { t('Tootmine & Hinnapakkumised') }
                        </a>
                        <div className='nav-sub--dropdown'>
                            <Link onClick={closeMenu} className={pathname === '/orders' ? 'active' : ''} to={'/orders'}>
                                { t('Hinnapakkumised') }
                            </Link>
                            <Link onClick={closeMenu} className={pathname === '/sales' ? 'active' : ''} target='Müük' to={'/sales'}>
                                { t('Müük') }
                            </Link>
                            <Link onClick={closeMenu} className={pathname === '/productions' ? 'active' : ''} target='Tootmine' to={'/productions'}>
                                { t('Tootmine') }
                            </Link>
                            <Link onClick={closeMenu} className={pathname === '/invoices' ? 'active' : ''} to={'/invoices'}>
                                { t('Arved (välismaised)') }
                            </Link>
                            <Link onClick={closeMenu} className={pathname === '/deliverynotes' ? 'active' : ''} to={'/deliverynotes'}>
                                { t('Saatelehed (kodumaised)') }
                            </Link>
                        </div>
                    </div>
                    <div className='nav-sub'>
                        <a className={['/material-orders', '/material-order', '/material-report'].includes(pathname) ? 'active' : ''}>
                            { t('Materjali tellimine') }
                        </a>
                        <div className='nav-sub--dropdown'>
                            <Link onClick={closeMenu} className={pathname === '/material-orders' ? 'active' : ''} to={'/material-orders'}>
                                { t('Materjali tellimine') }
                            </Link>
                            <Link onClick={closeMenu} className={pathname === '/material-report' ? 'active' : ''} to={'/material-report'}>
                                { t('Laekumiste aruanne') }
                            </Link>
                        </div>
                    </div>
                    <div className='nav-sub'>
                        <a className={['/report-basic','/report-yearly','/report-sales','/report-company', '/report-quotations', '/report-one-company', '/report-user'].includes(pathname) ? 'active' : ''}>
                            { t('Aruanded') }
                        </a>
                        <div className='nav-sub--dropdown'>
                            <Link onClick={closeMenu} className={pathname === '/lost-clients' ? 'active' : ''} to={'/lost-clients'}>
                                { t('Kadunud kliendid') }
                            </Link>
                            <Link onClick={closeMenu} className={pathname === '/report-one-company' ? 'active' : ''} to={'/report-one-company'}>
                                { t('Ühe firma realisatsioon') }
                            </Link>
                            {
                                user.user === 'BORISS' &&
                                <>
                                    <Link onClick={closeMenu} className={pathname === '/report-basic' ? 'active' : ''} to={'/report-basic'}>
                                        { t('Realisatsioon') }
                                    </Link>
                                    <Link onClick={closeMenu} className={pathname === '/report-yearly' ? 'active' : ''} to={'/report-yearly'}>
                                        { t('Realisatsioon aastate lõikes') }
                                    </Link>
                                    <Link onClick={closeMenu} className={pathname === '/report-user' ? 'active' : ''} to={'/report-user'}>
                                        { t('Realisatsioon eraldi') }
                                    </Link>
                                    <Link onClick={closeMenu} className={pathname === '/report-sales' ? 'active' : ''} to={'/report-sales'}>
                                        { t('Realisatsiooni diagramm') }
                                    </Link>
                                    <Link onClick={closeMenu} className={pathname === '/report-company' ? 'active' : ''} to={'/report-company'}>
                                        { t('Firmade realisatsiooni edetabel') }
                                    </Link>
                                    <Link onClick={closeMenu} className={pathname === '/report-quotations' ? 'active' : ''} to={'/report-quotations'}>
                                        { t('Hinnapakkumised') }
                                    </Link>
                                    <Link onClick={closeMenu} className={pathname === '/report-user-quotations' ? 'active' : ''} to={'/report-user-quotations'}>
                                        { t('Hinnapakkumiste diagramm') }
                                    </Link>
                                </>
                            }
                        </div>
                    </div>
                    <div className='nav-sub'>
                        <a className={['/users', '/clients', '/factories'].includes(pathname) ? 'active' : ''}>
                            { t('Püsiandmed') }
                        </a>
                        <div className='nav-sub--dropdown'>
                            <Link onClick={closeMenu} className={pathname === '/users' ? 'active' : ''} to={'/users'}>
                                { t('Kasutajad') }
                            </Link>
                            <Link onClick={closeMenu} className={pathname === '/clients' ? 'active' : ''} to={'/clients'}>
                                { t('Kliendid') }
                            </Link>
                            <Link onClick={closeMenu} className={pathname === '/factories' ? 'active' : ''} to={'/factories'}>
                                { t('Paberitehased') }
                            </Link>
                            <Link onClick={closeMenu} className={pathname === '/boxes' ? 'active' : ''} to={'/boxes'}>
                                { t('Karbid') }
                            </Link>
                            <Link onClick={closeMenu} className={pathname === '/classes' ? 'active' : ''} to={'/classes'}>
                                { t('Klassid') }
                            </Link>
                            <Link onClick={closeMenu} className={pathname === '/extra-products' ? 'active' : ''} to={'/extra-products'}>
                                { t('Lisatooted') }
                            </Link>
                            <Link onClick={closeMenu} className={pathname === '/materials' ? 'active' : ''} to={'/materials'}>
                                { t('Materjalid') }
                            </Link>
                            <Link onClick={closeMenu} className={pathname === '/constants' ? 'active' : ''} to={'/constants'}>
                                { t('Parameetrid') }
                            </Link>
                        </div>
                    </div>
                    {
                        user &&
                        <>
                            <div className='nav-sub'>
                                <div className='app-header--info'>
                                    <span className='app-header--info-name'>{ user.name }</span>
                                    <span className='app-header--info-title'>{ user.title }</span>
                                </div>
                                <div className='nav-sub--dropdown'>
                                    <a className={theme === 'dark' ? 'active' : ''} onClick={() => setTheme('dark')}>
                                        { t('Tume välimus') }
                                    </a>
                                    <a className={theme === 'light' ? 'active' : ''} onClick={() => setTheme('light')}>
                                        { t('Hele välimus') }
                                    </a>
                                </div>
                            </div>
                            <div className='app-header--actions'>
                                <Button
                                    label={t('Sign out')}
                                    onClick={signOut}
                                />
                            </div>
                        </>
                    }
                </nav>
                <div className="menu-btn" onClick={toggleMenu}>
                    <div></div>
                </div>
            </div>
        </header>
    )
}

export default AppHeader