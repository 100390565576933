import gql from 'graphql-tag'

export const ProductionUpdated = gql`
  subscription productionUpdated($id: Int!) {
    productionUpdated(id: $id) {
      ID
    }
  }
`;

export const ProductionCreated = gql`
  subscription productionCreated {
    productionCreated {
      ID
      ORDERNO
      ORDER_DATE
      DEADLINE
      NAME
      TELLIMUSNO
      KLIENT_BOXCODE
      START_DATE
      NEW_DEADLINE
      LAMINAATOR
      ALIIM
      _3LIIM
      KLIIM
      SLOTTER
      INLINE
      SRE
      TIGEL
      FLEXO
      SLITTER
      TOOTLEMINE
      FIN_DATE
      SHIPPED
      ALUSEID
      RKOGUS
      ALUS_MOOT
      KOMMENTAAR
      BOX
      KOGUS
      TRYKK
      MAT_ORDER_ID
      MOOT
      MATERIAL
      PRINTOUT
      KLIENT_ORDER
      PROD_COMMENT
      STOCK_MAT
      STOCK_MOOT
      MAT_LOADED
      CREATOR
      KLIENT_ID
      TRANSPORDIFIRMA
      ADDRESS
      OFSET
      CONTACT_ID
      EKSTSEN
      PREMAID
      PRODUCTION_CODE
      VERONA
      TITAN
      EXPORTED
      STANTS
      KLISHEE
      TRANSPORT
      PALLET
      PARTIAL_AMOUNT
      AFD
      FPR
      FACTORY_NAME
      MAT_AMOUNT
      MAT_ORDERS_CNT
      SIZE
      MAT_NUUT
      MAT_DELIVERY
      ARRIVED_AMOUNT
      DIFF_AMOUNT
      HALDURNAME
      HALDUREMAIL
      HALDUREMAIL_CC
      HALDURPHONE
      HALDURFAX
      SISESTAJA_EMAIL
      SISESTAJA_NIMI
      HINNAPAKKUMINE
      ETTEMAKS
      DEPT
      MAKSEAEG
      TRANSPORT_DATE
      PRICE
      KLIENT_INFORMED
      CLIENTEMAIL
      CLIENTPHONE
      CLIENTADDRESS
      VAT
      TYPE
    }
  }
`;

export const ProductionsUpdated = gql`
  subscription productionsUpdated {
    productionsUpdated {
      ID
      ORDERNO
      ORDER_DATE
      DEADLINE
      NAME
      TELLIMUSNO
      KLIENT_BOXCODE
      START_DATE
      NEW_DEADLINE
      LAMINAATOR
      ALIIM
      _3LIIM
      KLIIM
      SLOTTER
      INLINE
      SRE
      TIGEL
      FLEXO
      SLITTER
      TOOTLEMINE
      FIN_DATE
      SHIPPED
      ALUSEID
      RKOGUS
      ALUS_MOOT
      KOMMENTAAR
      BOX
      KOGUS
      TRYKK
      MAT_ORDER_ID
      MOOT
      MATERIAL
      PRINTOUT
      KLIENT_ORDER
      PROD_COMMENT
      STOCK_MAT
      STOCK_MOOT
      MAT_LOADED
      CREATOR
      KLIENT_ID
      TRANSPORDIFIRMA
      ADDRESS
      OFSET
      CONTACT_ID
      EKSTSEN
      PREMAID
      PRODUCTION_CODE
      VERONA
      TITAN
      EXPORTED
      STANTS
      KLISHEE
      TRANSPORT
      PALLET
      PARTIAL_AMOUNT
      AFD
      FPR
      FACTORY_NAME
      MAT_AMOUNT
      MAT_ORDERS_CNT
      SIZE
      MAT_NUUT
      MAT_DELIVERY
      ARRIVED_AMOUNT
      DIFF_AMOUNT
      HALDURNAME
      HALDUREMAIL
      HALDUREMAIL_CC
      HALDURPHONE
      HALDURFAX
      SISESTAJA_EMAIL
      SISESTAJA_NIMI
      HINNAPAKKUMINE
      ETTEMAKS
      DEPT
      MAKSEAEG
      TRANSPORT_DATE
      PRICE
      KLIENT_INFORMED
      CLIENTEMAIL
      CLIENTPHONE
      CLIENTADDRESS
      VAT
      TYPE
    }
  }
`;

export const OrdersUpdated = gql`
  subscription ordersUpdated {
    ordersUpdated {
      ID
      ORDERNO
      ODATE
      CREATOR
      TEXT
      PROD_DATE
      CONTACT_ID
      CLIENT_ID

      contact {
        ID
        CONTACT
        PHONE
        EMAIL
        SKYPE
        MOBILE
      }
      creator {
        ID
        UNAME
        RNAME
        EMAIL
        PHONE
        FAX
        PROFESSION
      }
      client {
        ID
        NAME
      }
    }
  }
`;

export const OrderCreated = gql`
  subscription orderCreated {
    orderCreated {
      ID
      ORDERNO
      ODATE
      CREATOR
      TEXT
      PROD_DATE
      CONTACT_ID
      CLIENT_ID

      contact {
        ID
        CONTACT
        PHONE
        EMAIL
        SKYPE
        MOBILE
      }
      creator {
        ID
        UNAME
        RNAME
        EMAIL
        PHONE
        FAX
        PROFESSION
      }
      client {
        ID
        NAME
      }
    }
  }
`;

export const OrderRowUpdated = gql`
  subscription orderRowUpdated ($orderId: Int!) {
    orderRowUpdated (orderId: $orderId) {
      ID
      ORDER_ID
      BOX
      MATERIAL
      MAT_PRICE
      L
      B
      H
      PRINT
      AMOUNT
      AL
      AB
      FPRICE
      PROFIT
      LPRICE
      BOXNAME
      MAT_L
      MAT_B
      NUUT1
      NUUT2
      NUUT3
      BOXES
      LISTS
      COLOR1
      COLOR2
      COLOR3
      COLOR4
      PANTONE1
      PANTONE2
      PANTONE3
      PANTONE4
      MATRIITS
      KLISHEE
      COMMENT
      PICTURE
      ROTATS
      STATUS
      PROD_DATE
      FILE1
      FILE2
      NUUT4
      NUUT5
      TRANSPORT
      H_EXTRA
      FACTORY
      SLITTER
      FLEXO
      TIGEL
      SRE
      INLINE
      SLOTTER
      KLIIM
      ALIIM
      _3LIIM
      LAMINAATOR
      TOOTLEMINE
      NUUDID
      PROD_CODE
      PRODUCTION_CODE
      MAT_TYPE
      CONSTR_NAME
      CONSTR_DATE
      VERONA
      TITAN
      CREATION_YEAR
      PER_SHEET
      RECEIVER
      LP_L
      LP_B
      TT_L
      TT_B
      CLASSTYPE
      DENSITY
      AFD
      FPR
      EKSTSEN
      PREMAID
      materialItem {
        MARK
        MARK2
      }
    }
  }
`;

export const MaterialCreated = gql`
  subscription materialCreated {
    materialCreated {
      ID
      CREATED_DATE
      ORDERNO
      REMARK
      ORDERED
      ORDER_NUMBER

      orderRows {
        ID
        ORDER_ID
        SIZE
        SIZE_BOX
        MARK
        MARK_COMMENT
        AMOUNT
        DELIVERY_DATE
        NUUT
        REMARK
        PRICE
        CLIENT_ID
        BOX_DATE
        SPEED
        BOX_SIZE
        BOX_TYPE
        PROD_ID
        FINISHED_DATE
        PRIORITY
        production {
          ORDERNO
          BOX
          MOOT
          DEADLINE
          client {
            NAME
          }
        }
      }
      factory {
        ID
        NAME
        ADDRESS
        EMAIL
        CONTACT
      }
      creator {
        RNAME
      }
    }
  }
`;

export const MaterialsUpdated = gql`
  subscription materialsUpdated {
    materialsUpdated {
      ID
      CREATED_DATE
      ORDERNO
      REMARK
      ORDERED
      ORDER_NUMBER

      orderRows {
        ID
        ORDER_ID
        SIZE
        SIZE_BOX
        MARK
        MARK_COMMENT
        AMOUNT
        DELIVERY_DATE
        NUUT
        REMARK
        PRICE
        CLIENT_ID
        BOX_DATE
        SPEED
        BOX_SIZE
        BOX_TYPE
        PROD_ID
        FINISHED_DATE
        PRIORITY
        production {
          ORDERNO
          BOX
          MOOT
          DEADLINE
          client {
            NAME
          }
        }
      }
      factory {
        ID
        NAME
        ADDRESS
        EMAIL
        CONTACT
      }
      creator {
        RNAME
      }
    }
  }
`;

export const DeliveryNoteRowCreated = gql`
  subscription deliveryNoteRowCreated($prodId: Int!) {
    deliveryNoteRowCreated(prodId: $prodId) {
      ID
      PRODUCT_ID
      INVOICE_ID
      AMOUNT
    }
  }
`;