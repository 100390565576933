import { useMutation, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CreateMaterialDelivery, RemoveMaterialDelivery, UpdateMaterialDelivery } from '../graphql/mutations'
import { GetMaterialDeliveries } from '../graphql/queries'
import { useNotification } from '../providers/Notification'
import { formatDate, parseDate } from '../util/format'
import Button from './Button'
import DateInput from './DateInput'
import Input from './Input'

const MaterialDeliveryRows = ({ prodId }) => {

    const { t } = useTranslation()
    const { dispatch } = useNotification()
    const [rows, setRows] = useState([])
    const rowHeaders = [
        {
            value: 'DELIVERY_DATE',
            label: t('Saabumine'),
        },
        {
            value: 'AMOUNT',
            label: t('Kogus'),
        },
        {
            value: 'INVOICE',
            label: t('Arve/saatelehe nr.'),
        },
    ]
    const [date, setDate] = useState(null)
    const [amount, setAmount] = useState(null)
    const [invoice, setInvoice] = useState(null)

    const [createRow] = useMutation(CreateMaterialDelivery)
    const [updateRow] = useMutation(UpdateMaterialDelivery)
    const [removeRow] = useMutation(RemoveMaterialDelivery)

    const { refetch } = useQuery(GetMaterialDeliveries, {
        variables: {
            prodId: parseInt(prodId),
        },
        onCompleted: (data) => {
            const { getMaterialDeliveries } = data
            if (getMaterialDeliveries) setRows(getMaterialDeliveries)
        }
    })

    const handleAddDelivery = async () => {
        if (!prodId || !date || !invoice) {
            dispatch({
                type: 'ADD',
                payload: {
                    content: t('Missing fields'),
                    type: 'error',
                },
            })
            return
        }
        try {
            await createRow({
                variables: {
                    data: {
                        INVOICE: invoice,
                        PROD_ID: parseInt(prodId),
                        AMOUNT: amount,
                        DELIVERY_DATE: date,
                    },
                },
            })
            setAmount(null)
            setDate(null)
            setInvoice(null)
            refetch()
        } catch (err) {
            console.log('handleAddRow', err)
        }
    }

    const handleUpdateDelivery = async ({
        id,
        date,
        invoice,
        amount,
    }) => {
        try {
            const data = {
                PROD_ID: parseInt(prodId),
            }

            if (date) data.DELIVERY_DATE = date
            if (invoice) data.INVOICE = invoice
            if (amount) data.AMOUNT = parseInt(amount)

            await updateRow({
                variables: {
                    id: parseInt(id),
                    data,
                },
            })
            refetch()
        } catch (err) {
            console.log('handleUpdateRow', err)
        }
    }

    const handleDeleteRow = async (id) => {
        try {
            await removeRow({
                variables: {
                    id: parseInt(id),
                },
            })
            refetch()
        } catch (err) {
            console.log('handleDeleteRow', err)
        }
    }

    const getColumnValue = (row, field) => {
        if (field === 'DELIVERY_DATE') return (
            <DateInput
                placeholder={t('Saabumine')}
                value={row[field] ? new Date(row[field]) : null}
                onChange={(val) => handleUpdateDelivery({
                    id: row.ID,
                    date: parseDate(val),
                })}
            />
        )
        if (field === 'AMOUNT') return (
            <Input
                value={row[field]}
                placeholder={t('Kogus')}
                type={'number'}
                onChange={(e) => handleUpdateDelivery({
                    id: row.ID,
                    amount: parseInt(e.target.value),
                })}
            />
        )
        if (field === 'INVOICE') return (
            <Input
                value={row[field]}
                placeholder={t('Arve/saatelehe nr')}
                onChange={(e) => handleUpdateDelivery({
                    id: row.ID,
                    invoice: e.target.value,
                })}
            />
        )
    }

    return (
        <div className='delivery-note-rows'>
            <div className='product-search'>
                <h5>{ `${t('Materiali saabumine')}` }</h5>
                <div className='product-search--inner'>
                    <DateInput
                        placeholder={t('Saabumine')}
                        value={date ? new Date(date) : null}
                        onChange={(val) => setDate(parseDate(val))}
                    />
                    <Input
                        className={'amount'}
                        value={amount}
                        placeholder={t('Kogus')}
                        type={'number'}
                        onChange={(e) => setAmount(parseInt(e.target.value))}
                    />
                    <Input
                        value={invoice}
                        placeholder={t('Arve/saatelehe nr')}
                        onChange={(e) => setInvoice(e.target.value)}
                    />
                    <Button
                        label={t('Lisa')}
                        onClick={handleAddDelivery}
                    />
                </div>
            </div>
            <div className='delivery-note-rows-list'>
                { rows.length > 0 ?
                    <table className='order-rows'>
                        <thead
                            className='order-rows--header'
                            style={{
                                gridTemplateColumns: `repeat(${rowHeaders.length}, 1fr)`
                            }}
                        >
                            <tr>
                            {
                                rowHeaders.map((headerItem) =>
                                    <th key={`header-${headerItem.value}`} className='order-rows--header-item'>
                                        { headerItem.label }
                                    </th>
                                )
                            }
                            <th></th>
                            </tr>
                        </thead>
                        <tbody className='order-rows--content'>
                            {
                                rows.map((row, index) =>
                                    <tr
                                        key={`order-row-${index}`}
                                        className={`order-row`}
                                        style={{
                                            gridTemplateColumns: `repeat(${rowHeaders.length}, 1fr)`
                                        }}
                                    >
                                        {
                                            rowHeaders.map((headerItem, itemIndex) =>
                                                <td
                                                    key={`order-row-${index}-${itemIndex}`}
                                                    className='order-row--item'
                                                >
                                                    { getColumnValue(row, headerItem.value) }
                                                </td>
                                            )
                                        }
                                        <td>
                                            <Button
                                                label={t('Kustuta')}
                                                onClick={() => handleDeleteRow(row.ID)}
                                            />
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                    :
                    <div className='empty-result'>
                        { t('Materjali saabumised puuduvad') }
                    </div>
                }
            </div>
        </div>
    )
}

export default MaterialDeliveryRows