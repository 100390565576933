import { useQuery } from '@apollo/client'
import React, { useRef, useState, forwardRef, useImperativeHandle, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ActivityIndicator from './ActivityIndicator'
import Pagination from './Pagination'

const getModelName = (input) => {
    switch (input) {
        case 'client':
            return 'CLIENTS'
        case 'contact':
            return 'CLIENTS_CONTACTS'
        case 'order':
            return 'ORDERS'
        case 'materialOrder':
            return 'MATERIAL_ORDERS'
        case 'production':
            return 'PRODUCTION'
        case 'factory':
            return 'FACTORIES'
        default:
            return input
    }
}

const getOrderBy = (field) => {
    const splitField = field.split('.').map(x => getModelName(x))
    return splitField.join('.')
}

const FilterTable = forwardRef(({
    query,
    queryKey,
    queryOptions,
    fieldConditions,
    includeFields,
    initialOrderBy,
    onRowClick,
    onRowDoubleClick,
    extraFilters,
    statusColorEnabled,
    activeRows,
    rowBackgroundColor,
    rowClass,
    ignoredKeys,
    useExtraFilters,
    subscriptions,
    onFiltersChanged,
}, ref) => {

    const { t } = useTranslation()
    const [filters, setFilters] = useState([])
    const [orderBy, setOrderBy] = useState(initialOrderBy)
    const [sort, setSort] = useState('DESC')
    const table = useRef()
    const page = useRef(0)
    const limit = useRef(200)
    const extraFiltersRef = useRef()
    const [inputs, setInputs] = useState({})

    const { data, refetch, loading, subscribeToMore } = useQuery(query, {
        notifyOnNetworkStatusChange: true,
        onCompleted: () => {
            if (onFiltersChanged) onFiltersChanged()
        },
        ...queryOptions,
    })

    useEffect(() => {
        if (includeFields) {
            const inputFields = {}
            includeFields.map(x => inputFields[x.value] = '')
            setInputs(inputFields)
        }
        if (!useExtraFilters) {
            refetch({
                ...queryOptions?.variables,
                page: page.current,
                limit: limit.current,
                sort,
                filters,
                orderBy: getOrderBy(orderBy),
                ...(extraFilters && {
                    extraFilters,
                }),
            })
        }

        const unsubscribe = []

        if (subscriptions?.length > 0) {
            subscriptions.forEach(({ document, updateQuery }) => {
                const unsub = subscribeToMore({
                    document,
                    updateQuery,
                })
                unsubscribe.push(unsub)
            })
        }

        return () => {
            unsubscribe.forEach(unsub => {
                unsub()
            })
        }
    }, [])

    useEffect(() => {
        if (!useExtraFilters || !extraFilters) return
        if (!Object.is(extraFiltersRef.current, extraFilters)) {
            refetch({
                ...queryOptions?.variables,
                page: page.current,
                limit: limit.current,
                sort,
                filters,
                orderBy: getOrderBy(orderBy),
                ...(extraFilters && {
                    extraFilters,
                }),
            })
            extraFiltersRef.current = extraFilters
        }
    }, [extraFilters])

    useImperativeHandle(ref, () => ({
        refresh () {
            handleFiltersChanged()
        },
        getTableRows () {
            return table.current.rows
        },
        getTableRowsData () {
            return data[queryKey]?.rows
        },
    }))

    const getColValue = (row, field) => {
        const conditionResult = fieldConditions ? fieldConditions(row, field) : null
        return conditionResult ? conditionResult : (typeof row[field] === 'object' ? '' : row[field]) 
    }

    const handleFiltering = (field, value) => {
        if (value <= 1 || value.length <= 1) {
            const removed = filters.filter(x => x.field !== field)
            setFilters(removed)
            return
        }
        const filterIndex = filters.findIndex(x => x.field === field)
        if (filterIndex > -1) {
            const newFilters = filters
            newFilters[filterIndex] = {
                field,
                value,
            }
            setFilters(newFilters)
            return
        }
        const newFilters = [
            ...filters,
            {
                field,
                value,
            },
        ]
        setFilters(newFilters)
    }

    const handleFiltersChanged = () => {
        refetch({
            page: page.current,
            limit: limit.current,
            filters,
            sort,
            orderBy: getOrderBy(orderBy),
            ...(extraFilters && {
                extraFilters,
            }),
        })
    }

    const handleSort = (key) => {
        if (ignoredKeys?.includes(key)) return
        const newSort = sort === 'ASC' ? 'DESC' : 'ASC'
        setOrderBy(key)
        setSort(newSort)
        refetch({
            page: page.current,
            limit: limit.current,
            filters,
            sort: newSort,
            orderBy: getOrderBy(key),
            ...(extraFilters && {
                extraFilters,
            }),
        })
    }

    const handleEnter = (e) => {
        if (e.key === 'Enter') handleFiltersChanged()
    }

    const getPreviousPage = () => {
        page.current = page.current - 1
        refetch({
            page: page.current,
            limit: limit.current,
        })
    }
    
    const getNextPage = () => {
        page.current = page.current + 1
        refetch({
            page: page.current,
            limit: limit.current,
        })
    }

    if (loading) return (
        <div className='loading-view'>
            <ActivityIndicator />
        </div>
    )

    return (
        <div className='filter-table'>
            <table id="filter-table" ref={table}>
                <thead>
                    <tr>
                        {
                            includeFields.map((header) =>
                                <th key={`filter-table-header-${header.value}`}>
                                    <span onClick={() => handleSort(header.value)}>{ t(header.label) }</span>
                                    <input
                                        value={inputs[header.value]}
                                        onChange={(e) => {
                                            setInputs({
                                                ...inputs,
                                                [header.value]: e.target.value,
                                            })
                                            handleFiltering(header.value, e.target.value)
                                        }}
                                        onKeyUp={handleEnter}
                                    />
                                </th>
                            )
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        data && data[queryKey] && data[queryKey].rows?.map((row, index) =>
                            <tr
                                key={`filter-table-row-${index}`}
                                className={
                                    `${activeRows && activeRows.some(x => x.ID === row.ID) ? 'filter-table-row--selected' : ''}${rowClass ? ' ' + rowClass(row) : ''}`
                                }
                                style={{
                                    ...(statusColorEnabled && rowBackgroundColor && {
                                        backgroundColor: rowBackgroundColor(row),
                                    }),
                                }}
                                onClick={() => {
                                    if (onRowClick) onRowClick(row)
                                }}
                                onDoubleClick={() => {
                                    if (onRowDoubleClick) onRowDoubleClick(row)
                                }}
                            >
                                {
                                    includeFields.map((field, fieldIndex) =>
                                        <td key={`filter-table-row-${index}-col-${fieldIndex}`}>
                                            { getColValue(row, field.value) }
                                        </td>
                                    )
                                }
                            </tr>
                        )
                    }
                </tbody>
            </table>
            <Pagination total={data && data[queryKey] && data[queryKey].count} onPrev={getPreviousPage} onNext={getNextPage} current={page.current} limit={limit.current} />
        </div>
    )
})

export default FilterTable