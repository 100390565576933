import React from 'react'

const Button = ({onClick, label, disabled, className}) => {

  return (
    <button
      className={`btn${className ? ' ' + className : ''}`}
      onClick={onClick}
      disabled={disabled}
    >
      { label }
    </button>
  )
}

export default Button