import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GetUsers } from '../graphql/queries'
import { Status } from '../util/const'
import ActivityIndicator from './ActivityIndicator'
import Checkbox from './Checkbox'
import SelectInput from './SelectInput'

const currentDate = new Date()

const initialState = {
    year: `${currentDate.getFullYear()}`,
    month: `${currentDate.getMonth() + 1}`,
    status: null,
    user: null,
    exported: false,
}

const statuses = [
    {
        value: Status.All,
        label: 'Kõik',
    },
    {
        value: Status.InProduction,
        label: 'Tootmises',
    },
    {
        value: Status.Sent,
        label: 'Väljastatud',
    },
    {
        value: Status.Ready,
        label: 'Valmis',
    },
    {
        value: Status.PrepaymentNotDone,
        label: 'Ettemaks tasumata',
    },
    {
        value: Status.Overdue,
        label: 'Tähtaeg ületatud',
    },
    {
        value: Status.Pending,
        label: 'Ootel',
    },
    {
        value: Status.Debt,
        label: 'Võlglane',
    },
    {
        value: Status.ReadyNotSent,
        label: 'Valmis, väljastamata',
    },
    {
        value: Status.TransportOrdered,
        label: 'Tellitud transport',
    },
]

const Filters = ({
    onFilterChange,
    loading,
}) => {

    const { t } = useTranslation()
    const [years, setYears] = useState([])
    const [months, setMonths] = useState([])
    const [users, setUsers] = useState([])
    const [filters, setFilters] = useState(initialState)

    useQuery(GetUsers, {
        onCompleted: (data) => {
            const { getUsers } = data
            if (getUsers && getUsers.length > 0) {
                setUsers(getUsers)
            }
        },
    })

    useEffect(() => {
        (async () => {
            const currentYear = new Date().getFullYear()
            const yearList = [
                {
                    label: 'Kõik',
                    value: 'all',
                },
            ]
            const monthList = [
                {
                    label: 'Kõik',
                    value: 'all',
                },
            ]
    
            for (let i = 2010; i <= currentYear; i++) {
                yearList.push({
                    label: i,
                    value: `${i}`,
                })
            }
    
            for (let i = 1; i <= 12; i++) {
                monthList.push({
                    label: i,
                    value: `${i}`,
                })
            }
    
            setMonths(monthList)
            setYears(yearList)
        })()
    
        return () => {
            
        }
    }, [])

    const setField = (field, value) => {
        const newVal = {
            ...filters,
            [field]: value,
        }

        setFilters(newVal)

        if (onFilterChange) onFilterChange(newVal)
    }

    const renderStatusOption = ({ innerProps, innerRef, data, isFocused, isSelected }) => {
        return (
            <div
                ref={innerRef}
                {...innerProps}
                className={
                    `select-option${isFocused ? ' select-option--focused' : ''}${isSelected ? ' select-option--selected' : ''} select-option--status-option`
                }
            >
                <div className='flex align-items--center'>
                    <span
                        className='select-option--color'
                        style={{
                            backgroundColor: getColor(data.value),
                            ...(data.value === Status.InProduction && {
                                borderColor: '#cccccc',
                                borderWidth: 1,
                                borderStyle: 'solid',
                            })
                        }}
                    ></span>
                    <span>
                        { t(data.label) }
                    </span>
                </div>
            </div>
        )
    }

    const getColor = (value) => {
        switch (value) {
            case Status.ReadyNotSent:
                return '#81c71a'
            case Status.Sent:
                return '#86d7b0'
            case Status.Overdue:
                return '#fe1153'
            case Status.TransportOrdered:
                return '#ff58faf7'
            case Status.Ready:
                return '#a4f7fd'
            case Status.PrepaymentNotDone:
                return '#fef711'
            case Status.Pending:
                return '#cccccc'
            case Status.Debt:
                return '#a32f41'
            case Status.InProduction:
                return '#fff'
            default:
                return '#fff'
        }
    }

    return (
        <div className='filters'>
            <div className='filters-date'>
                <SelectInput
                    options={years}
                    placeholder={t('Aasta')}
                    onChange={(option) => setField('year', option.value)}
                    value={{
                        label: years.find(x => x.value === filters.year)?.label,
                        value: filters.year,
                    }}
                />
                <SelectInput
                    options={months}
                    value={{
                        label: months.find(x => x.value === filters.month)?.label,
                        value: filters.month,
                    }}
                    placeholder={t('Kuu')}
                    onChange={(option) => setField('month', option.value)}
                />
            </div>
            <div className='filters-status'>
                <SelectInput
                    options={statuses}
                    placeholder={t('Staatus')}
                    components={{
                        Option: renderStatusOption,
                    }}
                    onChange={(option) => setField('status', option.value)}
                />
            </div>
            <div className='filters-exported'>
                <Checkbox
                    value={filters.exported}
                    onChange={(e) => setField('exported', e.target.checked)}
                    label={t('Peida väljastatud')}
                />
            </div>
            <div className='filters-name'>
                <SelectInput
                    options={users}
                    placeholder={t('Nimi')}
                    getOptionLabel={(option) => option.RNAME}
                    getOptionValue={(option) => option.UNAME}
                    onChange={(option) => setField('user', option.UNAME)}
                />
            </div>
            {
                loading && <ActivityIndicator />
            }
        </div>
    )
}

export default Filters