import React from 'react'
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer'
import UbuntuRegular from '../assets/fonts/Ubuntu/Ubuntu-Regular.ttf'
import UbuntuBold from '../assets/fonts/Ubuntu/Ubuntu-Bold.ttf'
import { useTranslation } from 'react-i18next'
import { formatDate } from '../util/format'

Font.register(
  {
    family: 'Ubuntu',
    fonts: [
      {
        src: UbuntuRegular,
        fontWeight: 400,
      },
      {
        src: UbuntuBold,
        fontWeight: 700,
      },
    ],
  },
)

const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontSize: 11,
  },
  wrapper: {
    borderWidth: 1,
    borderColor: '#000',
    width: 400,
  },
  title: {
    fontSize: 22,
    fontWeight: 700,
    fontFamily: 'Ubuntu',
    textDecoration: 'underline',
    marginBottom: 10,
  },
  subTitle: {
    fontWeight: 700,
    fontFamily: 'Ubuntu',
    fontSize: 13,
    marginTop: 5,
  },
  infoRow: {
    flexDirection: 'row',
    paddingVertical: 2,
  },
  tableRow: {
    flexDirection: 'row',
    paddingVertical: 4,
  },
  tableHeadLabel: {
    fontFamily: 'Ubuntu',
    fontWeight: 700,
    fontSize: 10,
  },
  tableColText: {
    fontFamily: 'Ubuntu',
    fontWeight: 400,
    fontSize: 10,
  },
  tableColTextBottom: {
    fontFamily: 'Ubuntu',
    fontWeight: 400,
    fontSize: 10,
    marginTop: 12,
  },
  strong: {
    fontFamily: 'Ubuntu',
    fontWeight: 700,
  },
  col1: {
    width: '5%',
    paddingRight: 3,
  },
  col2: {
    width: '20%',
    paddingRight: 3,
  },
  col3: {
    width: '20%',
    paddingRight: 3,
  },
  col4: {
    width: '15%',
    paddingRight: 3,
  },
  col5: {
    width: '15%',
    paddingRight: 3,
  },
  col6: {
    width: '25%',
  },
  label: {
    fontFamily: 'Ubuntu',
    fontWeight: 400,
    width: 80,
  },
  value: {
    fontFamily: 'Ubuntu',
    marginBottom: 4,
  },
  footer: {
    marginTop: 30,
  },
  footerText: {
    marginBottom: 4,
  },
})

const MaterialOrderInternalDocument = ({
  data,
}) => {

  const { t } = useTranslation()

  const getArea = (size, amount) => {
    const measurements = size?.split('x')
    amount = amount ? amount : 0
    if (!measurements || measurements.length < 2) return ''
    return `${(measurements[0] * measurements[1] / 1000000 * amount).toFixed(2)} m2`
  }

  const renderNuut = (nuut, size) => {
    if (!size) return <></>

    const sizes = size?.split('x')

    if (!nuut) return (
      <View
        style={{
          marginBottom: 4,
          marginLeft: '5%',
          marginTop: 10,
        }}
      >
        { sizes && sizes.length > 0 && <Text style={{ width: 160, textAlign: 'center', marginBottom: 4 }}>{ sizes[1] }</Text> }
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <View
            style={{
              borderWidth: 1,
              borderColor: '#000',
              width: 160,
              height: 20,
            }}
          >

          </View>
          { sizes && sizes.length > 0 &&  <Text style={{ marginLeft: 4 }}>{ sizes[0] }</Text> }
        </View>
      </View>
    )
    
    const nuuts = nuut.split(',')

    return (
      <View
        style={{
          marginBottom: 4,
          marginLeft: '5%',
          marginTop: 10,
        }}
      >
        { sizes && sizes.length > 0 && <Text style={{ width: 160, textAlign: 'center', marginBottom: 4 }}>{ sizes[1] }</Text> }
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <View
            style={{
              borderWidth: 1,
              borderColor: '#000',
              width: 160,
            }}
          >
            {
              nuuts.map((x, i) =>
                <View
                  key={`nuut-${i}`}
                  style={{
                    borderBottomWidth: i < nuuts.length - 1 ? 1 : 0,
                    borderColor: '#000',
                    height: 20,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  {
                    x.includes('*') &&
                    <View style={{ flexDirection: 'row' }}>
                      <View style={{ width: 15, height: 20, borderRightWidth: 1, borderColor: '#000' }} />
                      <View style={{ width: 10, height: 20, borderRightWidth: 1, borderColor: '#000' }} />
                      <View style={{ width: 10, height: 20, borderRightWidth: 1, borderColor: '#000' }} />
                    </View>
                  }
                  <Text style={{ flex: 1, textAlign: 'right', paddingRight: 20 }}>{ x.replace('*', '') }</Text>
                </View>
              )
            }
          </View>
          { sizes && sizes.length > 0 &&  <Text style={{ marginLeft: 4 }}>{ sizes[0] }</Text> }
        </View>
      </View>
    )
  }

  if (!data) return <></>

  return (
    <Document>
      <Page size="A4" style={styles.page} orientation={'portrait'}>
        <View
          style={{
            ...styles.infoRow,
            marginTop: 20,
          }}
        >
          <View style={styles.label}>
            <Text>{ `Tehas:` }</Text>
          </View>
          {
            data && data.factory ?
            <Text style={[styles.value]}>{ data.factory.NAME }</Text>
            :
            <></>
          }
        </View>

        <View
          style={[
            styles.infoRow,
            {
              fontFamily: 'Ubuntu',
              justifyContent: 'space-between',
              fontWeight: 700,
              marginTop: 20,
            },
          ]}
        >
          <View>
            <View style={{ flexDirection: 'row' }}>
              <Text>Tellimus:</Text>
              <Text style={{ marginLeft: 20 }}>{ `${data.ORDER_NUMBER}` }</Text>
            </View>
          </View>
          <Text>{ `Kuupäev: ${formatDate(new Date(data.CREATED_DATE))}` }</Text>
        </View>

        <View>
          <View style={[styles.tableRow, { borderBottomWidth: 2, borderBottomColor: '#000', marginTop: 20 }]}>
            <View style={styles.col1}>
              <Text style={styles.tableHeadLabel}>{ t('Nr') }</Text>
            </View>
            <View style={styles.col2}>
              <Text style={styles.tableHeadLabel}>{ t('Suurus') }</Text>
              <Text style={styles.tableHeadLabel}>{ t('Karp') }</Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.tableHeadLabel}>{ t('Materjal') }</Text>
              <Text style={styles.tableHeadLabel}>{ t('Klient') }</Text>
            </View>
            <View style={styles.col4}>
              <Text style={styles.tableHeadLabel}>{ t('Kogus') }</Text>
            </View>
            <View style={styles.col5}>
              <Text style={styles.tableHeadLabel}>{ t('Tellimus') }</Text>
              <Text style={styles.tableHeadLabel}>{ t('Karbi tarne') }</Text>
            </View>
            <View style={styles.col6}>
              <Text style={styles.tableHeadLabel}>{ t('Märkused') }</Text>
              <Text style={styles.tableHeadLabel}>{ t('Müügi number') }</Text>
            </View>
          </View>
          {
            data && data.orderRows.map((row, index) =>
            <View key={row.ID} style={[{ borderBottomWidth: index < data.orderRows.length - 1 ? 1 : 0, borderBottomColor: '#000' }]}>
              <View style={styles.tableRow}>
                <View style={styles.col1}>
                  <Text style={styles.tableColText}>{ index + 1 }</Text>
                </View>
                <View style={styles.col2}>
                  <Text style={styles.tableColText}>{ row.SIZE }</Text>
                  <Text style={styles.tableColTextBottom}>{ row.production?.BOX }</Text>
                  <Text style={styles.tableColText}>{ row.production?.MOOT }</Text>
                </View>
                <View style={styles.col3}>
                  <Text style={styles.tableColText}>{ row.MARK }</Text>
                  <Text style={styles.tableColTextBottom}>{ row.production?.client?.NAME }</Text>
                </View>
                <View style={styles.col4}>
                  <Text style={styles.tableColText}>{ row.AMOUNT }</Text>
                </View>
                <View style={styles.col5}>
                  <Text style={styles.tableColText}>{ `${formatDate(new Date(row.DELIVERY_DATE ? row.DELIVERY_DATE : null))}` }</Text>
                  <Text style={styles.tableColTextBottom}>{ `${formatDate(new Date(row.production?.DEADLINE ? row.production?.DEADLINE : null))}` }</Text>
                </View>
                <View style={styles.col6}>
                  <Text style={styles.tableColText}>{ row.REMARK }</Text>
                  <Text style={styles.tableColText}>{ getArea(row.SIZE, row.AMOUNT) }</Text>
                  <Text style={styles.tableColTextBottom}>{ row.production?.ORDERNO }</Text>
                </View>
              </View>
              { renderNuut(row.NUUT, row.SIZE) }
            </View>
            )
          }
        </View>
      </Page>
    </Document>
  )
}

export default MaterialOrderInternalDocument