import gql from 'graphql-tag';

export const GetOrders = gql`
  query getAllOrders ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $filters: [FiltersInput]) {
    getAllOrders (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, filters: $filters) {
      count
      rows {
        ID
        ORDERNO
        ODATE

        contact {
          CONTACT
        }
        creator {
          RNAME
        }
        client {
          ID
          NAME
        }
      }
    }
  }
`;

export const OrdersQuery = gql`
  query ordersQuery ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $filters: [FiltersInput], $extraFilters: FilterInput) {
    ordersQuery (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, filters: $filters, extraFilters: $extraFilters) {
      count,
      rows {
        ID
        ORDERNO
        ODATE

        contact {
          CONTACT
        }
        creator {
          RNAME
        }
        client {
          ID
          NAME
        }
      }
    }
  }
`;

export const GetAllOrderRows = gql`
  query getAllOrderRows ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $filters: [FiltersInput]) {
    getAllOrderRows (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, filters: $filters) {
      count
      rows {
        ID
        BOX
        MATERIAL
        L
        B
        H
        H_EXTRA
        AMOUNT
        FPRICE
        PRINT
        ORDER_ID

        order {
          ORDERNO
          ODATE
          CREATOR

          client {
            NAME
          }
        }
      }
    }
  }
`;

export const SearchOrders = gql`
  query searchOrders ($searchInput: String!) {
    searchOrders (searchInput: $searchInput) {
      count
      rows {
        ID
        ORDERNO
        ODATE

        contact {
          CONTACT
        }
        creator {
          RNAME
        }
        client {
          ID
          NAME
        }
      }
    }
  }
`;

export const MaterialOrderRowsQuery = gql`
  query materialOrderRowsQuery ($from: String, $to: String, $factory: String, $orderBy: String, $sort: String, $filters: [FiltersInput], $page: Int!, $limit: Int!) {
    materialOrderRowsQuery (from: $from, to: $to, factory: $factory, orderBy: $orderBy, sort: $sort, filters: $filters, page: $page, limit: $limit) {
      count,
      rows {
        ID
        DELIVERY_DATE
        SIZE
        NUUT
        MARK
        AMOUNT
        arrived
        inTransit

        factoryOrder {
          COMPLETED
        }

        materialOrder {
          ID
          ORDER_NUMBER
          CREATED_DATE

          factory {
            NAME
          }
        }

        production {
          BOX
          MOOT
          DEADLINE

          client {
            NAME
          }
        }
      }
    }
  }
`;

export const ProductionsQuery = gql`
  query productionsQuery ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $filters: [FiltersInput], $extraFilters: FilterInput) {
    productionsQuery (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, filters: $filters, extraFilters: $extraFilters) {
      count
      rows {
        ID
        ORDERNO
        ORDER_DATE
        DEADLINE
        NAME
        TELLIMUSNO
        KLIENT_BOXCODE
        START_DATE
        NEW_DEADLINE
        LAMINAATOR
        ALIIM
        _3LIIM
        KLIIM
        SLOTTER
        INLINE
        SRE
        TIGEL
        FLEXO
        SLITTER
        TOOTLEMINE
        FIN_DATE
        SHIPPED
        ALUSEID
        RKOGUS
        ALUS_MOOT
        KOMMENTAAR
        BOX
        KOGUS
        TRYKK
        MAT_ORDER_ID
        MOOT
        MATERIAL
        PRINTOUT
        KLIENT_ORDER
        PROD_COMMENT
        STOCK_MAT
        STOCK_MOOT
        MAT_LOADED
        CREATOR
        KLIENT_ID
        TRANSPORDIFIRMA
        ADDRESS
        OFSET
        CONTACT_ID
        EKSTSEN
        PREMAID
        PRODUCTION_CODE
        VERONA
        TITAN
        EXPORTED
        STANTS
        KLISHEE
        TRANSPORT
        PALLET
        PARTIAL_AMOUNT
        AFD
        FPR
        FACTORY_NAME
        MAT_AMOUNT
        MAT_ORDERS_CNT
        SIZE
        MAT_NUUT
        MAT_DELIVERY
        ARRIVED_AMOUNT
        DIFF_AMOUNT
        HALDURNAME
        HALDUREMAIL
        HALDUREMAIL_CC
        HALDURPHONE
        HALDURFAX
        SISESTAJA_EMAIL
        SISESTAJA_NIMI
        HINNAPAKKUMINE
        ETTEMAKS
        DEPT
        MAKSEAEG
        TRANSPORT_DATE
        PRICE
        KLIENT_INFORMED
        CLIENTEMAIL
        CLIENTPHONE
        CLIENTADDRESS
        VAT
        TYPE
        REGNO
      }
    }
  }
`;

export const GetAllUsers = gql`
  query getAllUsers ($page: Int!, $limit: Int!) {
    getAllUsers (page: $page, limit: $limit) {
      count
      rows {
        ID
        USERCODE
        UNAME
        RNAME
        PROFESSION
        PHONE
        FAX
        EMAIL
        SUPERUSER
        EXCEL
        MATERIAL
        CC_EMAIL
        CONSTRUCTOR
        MAT_ORDERS
        DIRECTO
      }
    }
  }
`;

export const GetProductions = gql`
  query getProductions ($page: Int!, $limit: Int!, $latest: Boolean) {
    getProductions (page: $page, limit: $limit, latest: $latest) {
      count
      rows {
        ID
        ORDERNO
        ORDER_DATE
        DEADLINE
        KLIENT_ORDER
        KLIENT_ID
        START_DATE
        FIN_DATE
        SHIPPED
        TRANSPORT_DATE
        NEW_DEADLINE
        PRODUCTION_CODE
        BOX
        MOOT
        MATERIAL
        KLIENT_BOXCODE
        KOGUS
        PARTIAL_AMOUNT
        TELLIMUSNO
        TRANSPORDIFIRMA
        RKOGUS
        PRICE
        ALUSEID
        KLISHEE
        STANTS
        TRANSPORT
        PALLET
        ADDRESS
        KOMMENTAAR

        creator {
          ID
          RNAME
        }

        client {
          ID
          NAME
          REGNO
          EMAIL
          PHONE
          ETTEMAKS
          ADDRESS
          MAKSEAEG
          TRANSFIRMA
          DEPT

          manager {
            ID
            RNAME
          }
        }
      }
    }
  }
`;

export const GetMaterialOrders = gql`
  query getMaterialOrders ($page: Int!, $limit: Int!) {
    getMaterialOrders (page: $page, limit: $limit) {
      count
      rows {
        ID
        CREATED_DATE
        ORDERNO
        REMARK
        ORDERED
        ORDER_NUMBER
        orderRows {
          ID
          ORDER_ID
          SIZE
          SIZE_BOX
          MARK
          MARK_COMMENT
          AMOUNT
          DELIVERY_DATE
          NUUT
          REMARK
          PRICE
          CLIENT_ID
          BOX_DATE
          SPEED
          BOX_SIZE
          BOX_TYPE
          PROD_ID
          FINISHED_DATE
          PRIORITY
        }
        factory {
          ID
          NAME
          ADDRESS
          EMAIL
          CONTACT
        }
        creator {
          RNAME
        }
      }
    }
  }
`;

export const GetFactoryMaterialOrders = gql`
  query getFactoryMaterialOrders ($prodId: Int!) {
    getFactoryMaterialOrders (prodId: $prodId) {
      ID
      ORDER_ID
      SIZE
      SIZE_BOX
      MARK
      MARK_COMMENT
      AMOUNT
      DELIVERY_DATE
      NUUT
      REMARK
      PRICE
      CLIENT_ID
      BOX_DATE
      SPEED
      BOX_SIZE
      BOX_TYPE
      PROD_ID
      FINISHED_DATE
      PRIORITY

      materialOrder {

        paperFactory {
          FACTORY_NAME
        }
      }

      factoryOrder {
        ID
        PROD_ID
        MAT_ORDER_NO
        MAT_ORDER_ROW_ID
        FACTORY_ID
        ARRIVAL
        COMPLETED
        STATUS
        PLANNED
        SHIPPED
        ORDERED_AMOUNT
        TRANSPORT_SIZE
        REAL_AMOUNT
        PLATES
        SIZE
        MARK
        FACTORY_CODE
        FACTORY_ORDER
        PRIORITY
        COMMENT
        BOX_SIZE
        STATUS_CHANGE
        MAT_ORDER_NUMBER
      }
    }
  }
`;

export const MaterialOrdersQuery = gql`
  query materialOrdersQuery ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $filters: [FiltersInput], $extraFilters: FilterInput) {
    materialOrdersQuery (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, filters: $filters, extraFilters: $extraFilters) {
      count
      rows {
        ID
        CREATED_DATE
        ORDERNO
        REMARK
        ORDERED
        ORDER_NUMBER

        orderRows {
          ID
          ORDER_ID
          SIZE
          SIZE_BOX
          MARK
          MARK_COMMENT
          AMOUNT
          DELIVERY_DATE
          NUUT
          REMARK
          PRICE
          CLIENT_ID
          BOX_DATE
          SPEED
          BOX_SIZE
          BOX_TYPE
          PROD_ID
          FINISHED_DATE
          PRIORITY
          production {
            ORDERNO
            BOX
            MOOT
            DEADLINE
            client {
              NAME
            }
          }
        }
        factory {
          ID
          NAME
          ADDRESS
          EMAIL
          CONTACT
        }
        creator {
          RNAME
        }
      }
    }
  }
`;

export const GetMaterialOrderRow = gql`
  query getMaterialOrderRow ($id: Int!) {
    getMaterialOrderRow (id: $id) {
      ID
      ORDER_ID
      SIZE
      SIZE_BOX
      MARK
      MARK_COMMENT
      AMOUNT
      DELIVERY_DATE
      NUUT
      REMARK
      PRICE
      CLIENT_ID
      BOX_DATE
      SPEED
      BOX_SIZE
      BOX_TYPE
      PROD_ID
      FINISHED_DATE
      PRIORITY
    }
  }
`;

export const GetRelatedMaterialOrders = gql`
  query getRelatedMaterialOrders ($productionCode: String!) {
    getRelatedMaterialOrders (productionCode: $productionCode) {
      ID
      ORDER_ID
      SIZE
      SIZE_BOX
      MARK
      MARK_COMMENT
      AMOUNT
      DELIVERY_DATE
      NUUT
      REMARK
      PRICE
      CLIENT_ID
      BOX_DATE
      SPEED
      BOX_SIZE
      BOX_TYPE
      PROD_ID
      FINISHED_DATE
      PRIORITY

      production {
        ID
        ORDERNO
        ORDER_DATE
        DEADLINE
        KLIENT_ORDER
        KLIENT_ID
        START_DATE
        FIN_DATE
        SHIPPED
        TRANSPORT_DATE
        NEW_DEADLINE
        PRODUCTION_CODE
        BOX
        MOOT
        MATERIAL
        KLIENT_BOXCODE
        KOGUS
        PARTIAL_AMOUNT
        TELLIMUSNO
        TRANSPORDIFIRMA
        RKOGUS
        PRICE
        ALUSEID
        KLISHEE
        STANTS
        TRANSPORT
        PALLET
        ADDRESS
        KOMMENTAAR

        creator {
          ID
          RNAME
        }

        client {
          ID
          NAME
          REGNO
          EMAIL
          PHONE
          ETTEMAKS
          ADDRESS
          MAKSEAEG
          TRANSFIRMA
          DEPT

          manager {
            ID
            RNAME
          }
        }
      }
    }
  }
`;

export const GetMaterialOrder = gql`
  query getMaterialOrder ($id: Int!) {
    getMaterialOrder (id: $id) {
      ID
      CREATED_DATE
      ORDERNO
      REMARK
      ORDERED
      ORDER_NUMBER
      FACTORY_ID
      orderRows {
        ID
        ORDER_ID
        SIZE
        SIZE_BOX
        MARK
        MARK_COMMENT
        AMOUNT
        DELIVERY_DATE
        NUUT
        REMARK
        PRICE
        CLIENT_ID
        BOX_DATE
        SPEED
        BOX_SIZE
        BOX_TYPE
        PROD_ID
        FINISHED_DATE
        PRIORITY
        production {
          ORDERNO
          DEADLINE
          BOX
          MOOT
          PRODUCTION_CODE
          FIN_DATE
          TRANSPORT_DATE
          SHIPPED
          NEW_DEADLINE

          client {
            NAME
          }
        }
      }
      factory {
        ID
        NAME
        ADDRESS
        EMAIL
        CONTACT
      }
      creator {
        RNAME
      }
    }
  }
`;

export const GetOrderByNumber = gql`
  query getOrderByNumber ($orderNumber: Int!) {
    getOrderByNumber (orderNumber: $orderNumber) {
      ID
      ORDERNO
      ODATE

      contact {
        CONTACT
      }
      creator {
        RNAME
      }
      client {
        ID
        NAME
      }
      orderRows {
        ID
        ORDER_ID
        BOX
        MATERIAL
        MAT_PRICE
        L
        B
        H
        PRINT
        AMOUNT
        AL
        AB
        FPRICE
        PROFIT
        LPRICE
        BOXNAME
        MAT_L
        MAT_B
        NUUT1
        NUUT2
        NUUT3
        BOXES
        LISTS
        COLOR1
        COLOR2
        COLOR3
        COLOR4
        PANTONE1
        PANTONE2
        PANTONE3
        PANTONE4
        MATRIITS
        KLISHEE
        COMMENT
        PICTURE
        ROTATS
        STATUS
        PROD_DATE
        FILE1
        FILE2
        NUUT4
        NUUT5
        TRANSPORT
        H_EXTRA
        FACTORY
        SLITTER
        FLEXO
        TIGEL
        SRE
        INLINE
        SLOTTER
        KLIIM
        ALIIM
        _3LIIM
        LAMINAATOR
        TOOTLEMINE
        NUUDID
        PROD_CODE
        PRODUCTION_CODE
        MAT_TYPE
        CONSTR_NAME
        CONSTR_DATE
        VERONA
        TITAN
        CREATION_YEAR
        PER_SHEET
        RECEIVER
        LP_L
        LP_B
        TT_L
        TT_B
        CLASSTYPE
        DENSITY
        AFD
        FPR
        EKSTSEN
        PREMAID
      }
    }
  }
`;

export const GetOrder = gql`
  query getOrder ($id: Int!) {
    getOrder (id: $id) {
      ID
      ORDERNO
      ODATE
      CREATOR
      TEXT
      PROD_DATE
      CONTACT_ID
      CLIENT_ID

      contact {
        ID
        CONTACT
        PHONE
        EMAIL
        SKYPE
        MOBILE
      }
      creator {
        ID
        UNAME
        RNAME
        EMAIL
        PHONE
        FAX
        PROFESSION
      }
      client {
        ID
        NAME
      }
      orderRows {
        ID
        ORDER_ID
        BOX
        MATERIAL
        MAT_PRICE
        L
        B
        H
        PRINT
        AMOUNT
        AL
        AB
        FPRICE
        PROFIT
        LPRICE
        BOXNAME
        MAT_L
        MAT_B
        NUUT1
        NUUT2
        NUUT3
        BOXES
        LISTS
        COLOR1
        COLOR2
        COLOR3
        COLOR4
        PANTONE1
        PANTONE2
        PANTONE3
        PANTONE4
        MATRIITS
        KLISHEE
        COMMENT
        PICTURE
        ROTATS
        STATUS
        PROD_DATE
        FILE1
        FILE2
        NUUT4
        NUUT5
        TRANSPORT
        H_EXTRA
        FACTORY
        SLITTER
        FLEXO
        TIGEL
        SRE
        INLINE
        SLOTTER
        KLIIM
        ALIIM
        _3LIIM
        LAMINAATOR
        TOOTLEMINE
        NUUDID
        PROD_CODE
        PRODUCTION_CODE
        MAT_TYPE
        CONSTR_NAME
        CONSTR_DATE
        VERONA
        TITAN
        CREATION_YEAR
        PER_SHEET
        RECEIVER
        LP_L
        LP_B
        TT_L
        TT_B
        CLASSTYPE
        DENSITY
        AFD
        FPR
        EKSTSEN
        PREMAID
        materialItem {
          MARK
          MARK2
        }
      }
    }
  }
`;

export const GetProduction = gql`
  query getProduction ($id: Int!) {
    getProduction (id: $id) {
      ID
      ORDERNO
      KLIENT_ID
      STAATUS
      ORDER_ID
      ORDER_ROW_ID
      START_DATE
      FIN_DATE
      DEADLINE
      SHIPPED
      KLIENT_ORDER
      KLIENT_BOXCODE
      ALUSEID
      TRANSPORDIFIRMA
      ADDRESS
      KOMMENTAAR
      ETTEMAKS
      CREATOR
      BOX
      MATERIAL
      PRICE
      KOGUS
      CREATED
      TRYKK
      MOOT
      ORDER_DATE
      TRANSPORT_DATE
      RKOGUS
      TRANSPORT_ID
      ALUS_MOOT
      CONTACT_ID
      TELLIMUSNO
      FACTORY
      KLIENT_INFORMED
      PRINTOUT
      NEW_DEADLINE
      SLITTER
      FLEXO
      TIGEL
      SRE
      INLINE
      SLOTTER
      KLIIM
      ALIIM
      _3LIIM
      LAMINAATOR
      PROD_COMMENT
      STOCK_MAT
      STOCK_MOOT
      MAT_LOADED
      TOOTLEMINE
      OFSET
      EKSTSEN
      PREMAID
      PROD_CODE
      PRODUCTION_CODE
      VERONA
      TITAN
      EXPORTED
      PROD_READY
      STANTS
      KLISHEE
      TRANSPORT
      PARTIAL_AMOUNT
      CLASS
      AFD
      FPR
      PALLET

      order {
        ID
        ORDERNO
      }

      materialOrders {
        ID
        ORDER_ID
        SIZE
        SIZE_BOX
        MARK
        MARK_COMMENT
        AMOUNT
        DELIVERY_DATE
        NUUT
        REMARK
        PRICE
        CLIENT_ID
        BOX_DATE
        SPEED
        BOX_SIZE
        BOX_TYPE
        PROD_ID
        FINISHED_DATE
        PRIORITY
      }

      materialDeliveries {
        ID
        PROD_ID
        INVOICE
        DELIVERY_DATE
        AMOUNT
      }

      creator {
        ID
        RNAME
      }

      contact {
        ID
        CONTACT
        EMAIL
        PHONE
      }

      client {
        ID
        NAME
        REGNO
        EMAIL
        PHONE
        ETTEMAKS
        ADDRESS
        MAKSEAEG
        TRANSFIRMA
        DEPT
        VAT

        manager {
          ID
          RNAME
        }

        contacts {
          ID
          CONTACT
          EMAIL
          PHONE
        }
        
        transports {
          ID
          TRANSPORT
          OLD
          MAIN
        }
      }

      emails {
        ID
        EMAIL
        KUUP
        USER
        TEXT
      }
    }
  }
`;

export const GetOrderRowByName = gql`
  query getOrderRowByName ($input: String!, $clientId: Int!, $rowId: Int!) {
    getOrderRowByName (input: $input, clientId: $clientId, rowId: $rowId) {
      ID
      ORDER_ID
      BOX
      MATERIAL
      MAT_PRICE
      L
      B
      H
      PRINT
      AMOUNT
      AL
      AB
      FPRICE
      PROFIT
      LPRICE
      BOXNAME
      MAT_L
      MAT_B
      NUUT1
      NUUT2
      NUUT3
      BOXES
      LISTS
      COLOR1
      COLOR2
      COLOR3
      COLOR4
      PANTONE1
      PANTONE2
      PANTONE3
      PANTONE4
      MATRIITS
      KLISHEE
      COMMENT
      PICTURE
      ROTATS
      STATUS
      PROD_DATE
      FILE1
      FILE2
      NUUT4
      NUUT5
      TRANSPORT
      H_EXTRA
      FACTORY
      SLITTER
      FLEXO
      TIGEL
      SRE
      INLINE
      SLOTTER
      KLIIM
      ALIIM
      _3LIIM
      LAMINAATOR
      TOOTLEMINE
      NUUDID
      PROD_CODE
      PRODUCTION_CODE
      MAT_TYPE
      CONSTR_NAME
      CONSTR_DATE
      VERONA
      TITAN
      CREATION_YEAR
      PER_SHEET
      RECEIVER
      LP_L
      LP_B
      TT_L
      TT_B
      CLASSTYPE
      DENSITY
      AFD
      FPR
      EKSTSEN
      PREMAID
    }
  }
`;

export const GetOrderRow = gql`
  query getOrderRow ($id: Int!) {
    getOrderRow (id: $id) {
      ID
      ORDER_ID
      BOX
      MATERIAL
      MAT_PRICE
      L
      B
      H
      PRINT
      AMOUNT
      AL
      AB
      FPRICE
      PROFIT
      LPRICE
      BOXNAME
      MAT_L
      MAT_B
      NUUT1
      NUUT2
      NUUT3
      BOXES
      LISTS
      COLOR1
      COLOR2
      COLOR3
      COLOR4
      PANTONE1
      PANTONE2
      PANTONE3
      PANTONE4
      MATRIITS
      KLISHEE
      COMMENT
      PICTURE
      ROTATS
      STATUS
      PROD_DATE
      FILE1
      FILE2
      NUUT4
      NUUT5
      TRANSPORT
      H_EXTRA
      FACTORY
      SLITTER
      FLEXO
      TIGEL
      SRE
      INLINE
      SLOTTER
      KLIIM
      ALIIM
      _3LIIM
      LAMINAATOR
      TOOTLEMINE
      NUUDID
      PROD_CODE
      PRODUCTION_CODE
      MAT_TYPE
      CONSTR_NAME
      CONSTR_DATE
      VERONA
      TITAN
      CREATION_YEAR
      PER_SHEET
      RECEIVER
      LP_L
      LP_B
      TT_L
      TT_B
      CLASSTYPE
      DENSITY
      AFD
      FPR
      EKSTSEN
      PREMAID
    }
  }
`;

export const GetClasses = gql`
  query getClasses {
    getClasses {
      ID
      NAME
    }
  }
`;

export const GetBoxes = gql`
  query getBoxes {
    getBoxes {
      ID
      NAME
      FORMULA_L
      FORMULA_B
    }
  }
`;

export const GetFactories = gql`
  query getFactories {
    getFactories {
      ID
      NAME
      ADDRESS
      EMAIL
      CONTACT
    }
  }
`;

export const GetPaperFactories = gql`
  query getPaperFactories {
    getPaperFactories {
      ID
      FACTORY_NAME
      ADDRESS
      EMAIL
      CONTACT
    }
  }
`;

export const GetClients = gql`
  query getClients {
    getClients {
      ID
      NAME
      ADDRESS
      EMAIL
      CONTACT
    }
  }
`;

export const GetClientsByName = gql`
  query getClientsByName ($name: String) {
    getClientsByName (name: $name) {
      ID
      NAME
    }
  }
`;

export const GetClient = gql`
  query getClient ($id: Int!) {
    getClient (id: $id) {
      ID
      NAME
      ADDRESS
      CONTACT
      PHONE
      FAX
      EMAIL
      UNAME
      SKYPE
      ETTEMAKS
      COMMON
      DEPT
      TRANSPORT
      TRANSFIRMA
      VAT
      MANAGER
      ACTIVE
      MAKSEAEG
      REGNO
      MANAGER_ID
      CHANGE
      COUNTRY
      PREPAID
    }
  }
`;

export const GetClientTransports = gql`
  query getClientTransports ($clientId: Int!) {
    getClientTransports (clientId: $clientId) {
      ID
      TRANSPORT
      OLD
      MAIN
    }
  }
`;

export const GetAllClients = gql`
  query getAllClients ($page: Int!, $limit: Int!) {
    getAllClients (page: $page, limit: $limit) {
      count
      rows {
        ID
        NAME
        ADDRESS
        CONTACT
        PHONE
        FAX
        EMAIL
        UNAME
        SKYPE
        ETTEMAKS
        COMMON
        DEPT
        TRANSPORT
        TRANSFIRMA
        VAT
        MANAGER
        ACTIVE
        MAKSEAEG
        REGNO
        MANAGER_ID
        CHANGE
        COUNTRY
        PREPAID
        manager {
          RNAME
        }
      }
    }
  }
`;

export const SearchClients = gql`
  query searchClients ($searchInput: String!) {
    searchClients (searchInput: $searchInput) {
      ID
      NAME
      ADDRESS
      EMAIL
      CONTACT
      contacts {
        ID
        CONTACT
        PHONE
        EMAIL
        MOBILE
      }
    }
  }
`;

export const SearchClientsAll = gql`
  query searchClients ($searchInput: String!) {
    searchClients (searchInput: $searchInput) {
      ID
      NAME
      ADDRESS
      CONTACT
      PHONE
      FAX
      EMAIL
      UNAME
      SKYPE
      ETTEMAKS
      COMMON
      DEPT
      TRANSPORT
      TRANSFIRMA
      VAT
      MANAGER
      ACTIVE
      MAKSEAEG
      REGNO
      MANAGER_ID
      CHANGE
      COUNTRY
      PREPAID
    }
  }
`;

export const GetClientContacts = gql`
  query getClientContacts ($clientId: Int!) {
    getClientContacts (clientId: $clientId) {
      CONTACT
      ID
      EMAIL
      PHONE
      MOBILE
      FAX
      SKYPE
      OLD
    }
  }
`;

export const GetUsers = gql`
  query getUsers {
    getUsers {
      ID
      RNAME
      UNAME
      USERCODE
      EMAIL
    }
  }
`;

export const GetMaterials = gql`
  query getMaterials {
    getMaterials {
      FACTORY
      MARK
      MARK2
      PRICE
      LAYER
      ACTIVE
      TYPE
      CODE
      DENSITY
    }
  }
`;

export const GetAllMaterials = gql`
  query getAllMaterials ($page: Int!, $limit: Int!) {
    getAllMaterials (page: $page, limit: $limit) {
      count
      rows {
        FACTORY
        MARK
        MARK2
        PRICE
        LAYER
        ACTIVE
        TYPE
        CODE
        DENSITY
      }
    }
  }
`;

export const GetMaterialDeliveries = gql`
  query getMaterialDeliveries ($prodId: Int!) {
    getMaterialDeliveries (prodId: $prodId) {
      ID
      PROD_ID
      INVOICE
      DELIVERY_DATE
      AMOUNT
    }
  }
`;

export const GetInvoices = gql`
  query getInvoices ($page: Int!, $limit: Int!) {
    getInvoices (page: $page, limit: $limit) {
      count
      rows {
        ID
        CREATED_DATE
        CLIENT_ID
        PALLETS
        PLACES
        NETTO
        GROSS
        PAYMENT_DATE
        CREATOR
        ORDERNO
        GOODS
        COMMENT
        COUNTRY
        PREPAID_AMOUNT
        client {
          NAME
          ID
          VAT
        }
        author {
          RNAME
          ID
          UNAME
        }
      }
    }
  }
`;

export const GetInvoice = gql`
  query getInvoice ($id: Int!) {
    getInvoice (id: $id) {
      ID
      CREATED_DATE
      CLIENT_ID
      PALLETS
      PLACES
      NETTO
      GROSS
      PAYMENT_DATE
      CREATOR
      ORDERNO
      GOODS
      COMMENT
      COUNTRY
      PREPAID_AMOUNT
      client {
        NAME
        ID
        VAT
      }
      author {
        RNAME
        ID
        UNAME
      }
    }
  }
`;

export const GetInvoiceRows = gql`
  query getInvoiceRows ($invoiceId: Int!) {
    getInvoiceRows (invoiceId: $invoiceId) {
      ID
      INVOICE_ID
      PRODUCT_ID
      production {
        ID
        ORDERNO
        KLIENT_ORDER
        KLIENT_BOXCODE
        RKOGUS
        MOOT
        PRICE
        ALUSEID
        BOX
        ADDRESS
      }
    }
  }
`;

export const GetInvoiceExtraRows = gql`
  query getInvoiceExtraRows ($invoiceId: Int!) {
    getInvoiceExtraRows (invoiceId: $invoiceId) {
      ID
      PRICE
      AMOUNT
      INVOICE_ID
      PRODUCT_ID
      product {
        ID
        PRODUCT
        PRICE
        CODE
      }
    }
  }
`;

export const DeliveryNotesQuery = gql`
  query deliveryNotesQuery ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $filters: [FiltersInput], $extraFilters: FilterInput) {
    deliveryNotesQuery (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, filters: $filters, extraFilters: $extraFilters) {
      count
      rows {
        ID
        CREATED_DATE
        CLIENT_ID
        CREATOR
        ORDERNO
        PALLETS
        PALLET_COSTS
        BOOKKEEPING
        COMMENT
        client {
          NAME
          ID
          VAT
          ADDRESS
        }
        author {
          RNAME
          ID
          UNAME
        }
      }
    }
  }
`;

export const GetDeliveryNotes = gql`
  query getDeliveryNotes ($page: Int!, $limit: Int!) {
    getDeliveryNotes (page: $page, limit: $limit) {
      count
      rows {
        ID
        CREATED_DATE
        CLIENT_ID
        CREATOR
        ORDERNO
        PALLETS
        PALLET_COSTS
        BOOKKEEPING
        COMMENT
        client {
          NAME
          ID
          VAT
          ADDRESS
        }
        author {
          RNAME
          ID
          UNAME
        }
      }
    }
  }
`;

export const GetDeliveryNotesByProd = gql`
  query getDeliveryNotesByProd ($prodId: Int!) {
    getDeliveryNotesByProd (prodId: $prodId) {
      ID
      CREATED_DATE
      CLIENT_ID
      CREATOR
      ORDERNO
      PALLETS
      PALLET_COSTS
      BOOKKEEPING
      COMMENT
      client {
        NAME
        ID
        VAT
        ADDRESS
      }
      author {
        RNAME
        ID
        UNAME
      }
      rows {
        ID
        AMOUNT
      }
    }
  }
`;

export const GetDeliveryNote = gql`
  query getDeliveryNote ($id: Int!) {
    getDeliveryNote (id: $id) {
      ID
      CREATED_DATE
      CLIENT_ID
      CREATOR
      ORDERNO
      PALLETS
      PALLET_COSTS
      BOOKKEEPING
      COMMENT
      client {
        NAME
        ID
        VAT
        ADDRESS
      }
      author {
        RNAME
        ID
        UNAME
      }
    }
  }
`;

export const GetDeliveryNoteWithRows = gql`
  query getDeliveryNoteWithRows ($id: Int!) {
    getDeliveryNoteWithRows (id: $id) {
      ID
      CREATED_DATE
      CLIENT_ID
      CREATOR
      ORDERNO
      PALLETS
      PALLET_COSTS
      BOOKKEEPING
      COMMENT
      client {
        NAME
        ID
        VAT
        ADDRESS
      }
      author {
        RNAME
        ID
        UNAME
      }
      rows {
        ID
        INVOICE_ID
        PRODUCT_ID
        AMOUNT
        production {
          ID
          ORDERNO
          KLIENT_ORDER
          KLIENT_BOXCODE
          RKOGUS
          MOOT
          PRICE
          ALUSEID
          BOX
          ADDRESS
          PALLET
          TRANSPORT
          STANTS
          KLISHEE
        }
      }
    }
  }
`;

export const GetDeliveryNoteRows = gql`
  query getDeliveryNoteRows ($noteId: Int!) {
    getDeliveryNoteRows (noteId: $noteId) {
      ID
      INVOICE_ID
      PRODUCT_ID
      AMOUNT
      production {
        ID
        ORDERNO
        KLIENT_ORDER
        KLIENT_BOXCODE
        RKOGUS
        MOOT
        PRICE
        ALUSEID
        BOX
        ADDRESS
        PALLET
        TRANSPORT
        STANTS
        KLISHEE
      }
    }
  }
`;

export const GetConstants = gql`
  query getConstants {
    getConstants {
      ID
      PRINTPRICE
      FAILURE
      DSTART
      DEND
      COLOR1
      COLOR2
      ROTAT
      KOEF
      ORDERNO
      PRODNO
      OFFSET
      INVOICENO
      PALLETPRICE
      MATERIAL_ORDERNO
      DNS
      SMTP_SERVER
      EMAIL
      TEMPLATE
      TELLIMUSNO
      XXX_TIMER
      INVOICENO_DOM
      PALLETPRICE_DOM
      PREPAID_INVOICE
      CREDIT_NOTE
      SSL_TYPE
      PORT
      DIRECTO
    }
  }
`

export const GetExtraProducts = gql`
  query getExtraProducts {
    getExtraProducts {
      ID
      PRODUCT
      PRICE
      CODE
    }
  }
`

export const GetMaterialsByFactory = gql`
  query getMaterialsByFactory ($factory: String!) {
    getMaterialsByFactory (factory: $factory) {
      FACTORY
      MARK
      MARK2
      PRICE
      LAYER
      ACTIVE
      TYPE
      CODE
      DENSITY
    }
  }
`;

export const GetPrintouts = gql`
  query getPrintouts ($prodId: Int!) {
    getPrintouts (prodId: $prodId) {
      id
      prod_id
      bottom
      height
      count
      items
    }
  }
`;

export const GetPallets = gql`
  query getPallets {
    getPallets {
      id
      name
    }
  }
`;

export const SearchMaterialSizes = gql`
query searchMaterialSizes ($searchInput: String!, $box: Boolean) {
  searchMaterialSizes (searchInput: $searchInput, box: $box)
}
`;

export const SearchMaterialNuut = gql`
query searchMaterialNuut ($searchInput: String!) {
  searchMaterialNuut (searchInput: $searchInput)
}
`;

export const SearchMaterial = gql`
query searchMaterial ($searchInput: String!) {
  searchMaterial (searchInput: $searchInput) {
    MARK
  }
}
`;

export const GetFileTypes = gql`
query getFileTypes {
  getFileTypes {
    ID
    NAME
  }
}
`;

export const GetFiles = gql`
query getFiles ($orderRowId: Int!) {
  getFiles (orderRowId: $orderRowId) {
    ID
    FILENAME
    fileType {
      ID
      NAME
    }
    user {
      ID
      RNAME
    }
  }
}
`;

export const SearchAllMaterial = gql`
query searchMaterial ($searchInput: String!) {
  searchMaterial (searchInput: $searchInput) {
    MARK
    FACTORY
    MARK2
    PRICE
    LAYER
    ACTIVE
    TYPE
    CODE
    DENSITY
  }
}
`;

export const SearchProduction = gql`
query searchProduction ($searchInput: String!) {
  searchProduction (searchInput: $searchInput) {
    ID
    ORDERNO
    ORDER_DATE
    DEADLINE
    KLIENT_ORDER
    KLIENT_ID
    START_DATE
    FIN_DATE
    SHIPPED
    TRANSPORT_DATE
    NEW_DEADLINE
    PRODUCTION_CODE
    BOX
    MOOT
    MATERIAL
    KLIENT_BOXCODE
    KOGUS
    PARTIAL_AMOUNT
    TELLIMUSNO
    TRANSPORDIFIRMA
    RKOGUS
    PRICE
    ALUSEID
    KLISHEE
    STANTS
    TRANSPORT
    PALLET
    ADDRESS
    KOMMENTAAR

    creator {
      ID
      RNAME
    }

    client {
      ID
      NAME
      REGNO
      EMAIL
      PHONE
      ETTEMAKS
      ADDRESS
      MAKSEAEG
      TRANSFIRMA
      DEPT

      manager {
        ID
        RNAME
      }
    }
  }
}
`;

export const GetBasicReport = gql`
query getBasicReport ($start: Date!, $end: Date!, $period: String!, $shipped: Boolean) {
  getBasicReport (start: $start, end: $end, period: $period, shipped: $shipped) {
    value
    label
  }
}
`;

export const GetQuotationsReport = gql`
query getQuotationsReport ($start: Date!, $end: Date!, $period: String!) {
  getQuotationsReport (start: $start, end: $end, period: $period) {
    value
    label
  }
}
`;

export const GetYearlyReport = gql`
query getYearlyReport ($start: Date!, $end: Date!, $shipped: Boolean, $type: String) {
  getYearlyReport (start: $start, end: $end, shipped: $shipped, type: $type) {
    value
    month
    year
  }
}
`;

export const GetSalesReport = gql`
query getSalesReport ($start: Date!, $end: Date!, $shipped: Boolean) {
  getSalesReport (start: $start, end: $end, shipped: $shipped) {
    value
    label
  }
}
`;

export const GetQuotationsUserReport = gql`
query getQuotationsUserReport ($start: Date!, $end: Date!) {
  getQuotationsUserReport (start: $start, end: $end) {
    value
    label
  }
}
`;

export const GetCompanyReport = gql`
query getCompanyReport ($start: Date!, $end: Date!, $shipped: Boolean) {
  getCompanyReport (start: $start, end: $end, shipped: $shipped) {
    value
    label
  }
}
`;

export const GetSingleCompanyReport = gql`
query getSingleCompanyReport ($start: Date!, $end: Date!, $period: String!, $client: Int!, $shipped: Boolean) {
  getSingleCompanyReport (start: $start, end: $end, period: $period, client: $client, shipped: $shipped) {
    value
    label
  }
}
`;

export const GetLostClients = gql`
query getLostClients ($date: Date!) {
  getLostClients (date: $date) {
    value
    label
  }
}
`;

export const GetUserRealisation = gql`
query getUserRealisation ($start: Date!, $end: Date!, $period: String!, $shipped: Boolean) {
  getUserRealisation (start: $start, end: $end, period: $period, shipped: $shipped) {
    value
    period
    name
  }
}
`;