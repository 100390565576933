import React, { useState } from 'react'

const Textarea = ({label, placeholder, value, onChange, error, focus, onKeyUp, maxLength, onFocus, onBlur, disabled}) => {

  const [isFocused, setIsFocused] = useState(false)

  const handleFocus = () => {
    setIsFocused(true)
    if (onFocus) onFocus()
  }
  const handleBlur = () => {
    setIsFocused(false)
    if (onBlur) onBlur()
  }

  return (
    <div className={`input-wrapper${error ? ' input-wrapper--error' : ''}`}>
      <label>
        { label && <span>{ label }</span> }
        <div className={`input-wrapper--control${isFocused ? ' input-wrapper--control-focused' : ''}`}>
          <div className='input-wrapper--input'>
            <textarea
              autoFocus={focus}
              placeholder={placeholder}
              onChange={onChange}
              value={value ? value : ''}
              onKeyUp={onKeyUp}
              maxLength={maxLength}
              disabled={disabled}
              onFocus={handleFocus}
              onBlur={handleBlur}
            ></textarea>
          </div>
        </div>
      </label>
      { error &&
        <div className="input-error">{ error }</div>
      }
    </div>
  )
}

export default Textarea