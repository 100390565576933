import { useMutation, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CreateMaterialOrderItem, UpdateMaterialOrderItem } from '../graphql/mutations'
import { GetMaterialOrderRow, GetRelatedMaterialOrders, ProductionsQuery, SearchMaterial, SearchMaterialNuut, SearchMaterialSizes } from '../graphql/queries'
import { parseDate } from '../util/format'
import Button from './Button'
import Checkbox from './Checkbox'
import CreatableInput from './CreatableInput'
import DateInput from './DateInput'
import Input from './Input'
import Modal from './Modal'
import AdditionalModal from './AdditionalModal'
import SelectInput from './SelectInput'
import Textarea from './Textarea'

const initialItemState = {
    ORDER_ID: '',
    SIZE: '',
    SIZE_BOX: '',
    MARK: '',
    MARK_COMMENT: '',
    AMOUNT: '',
    DELIVERY_DATE: null,
    NUUT: '',
    REMARK: '',
    PRICE: 0,
    CLIENT_ID: '',
    BOX_DATE: null,
    SPEED: 0,
    BOX_SIZE: '',
    BOX_TYPE: '',
    PROD_ID: '',
    FINISHED_DATE: null,
    PRIORITY: 0,
}

const initialErrorState = {
    size: null,
    amount: null,
    deliveryDate: null,
    quality: null,
}

const AddMaterialModal = ({
    orderId,
    orderRowId,
    showModal,
    close,
    orderUpdated,
    productions,
}) => {

    const { t } = useTranslation()

    const [errors, setErrors] = useState(initialErrorState)
    const [errorMessage, setErrorMessage] = useState(null)
    const [item, setItem] = useState({
        ...initialItemState,
        ORDER_ID: parseInt(orderId),
    })
    const [showProductionCodeModal, setShowProductionCodeModal] = useState(false)
    const [productionCodeRows, setProductionCodeRows] = useState([])
    const [createMaterialOrderItem] = useMutation(CreateMaterialOrderItem)
    const [updateMaterialOrderItem] = useMutation(UpdateMaterialOrderItem)

    const { refetch: searchSize } = useQuery(SearchMaterialSizes, {
        variables: {
            searchInput: '',
        },
        fetchPolicy: 'no-cache',
    })

    useQuery(GetMaterialOrderRow, {
        variables: {
            id: parseInt(orderRowId),
        },
        skip: !orderRowId,
        onCompleted: (data) => {
            const { getMaterialOrderRow } = data
            if (getMaterialOrderRow) {
                setItem(getMaterialOrderRow)
            }
        },
        fetchPolicy: 'no-cache',
    })

    const { refetch: searchMaterial } = useQuery(SearchMaterial, {
        variables: {
            searchInput: '',
        },
        fetchPolicy: 'no-cache',
    })

    const { refetch: searchMaterialNuut } = useQuery(SearchMaterialNuut, {
        variables: {
            searchInput: '',
        },
        fetchPolicy: 'no-cache',
    })

    const { refetch: fetchRelated } = useQuery(GetRelatedMaterialOrders, {
        skip: true,
        fetchPolicy: 'no-cache',
    })

    const addToOrder = async () => {
        if (!validateInput()) return

        try {
            const res = await createMaterialOrderItem({
                variables: {
                    orderId: parseInt(orderId),
                    data: item,
                },
            })

            if (res?.data?.createMaterialOrderItem === 'Duplicate') {
                setErrorMessage('Sellele tootele on juba tellimus tehtud')
                return
            }

            handleClose()
            if (orderUpdated) orderUpdated()
        } catch (err) {
            console.log('Error adding new material row', err)
        }
    }

    const updateItem = async () => {
        if (!validateInput() || !item.ID) return

        try {
            const data = {
                ...item,
                ID: undefined,
                __typename: undefined,
            }
            const res = await updateMaterialOrderItem({
                variables: {
                    id: parseInt(item.ID),
                    data,
                },
            })

            if (res?.data?.updateMaterialOrderItem === 'Duplicate') {
                setErrorMessage('Sellele tootele on juba tellimus tehtud')
                return
            }

            handleClose()
            if (orderUpdated) orderUpdated()
        } catch (err) {
            console.log('Error updating new material row', err)
        }
    }

    const loadSizes = async (input, box = false) => {
        if (box && input.length < 2 || !box && input.length < 3) return
        const { data } = await searchSize({
            searchInput: input,
            box,
        })
        if (data && data.searchMaterialSizes) {
            const items = data.searchMaterialSizes.map(x => ({
                label: x,
                value: x,
            }))
            return items
        }
        return []
    }

    const loadNuuts = async (input) => {
        if (input.length < 1) return
        const { data } = await searchMaterialNuut({
            searchInput: input,
        })
        if (data && data.searchMaterialNuut) {
            const items = data.searchMaterialNuut.map(x => ({
                label: x,
                value: x,
            }))
            return items
        }
        return []
    }

    const loadMaterial = async (input) => {
        if (input.length < 1) return
        const { data } = await searchMaterial({
            searchInput: input,
        })
        if (data && data.searchMaterial) {
            return data.searchMaterial
        }
        return []
    }

    const handleCreatableInputChange = (option, field) => {
        setField(field, option.value)
    }

    const handleOrderSelect = async (option) => {
        setItem({
            ...item,
            PROD_ID: option.ID,
            CLIENT_ID: option.KLIENT_ID,
            BOX_DATE: option.DEADLINE,
            BOX_SIZE: option.MOOT,
            BOX_TYPE: option.BOX,
        })

        try {
            const { data } = await fetchRelated({
                productionCode: option.PRODUCTION_CODE,
            })
            if (data?.getRelatedMaterialOrders?.length > 0) {
                setProductionCodeRows(data.getRelatedMaterialOrders)
                setShowProductionCodeModal(true)
            }
        } catch (err) {
            console.log('handleOrderSelect', err)
        }
    }

    const setField = (field, value) => {
        setItem({
            ...item,
            [field]: value,
        })
    }

    const validateInput = () => {
        const errState = errors
        setErrorMessage(null)
        let isValid = true

        if (!item.SIZE) {
            errState.size = t('Size is required')
            isValid = false
        }
        if (!item.AMOUNT) {
            errState.amount = t('Amount is required')
            isValid = false
        }
        if (!item.DELIVERY_DATE) {
            errState.deliveryDate = t('Delivery date is required')
            isValid = false
        }
        if (!item.MARK) {
            errState.quality = t('Quality is required')
            isValid = false
        }

        setErrors({
            ...errors,
            ...errState,
        })
        return isValid
    }

    const handleProductionCodeClick = (productionCodeItem) => {
        setItem({
            ...item,
            ORDER_ID: parseInt(orderId),
            AMOUNT: productionCodeItem.AMOUNT,
            SIZE: productionCodeItem.SIZE,
            SIZE_BOX: productionCodeItem.SIZE_BOX,
            NUUT: productionCodeItem.NUUT,
            MARK: productionCodeItem.MARK,
            MARK_COMMENT: productionCodeItem.MARK_COMMENT,
        })
        setShowProductionCodeModal(false)
        setProductionCodeRows([])
    }

    const handleClose = () => {
        setErrors(initialErrorState)
        setItem({
            ...initialItemState,
            ORDER_ID: parseInt(orderId),
            ID: undefined,
            __typename: undefined,
        })
        if (close) close()
    }

    const getArea = (size, amount) => {
        const measurements = size?.split('x')
        amount = amount ? amount : 0
        if (!measurements || measurements.length < 2) return ''
        return (measurements[0] * measurements[1] / 1000000 * amount).toFixed(2)
    }

    const renderProductionOption = ({ innerProps, innerRef, data, isFocused, isSelected }) => {
        return (
            <div
                ref={innerRef}
                {...innerProps}
                className={
                    `select-option${isFocused ? ' select-option--focused' : ''}${isSelected ? ' select-option--selected' : ''} select-option--production-option`
                }
            >
                <span>
                    { data.ORDERNO }
                </span>
                <span>
                    { data.PRODUCTION_CODE }
                </span>
                <span>
                    { data.client.NAME }
                </span>
            </div>
        )
    }

    const renderProductionData = () => {
        const selectedProduction = productions.find(x => x.ID === item.PROD_ID)
        if (!selectedProduction) return (<></>)
        return (
            <div className='production-data'>
                <h5>{ t('Tootmise andmed') }</h5>
                <div className='production-data--inner'>
                    <div className='field-item'>
                        <span className='field-item--label'>{ t('Klient') }</span>
                        <span className='field-item--value'>{ selectedProduction.client.NAME }</span>
                    </div>
                    <div className='field-item'>
                        <span className='field-item--label'>{ t('Karbi tüüp') }</span>
                        <span className='field-item--value'>{ selectedProduction.BOX }</span>
                    </div>
                    <div className='field-item'>
                        <span className='field-item--label'>{ t('Karbi mõõt') }</span>
                        <span className='field-item--value'>{ selectedProduction.MOOT }</span>
                    </div>
                    <div className='field-item'>
                        <span className='field-item--label'>{ t('Karbi tarne') }</span>
                        <span className='field-item--value'>{ selectedProduction.DEADLINE }</span>
                    </div>
                    <div className='field-item'>
                        <span className='field-item--label'>{ t('Toote kood') }</span>
                        <span className='field-item--value'>{ selectedProduction.PRODUCTION_CODE }</span>
                    </div>
                </div>
            </div>
        )
    }

    const renderModalActions = () => {
        return (
            <div className='modal-actions'>
                <Button
                    label={t('Katkesta')}
                    onClick={handleClose}
                    className={'btn-cancel'}
                />
                {
                    item.ID ?
                    <Button
                        label={t('Uuenda')}
                        onClick={updateItem}
                    />
                    :
                    <Button
                        label={t('Lisa tellimusse')}
                        onClick={addToOrder}
                    />
                }
            </div>
        )
    }

    return (
        <>
            <Modal
                title={t('Lisa materjal tellimusse')}
                close={handleClose}
                show={showModal}
                className={'modal--add-material'}
                renderActions={renderModalActions}
            >
                <div className='field-row'>
                    <div className='field-col margin-right-0'>
                        {
                            productions &&
                            <SelectInput
                                label={`${t('Müügi number')}`}
                                options={productions}
                                value={productions.find(x => x.ID === item.PROD_ID)}
                                getOptionLabel={(option) => option.ORDERNO}
                                getOptionValue={(option) => option.ID}
                                onChange={handleOrderSelect}
                                components={{
                                    Option: renderProductionOption,
                                }}
                            />
                        }
                        { renderProductionData() }
                    </div>
                </div>
                <div className='field-row'>
                    <div className='field-col'>
                        <CreatableInput
                            label={`${t('Suurus (LxB)')}`}
                            value={item.SIZE ? {
                                label: item.SIZE,
                                value: item.SIZE,
                            } : null}
                            load={loadSizes}
                            loadingMessage={() => t('Laen suuruseid')}
                            noOptionsMessage={() => t('Kirjuta, et otsida suurust')}
                            onChange={(val) => handleCreatableInputChange(val, 'SIZE')}
                            placeholder={`${t('Pikkus')} x ${t('Laius')}`}
                            error={errors.size}
                        />
                    </div>
                    <div className='field-col'>
                        <CreatableInput
                            label={`${t('Suurus (LxBxH)')}`}
                            value={item.SIZE_BOX ? {
                                label: item.SIZE_BOX,
                                value: item.SIZE_BOX,
                            } : null}
                            load={(input) => {
                                return loadSizes(input, true)
                            }}
                            loadingMessage={() => t('Laen suuruseid')}
                            noOptionsMessage={() => t('Kirjuta, et otsida suurust')}
                            onChange={(val) => handleCreatableInputChange(val, 'SIZE_BOX')}
                            placeholder={`${t('Pikkus')} x ${t('Laius')} x ${t('Kõrgus')}`}
                        />
                    </div>
                </div>
                <div className='field-row'>
                    <div className='field-col'>
                        <SelectInput
                            label={`${t('Kvaliteet')}`}
                            value={item.MARK ? {
                                MARK: item.MARK,
                            } : null}
                            load={(input) => {
                                return loadMaterial(input)
                            }}
                            loadingMessage={() => t('Laen kvaliteete')}
                            noOptionsMessage={() => t('Kirjuta, et otsida kvaliteeti')}
                            getOptionLabel={(option) => option.MARK}
                            getOptionValue={(option) => option.MARK}
                            onChange={(val) => setItem({...item, MARK: val.MARK ? val.MARK : ''})}
                            error={errors.quality}
                        />
                    </div>
                    <div className='field-col'>
                        <CreatableInput
                            label={`${t('Nuut')}`}
                            value={item.NUUT ? {
                                label: item.NUUT,
                                value: item.NUUT,
                            } : null}
                            load={(input) => {
                                return loadNuuts(input)
                            }}
                            loadingMessage={() => t('Laen nuute')}
                            noOptionsMessage={() => t('Kirjuta, et otsida nuute')}
                            onChange={(val) => handleCreatableInputChange(val, 'NUUT')}
                            placeholder={'10,10*,10'}
                        />
                    </div>
                </div>
                <div className='field-row align-items--center justify-content--space-between'>
                    <div className='field-col'>
                        <Input
                            label={t('Kogus')}
                            value={item.AMOUNT}
                            onChange={(e) => setField('AMOUNT', parseInt(e.target.value))}
                            error={errors.amount}
                        />
                    </div>
                    <div className='field-col'>
                        <Input
                            type={'number'}
                            label={t('Hind')}
                            value={item.PRICE}
                            onChange={(e) => setField('PRICE', parseFloat(e.target.value))}
                        />
                    </div>
                    <div className='field-col field-col--asap'>
                        <Checkbox
                            label={t('ASAP')}
                            value={item.SPEED === 1}
                            onChange={(e) => setField('SPEED', e.target.checked ? 1 : 0)}
                        />
                    </div>
                    <div className='field-col field-col--area'>
                        <Input
                            value={getArea(item.SIZE, item.AMOUNT)}
                            label={t('Pindala')}
                            disabled
                        />
                    </div>
                </div>
                <div className='field-row'>
                    <div className='field-col'>
                        <DateInput
                            label={t('Saabumine')}
                            value={item.DELIVERY_DATE ? new Date(item.DELIVERY_DATE) : null}
                            onChange={(val) => setField('DELIVERY_DATE', parseDate(val))}
                            error={errors.deliveryDate}
                        />
                    </div>
                    <div className='field-col field-col--remark'>
                        <Textarea
                            label={t('Märkus')}
                            value={item.REMARK}
                            onChange={(e) => setField('REMARK', e.target.value)}
                        />
                    </div>
                </div>
                {
                    errorMessage &&
                    <div className='errors'>
                        <div className='errors-message'>{ errorMessage }</div>
                    </div>
                }
            </Modal>
            <AdditionalModal
                show={showProductionCodeModal}
                title={`Toote kood: ${productionCodeRows[0]?.production?.PRODUCTION_CODE}`}
                close={() => {
                    setShowProductionCodeModal(false)
                    setProductionCodeRows([])
                }}
            >
                <table>
                    <thead>
                        <tr>
                            <th>{ t('Karbi tüüp') }</th>
                            <th>{ t('Materjal') }</th>
                            <th>{ t('Karbi mõõt') }</th>
                            <th>{ t('LxB') }</th>
                            <th>{ t('LxBxH') }</th>
                            <th>{ t('Kvaliteet') }</th>
                            <th>{ t('Märkus') }</th>
                            <th>{ t('Nuudid') }</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            productionCodeRows.map((x) =>
                                <tr key={x.ID} className='production-code-item' onClick={() => handleProductionCodeClick(x)}>
                                    <td>{ x.production?.BOX }</td>
                                    <td>{ x.production?.MATERIAL }</td>
                                    <td>{ x.production?.MOOT }</td>
                                    <td>{ x.SIZE }</td>
                                    <td>{ x.SIZE_BOX }</td>
                                    <td>{ x.MARK }</td>
                                    <td>{ x.MARK_COMMENT }</td>
                                    <td>{ x.NUUT }</td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </AdditionalModal>
        </>
    )
}

export default AddMaterialModal