import { useMutation, useQuery } from '@apollo/client'
import jwtDecode from 'jwt-decode'
import React, { useState, useEffect, useContext, createContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Login, ValidateToken } from '../graphql/mutations'
import { GetConstants } from '../graphql/queries'

const authContext = createContext()

export const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth()

  return <authContext.Provider
            value={ auth }
        >
            { children }
        </authContext.Provider>
}

export const useAuth = () => {
  return useContext(authContext)
}

const useProvideAuth = () => {
  const [user, setUser] = useState(null)
  const [constants, setConstants] = useState({})
  const [loggedIn, setLoggedIn] = useState(false)
  const [theme, setTheme] = useState(localStorage.getItem('theme'))
  const [login] = useMutation(Login)
  const [validateToken] = useMutation(ValidateToken)
  const navigate = useNavigate()

  useQuery(GetConstants, {
    onCompleted: (data) => {
      const { getConstants } = data
      if (getConstants) setConstants(getConstants)
    }
  })

  useEffect(() => {
    (async () => {
      await validate()
    })()
  }, [])

  useEffect(() => {
    if (theme === 'dark') {
      localStorage.setItem('theme', 'dark')
      document.body.classList.remove('theme--light')
      document.body.classList.add('theme--dark')
      return
    }
    localStorage.setItem('theme', 'light')
    document.body.classList.remove('theme--dark')
    document.body.classList.add('theme--light')
  }, [theme])

  const signIn = async (username, password) => {
    try {
      const { data } = await login({
        variables: {
          username,
          password,
        },
      })

      if (data && data.login) {
        localStorage.setItem('token', data.login)
        await validate()
      }
      return true
    } catch (err) {
      console.log('Login error', err)
      return false
    }
  }

  const validate = async () => {
    const token = localStorage.getItem('token')
    if (!token) {
      navigate('/login')
      return
    }

    try {
      const { data } = await validateToken({
        variables: {
          token,
        },
      })

      if (data?.validateToken) {
        const userData = jwtDecode(token)

        setUser(userData)
        setLoggedIn(true)
        return
      }
    } catch (err) {
      console.log('Error validating token', err)
      signOut()
    }
  }

  const signOut = () => {
    localStorage.removeItem('token')
    setUser(null)
    setLoggedIn(false)
    navigate('/login')
  }

  return {
    user,
    loggedIn,
    constants,
    signIn,
    signOut,
    setTheme,
    theme,
  }
}