import React from 'react'
import { useTranslation } from 'react-i18next'
import Checkbox from './Checkbox'

const BoxWorkflow = ({
    checkboxes,
    onCheckboxChange,
    onFieldBlur,
}) => {

    const { t } = useTranslation()

    return (
        <div className='box-fields--workflow'>
            {
                checkboxes && checkboxes.map((x, i) =>
                    <Checkbox
                        key={`workflow-${i}`}
                        label={t(x.field)}
                        value={x.value === 1}
                        onBlur={onFieldBlur}
                        onChange={(e) => {
                            if (!onCheckboxChange) return
                            onCheckboxChange(x.field, e.target.checked ? 1 : 0)
                        }}
                    />
                )
            }
        </div>
    )
}

export default BoxWorkflow