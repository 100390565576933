import { addDays } from '../util/time'

const getProductStatusColor = (row) => {
    // Väljastatud (MoneyGreen)
    if (new Date(row.SHIPPED) > new Date('1970-1-2')) return '#86d7b0'
    // Tähtaeg ületatud (Red)
    if (
        new Date(row.DEADLINE) > new Date('1970-1-2')
        && new Date(row.DEADLINE) < new Date()
        && (new Date(row.SHIPPED) < new Date('1970-1-2') || !row.SHIPPED || row.SHIPPED === '0000-00-00')
    ) return '#ff6565'
    // Valmis, väljastamata (Green)
    if (
        new Date(row.DEADLINE) > new Date('1970-1-2')
        && new Date(row.DEADLINE) < new Date()
        && new Date(row.FIN_DATE) > new Date('1970-1-2')
        && (new Date(row.SHIPPED) < new Date('1970-1-2') || !row.SHIPPED || row.SHIPPED === '0000-00-00')
    ) return '#81c71a'
    // Tellitud transport (Fuchsia)
    if (
        new Date(row.TRANSPORT_DATE) > new Date('1970-1-2')
    ) return '#ff58faf7'
    // Valmis (SkyBlue)
    if (
        new Date(row.FIN_DATE) > new Date('1970-1-2')
        && (new Date(row.SHIPPED) < new Date('1970-1-2') || !row.SHIPPED || row.SHIPPED === '0000-00-00')
    ) return '#a4f7fd'
    // Ettemaks tasumata (Yellow)
    if (
        row.client
        && row.client.ETTEMAKS === 1
        && row.ETTEMAKS === 0
    ) return '#fef711'
    // Ootel (Silver)
    if (
        new Date(row.DEADLINE) > new Date('1970-1-2')
        && (new Date(row.START_DATE) < new Date('1970-1-2') || !row.START_DATE || row.START_DATE === '0000-00-00')
    ) return '#cccccc'
    // Võlglane (Maroon)
    if (
        row.client
        && row.client.DEPT
    ) return '#a32f41'
    // Tootmises (White)
    if (
        new Date(row.START_DATE) > new Date('1970-1-2')
        && (new Date(row.FIN_DATE) < new Date('1970-1-2') || !row.FIN_DATE || row.FIN_DATE === '0000-00-00')
    ) return '#fff'
}

const getProductStatusClass = (row) => {
    let baseClass = `production-status `
    // Väljastatud (MoneyGreen)
    if (new Date(row.SHIPPED) > new Date('1970-1-2')) return baseClass + 'production-status--shipped'
    // Valmis, väljastamata (Green)
    if (
        new Date(row.DEADLINE) > new Date('1970-1-2')
        && new Date(row.DEADLINE) < new Date()
        && new Date(row.FIN_DATE) > new Date('1970-1-2')
        && (new Date(row.SHIPPED) < new Date('1970-1-2') || !row.SHIPPED || row.SHIPPED === '0000-00-00')
    ) return baseClass + 'production-status--ready-not-shipped'
    // Tellitud transport (Fuchsia)
    if (
        new Date(row.TRANSPORT_DATE) > new Date('1970-1-2')
    ) return baseClass + 'production-status--transport'
    // Valmis (SkyBlue)
    if (
        new Date(row.FIN_DATE) > new Date('1970-1-2')
        && (new Date(row.SHIPPED) < new Date('1970-1-2') || !row.SHIPPED || row.SHIPPED === '0000-00-00')
    ) return baseClass + 'production-status--ready'
    // Ettemaks tasumata (Yellow)
    if (
        row.client
        && row.client.ETTEMAKS === 1
        && row.ETTEMAKS === 0
    ) return baseClass + 'production-status--unpaid'
    // Võlglane (Maroon)
    if (
        row.client
        && row.client.DEPT
    ) return baseClass + 'production-status--dept'
    // Tähtaeg ületatud (Red)
    if (
        new Date(row.DEADLINE) > new Date('1970-1-2')
        && addDays(row.DEADLINE, 1) <= new Date()
        && (new Date(row.SHIPPED) < new Date('1970-1-2') || !row.SHIPPED || row.SHIPPED === '0000-00-00')
    ) return baseClass + 'production-status--overdue'
    // Ootel (Silver)
    if (
        new Date(row.DEADLINE) > new Date('1970-1-2')
        && (new Date(row.START_DATE) < new Date('1970-1-2') || !row.START_DATE || row.START_DATE === '0000-00-00')
    ) return baseClass + 'production-status--pending'
    // Tootmises (White)
    if (
        new Date(row.START_DATE) > new Date('1970-1-2')
        && (new Date(row.FIN_DATE) < new Date('1970-1-2') || !row.FIN_DATE || row.FIN_DATE === '0000-00-00')
    ) return baseClass + 'production-status--in-production'
}

const chartColors = ['#fd7f6f', '#7eb0d5', '#b2e061', '#bd7ebe', '#ffb55a', '#ffee65', '#beb9db', '#fdcce5', '#8bd3c7']

export {
    getProductStatusColor,
    getProductStatusClass,
    chartColors,
}