import React, { useState } from 'react'

const Input = ({
  type,
  label,
  placeholder,
  defaultValue,
  value,
  onChange,
  error,
  focus,
  onKeyUp,
  maxLength,
  onFocus,
  onBlur,
  disabled,
  suffix,
  className,
}) => {
  const [isFocused, setIsFocused] = useState(false)

  const handleFocus = () => {
    setIsFocused(true)
    if (onFocus) onFocus()
  }
  const handleBlur = () => {
    setIsFocused(false)
    if (onBlur) onBlur()
  }

  return (
    <div className={`input-wrapper${error ? ' input-wrapper--error' : ''}${className ? ' ' + className : ''}`}>
      <label>
        { label && <span>{ label }</span> }
        <div className={`input-wrapper--control${isFocused ? ' input-wrapper--control-focused' : ''}`}>
          <div className='input-wrapper--input'>
            <input
              autoFocus={focus}
              type={type}
              placeholder={placeholder}
              onChange={onChange}
              value={value ? value : ''}
              defaultValue={defaultValue}
              onKeyUp={onKeyUp}
              maxLength={maxLength}
              disabled={disabled}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
          </div>
          { suffix &&
            <span className='input-wrapper--suffix'>
              { suffix }
            </span>
          }
        </div>
      </label>
      { error &&
        <div className="input-error">{ error }</div>
      }
    </div>
  )
}

export default Input