import React, { useEffect, useRef, useState } from 'react'
import './style.scss'
import { useMutation } from '@apollo/client'
import { ProductionsQuery } from '../../graphql/queries'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import { CreateInvoice, CreateInvoiceRow, DuplicateProductionItem, RemoveProductionItem, SendOrderEmail, SetProductionToOrder } from '../../graphql/mutations'
import { useNotification } from '../../providers/Notification'
import { pdf } from '@react-pdf/renderer'
import OrderDocument from '../../documents/order-document'
import { formatDate } from '../../util/format'
import Filters from '../../components/Filters'
import FilterTable from '../../components/FilterTable'
import Modal from '../../components/Modal'
import PdfViewer from '../../components/PdfViewer'
import { getProductStatusClass } from '../../util/colors'
import ConfirmModal from '../../components/ConfirmModal'
import { ProductionCreated, ProductionsUpdated } from '../../graphql/subscriptions'

const includeFields = [
    {
        value: 'ORDERNO',
        label: 'Tellimus',
    },
    {
        value: 'ORDER_DATE',
        label: 'Tellimuse kuup.',
    },
    {
        value: 'PRODUCTION_CODE',
        label: 'Toote kood',
    },
    {
        value: 'NAME',
        label: 'Klient',
    },
    {
        value: 'KLIENT_ORDER',
        label: 'Kliendi tellimus',
    },
    {
        value: 'TELLIMUSNO',
        label: 'Tellimus',
    },
    {
        value: 'KLIENT_BOXCODE',
        label: 'Karbi kood',
    },
    {
        value: 'MOOT',
        label: 'Mõõt',
    },
    {
        value: 'MATERIAL',
        label: 'Materjal',
    },
    {
        value: 'PRICE',
        label: 'Hind',
    },
    {
        value: 'KOGUS',
        label: 'Kogus',
    },
    {
        value: 'RKOGUS',
        label: 'Tegelik kogus',
    },
    {
        value: 'BOX',
        label: 'FEFCO',
    },
    {
        value: 'TRYKK',
        label: 'Trükk',
    },
    {
        value: 'DEADLINE',
        label: 'Tähtaeg',
    },
    {
        value: 'NEW_DEADLINE',
        label: 'Reaalne tähtaeg',
    },
    {
        value: 'START_DATE',
        label: 'Tootmise algus',
    },
    {
        value: 'FIN_DATE',
        label: 'Tootmise lõpp',
    },
    {
        value: 'ALUSEID',
        label: 'Aluseid',
    },
    {
        value: 'TRANSPORDIFIRMA',
        label: 'Transport',
    },
    {
        value: 'KLIENT_INFORMED',
        label: 'Teavitatud',
    },
    {
        value: 'SHIPPED',
        label: 'Väljastatud',
    },
    {
        value: 'ADDRESS',
        label: 'Tarne aadress',
    },
    {
        value: 'KOMMENTAAR',
        label: 'Kommentaar',
    },
    {
        value: 'SISESTAJA_NIMI',
        label: 'Sisestaja',
    },
    {
        value: 'HALDURNAME',
        label: 'Müügihaldur',
    },
    {
        value: 'ETTEMAKS',
        label: 'Ettemaks',
    },
    {
        value: 'HINNAPAKKUMINE',
        label: 'Hinnapakkumine',
    },
    {
        value: 'DEPT',
        label: 'Võlglane',
    },
    {
        value: 'TRANSPORT_DATE',
        label: 'Tellitud',
    },
    {
        value: 'ALUS_MOOT',
        label: 'Aluse mõõt',
    },
    {
        value: 'MAKSEAEG',
        label: 'Makseaeg',
    },
    {
        value: 'SLITTER',
        label: 'Slitter',
    },
    {
        value: 'FLEXO',
        label: 'Flexo',
    },
    {
        value: 'TIGEL',
        label: 'Tigel',
    },
    {
        value: 'VERONA',
        label: 'Verona',
    },
    {
        value: 'TITAN',
        label: 'Titan',
    },
    {
        value: 'SRE',
        label: 'SRE',
    },
    {
        value: 'INLINE',
        label: 'In-Line',
    },
    {
        value: 'SLOTTER',
        label: 'Slotter',
    },
    {
        value: 'KLIIM',
        label: 'K. liim',
    },
    {
        value: 'ALIIM',
        label: 'A. liim',
    },
    {
        value: '_3LIIM',
        label: '3p. liim',
    },
    {
        value: 'LAMINAATOR',
        label: 'Laminaator',
    },
    {
        value: 'AFD',
        label: 'AFD',
    },
    {
        value: 'FPR',
        label: 'FPR',
    },
    {
        value: 'PALLET',
        label: 'PALLET',
    },
]

const handleUpdateSubscription = (prev, { subscriptionData }) => {
    const newData = subscriptionData?.data?.productionsUpdated
    if (!newData || !prev.productionsQuery?.rows?.some(x => x.ID === newData.ID)) return prev

    const itemRows = prev.productionsQuery.rows.map(x => {
        if (x.ID !== newData.ID) return x
        return newData
    })
    
    return Object.assign({}, prev, {
        productionsQuery: {
            ...prev.productionsQuery,
            rows: itemRows,
        },
    })
}

const handleCreateSubscription = (prev, { subscriptionData }) => {
    const newData = subscriptionData?.data?.productionCreated
    if (!newData || prev.productionsQuery?.rows?.some(x => x.ID === newData.ID)) return prev
    
    return Object.assign({}, prev, {
        productionsQuery: {
            ...prev.productionsQuery,
            rows: [
                newData,
                ...prev.productionsQuery.rows,
            ],
        },
    })
}

const subscriptions = [
    {
        document: ProductionsUpdated,
        updateQuery: handleUpdateSubscription,
    },
    {
        document: ProductionCreated,
        updateQuery: handleCreateSubscription,
    },
]

const SalesListScreen = () => {

    const { t } = useTranslation()
    const tableRef = useRef()
    const [selectedRows, setSelectedRows] = useState([])
    const [newInvoice] = useMutation(CreateInvoice)
    const [newInvoiceRow] = useMutation(CreateInvoiceRow)
    const [duplicateRow] = useMutation(DuplicateProductionItem)
    const [makeOrder] = useMutation(SetProductionToOrder)
    const [sendEmail] = useMutation(SendOrderEmail)
    const [removeProductionItem] = useMutation(RemoveProductionItem)
    const [extraFilters, setExtraFilters] = useState()
    const [showModal, setShowModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const { dispatch } = useNotification()

    useEffect(() => {
        const currentDate = new Date()
        const filters = {
            year: `${currentDate.getFullYear()}`,
            month: `${currentDate.getMonth() + 1}`,
        }
        handleFilterChange(filters)
    }, [])

    const fieldConditions = (row, field) => {
        switch (field) {
            case 'ORDER_DATE':
            case 'FIN_DATE':
            case 'START_DATE':
            case 'DEADLINE':
            case 'NEW_DEADLINE':
            case 'TRANSPORT_DATE':
            case 'SHIPPED':
                return formatDate(new Date(row[field]))
            case 'client.NAME':
                return row?.client?.NAME
            case 'order.ORDERNO':
                return row?.order?.ORDERNO
            case 'client.MAKSEAEG':
                return row?.client?.MAKSEAEG
            case 'client.DEPT':
                return row?.client?.DEPT
            case 'client.manager.RNAME':
                return row?.client?.manager?.RNAME
            case 'creator.RNAME':
                return row?.creator?.RNAME
        }
    }

    const createInvoice = async () => {
        if (selectedRows.length < 1) {
            dispatch({
                type: 'ADD',
                payload: {
                    content: t('Vali tellimused'),
                    type: 'error',
                },
            })
            return
        }
        const client = selectedRows[0].KLIENT_ID
        if (selectedRows.some(x => x.KLIENT_ID !== client)) {
            dispatch({
                type: 'ADD',
                payload: {
                    content: t('Mitu erinevat klienti valitud'),
                    type: 'error',
                },
            })
            return
        }
        try {
            const { data } = await newInvoice({
                variables: {
                    data: {
                        CLIENT_ID: client,
                    },
                },
            })

            if (data && data.createInvoice) {
                await Promise.all(selectedRows.map(async (item) => {
                    await newInvoiceRow({
                        variables: {
                            data: {
                                INVOICE_ID: parseInt(data.createInvoice),
                                PRODUCT_ID: parseInt(item.ID),
                            },
                        },
                    })
                }))
                dispatch({
                    type: 'ADD',
                    payload: {
                        content: t('Arve koostatud'),
                        type: 'success',
                    },
                })
                setSelectedRows([])
            }
        } catch (err) {
            console.log('createInvoice', err)
        }
    }

    const duplicate = async () => {
        if (selectedRows.length > 1) {
            dispatch({
                type: 'ADD',
                payload: {
                    content: t('Rohkem kui üks tellimus valitud'),
                    type: 'error',
                },
            })
            return
        }
        if (selectedRows.length <= 0) {
            dispatch({
                type: 'ADD',
                payload: {
                    content: t('Vali tellimus'),
                    type: 'error',
                },
            })
            return
        }
        try {
            const { data } = await duplicateRow({
                variables: {
                    id: parseInt(selectedRows[0].ID),
                },
            })
            if (data && data.duplicateProductionItem !== 'Success') {
                dispatch({
                    type: 'ADD',
                    payload: {
                        content: t('Tellimus kopeeritud'),
                        type: 'success',
                    },
                })
                tableRef.current.refresh()
            }
        } catch (err) {
            console.log('duplicate', err)
        }
    }

    const handleFilterChange = async (filters, refresh = false) => {
        setExtraFilters(filters)
    }

    const handleMakeOrderPreview = () => {
        const orderPdf = pdf(<OrderDocument data={selectedRows} />)
        orderPdf.updateContainer(<OrderDocument data={selectedRows} />)

        handleShowModal()
    }

    const handleMakeOrder = async () => {
        try {
            const ids = selectedRows.map(x => x.ID)
            const { data } = await makeOrder({
                variables: {
                    ids,
                },
            })

            if (data?.setProductionToOrder) {
                const tableRows = tableRef.current?.getTableRowsData()
                const updatedRows = []
                
                selectedRows.forEach(x => {
                    const matchRow = tableRows.find(i => x.ID === i.ID)
                    if (matchRow) updatedRows.push({
                        ...matchRow,
                        TELLIMUSNO: data.setProductionToOrder,
                    })
                })

                handleCloseModal()

                setSelectedRows(updatedRows)

                handleShowModal()

                await handleEmail(updatedRows, parseInt(data.setProductionToOrder))
            }
        } catch (err) {
            console.log('openPdf:', err)
        }
    }

    const handleEmail = async (documentData, orderNo) => {
        if (documentData?.length < 1) return

        try {
            const pdfFile = pdf(<OrderDocument data={documentData} />)
            pdfFile.updateContainer(<OrderDocument data={documentData} />)
            const blob = await pdfFile.toBlob()
            const file = new File([blob], 'file.pdf', { type: 'application/pdf'})

            const res = await sendEmail({
                variables: {
                    orderNo,
                    file,
                },
            })

            if (res?.data?.sendOrderEmail === 'Success') {
                dispatch({
                    type: 'ADD',
                    payload: {
                        type: 'success',
                        content: t('Email saadetud')
                    },
                })
            } else {
                dispatch({
                    type: 'ADD',
                    payload: {
                        type: 'error',
                        content: t('Emaili saatmine ebaõnnestus')
                    },
                })
            }
        } catch (err) {
            console.log('handleEmail:', err)
            dispatch({
                type: 'ADD',
                payload: {
                    type: 'error',
                    content: t('Emaili saatmine ebaõnnestus')
                },
            })
        }
    }

    const handleRowDoubleClick = (item) => {
        window.open(`/sale/${item.ID}`, 'Müügi andmed')
    }

    const handleRowClick = (item) => {
        if (selectedRows.some(x => x.ID === item.ID)) {
            setSelectedRows(selectedRows.filter(x => x.ID !== item.ID))
            return
        }
        setSelectedRows([
            ...selectedRows,
            item,
        ])
    }

    const handleRemoveItem = async () => {
        if (selectedRows.length > 1) {
            dispatch({
                type: 'ADD',
                payload: {
                    content: t('Rohkem kui üks tellimus valitud'),
                    type: 'error',
                },
            })
            return
        }
        try {
            const res = await removeProductionItem({
                variables: {
                    id: parseInt(selectedRows[0].ID),
                },
            })

            if (res?.data?.removeProductionItem === 'Success') {
                dispatch({
                    type: 'ADD',
                    payload: {
                        type: 'success',
                        content: t(`Toode ${selectedRows[0].ORDERNO} kustutatud`)
                    },
                })
                setSelectedRows([])
                setDeleteModal(false)
                tableRef.current?.refresh()
            } else {
                dispatch({
                    type: 'ADD',
                    payload: {
                        type: 'error',
                        content: t(`Toote ${selectedRows[0].ORDERNO} kustutamine ebaõnnestus`)
                    },
                })
            }
        } catch (err) {
            console.log('removeProductItem', err)
        }
    }

    const handleShowModal = () => {
        setShowModal(true)
    }

    const handleCloseModal = () => {
        setShowModal(false)
    }

    return (
        <div className='sales'>
            <div className='inner'>
                <div className='inner-title'>
                    <h2>{ t('Müük') }</h2>
                    <div className='inner-title--actions'>
                        <Button
                            label={t('Koosta arve')}
                            onClick={createInvoice}
                        />
                        <Button
                            label={t('Tee tellimusest koopia')}
                            onClick={duplicate}
                        />
                        {
                            selectedRows && selectedRows.length > 0 ?
                            <>
                                <Button
                                    label={t('Koosta tellimus')}
                                    onClick={() => handleMakeOrderPreview()}
                                />
                                <Button
                                    label={t('Tühista valik')}
                                    onClick={() => setSelectedRows([])}
                                />
                                <Button
                                    label={t('Kustuta tellimus')}
                                    onClick={() => setDeleteModal(true)}
                                />
                            </>
                            :
                            <Button
                                disabled
                                label={t('Koosta tellimus')}
                            />
                        }
                    </div>
                </div>
                <Filters
                    onFilterChange={handleFilterChange}
                />
                <div className='filter-table--container'>
                    <FilterTable
                        query={ProductionsQuery}
                        queryKey={'productionsQuery'}
                        fieldConditions={fieldConditions}
                        includeFields={includeFields}
                        initialOrderBy={'ORDERNO'}
                        extraFilters={extraFilters}
                        statusColorEnabled={true}
                        onRowDoubleClick={handleRowDoubleClick}
                        onRowClick={handleRowClick}
                        activeRows={selectedRows}
                        ref={tableRef}
                        useExtraFilters={true}
                        rowClass={getProductStatusClass}
                        subscriptions={subscriptions}
                    />
                </div>
            </div>
            <Modal
                show={showModal}
                className={'make-order-modal'}
                close={handleCloseModal}
            >
                <PdfViewer document={'order'} data={selectedRows} onSendEmail={handleMakeOrder} />
            </Modal>
            <ConfirmModal
                show={deleteModal}
                confirmLabel={t('Kustuta toode')}
                close={() => setDeleteModal(false)}
                onConfirm={handleRemoveItem}
                title={t('Kas soovid kustutada järgmise toodangu?')}
            >
                {
                    selectedRows.map((x, i) =>
                        <div key={`delete-${i}`}>{ x.ORDERNO }</div>    
                    )
                }
            </ConfirmModal>
        </div>
    )
}

export default SalesListScreen