import React from 'react'
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer'
import UbuntuRegular from '../assets/fonts/Ubuntu/Ubuntu-Regular.ttf'
import UbuntuBold from '../assets/fonts/Ubuntu/Ubuntu-Bold.ttf'
import { useTranslation } from 'react-i18next'
import { formatDate } from '../util/format'

Font.register(
  {
    family: 'Roboto',
    fonts: [
      {
        src: UbuntuRegular,
        fontWeight: 400,
      },
      {
        src: UbuntuBold,
        fontWeight: 700,
      },
    ],
  },
)

const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontSize: 11,
  },
  wrapper: {
    borderWidth: 1,
    borderColor: '#000',
    width: 400,
  },
  title: {
    fontSize: 22,
    fontWeight: 700,
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
  },
  infoRow: {
    flexDirection: 'row',
    paddingVertical: 2,
  },
  tableRow: {
    flexDirection: 'row',
    paddingVertical: 4,
  },
  tableHeadLabel: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 10,
  },
  tableColText: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 10,
  },
  strong: {
    fontFamily: 'Roboto',
    fontWeight: 700,
  },
  col1: {
    width: '25%',
    paddingRight: 3,
  },
  col2: {
    width: '25%',
    paddingRight: 3,
  },
  col3: {
    width: '35%',
    paddingRight: 3,
  },
  col4: {
    width: '15%',
    paddingRight: 3,
  },
  label: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    width: 70,
  },
  value: {
    fontFamily: 'Roboto',
    marginBottom: 4,
  },
  info: {
    fontSize: 10,
  },
  image: {
    width: 70,
    height: 70,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 80,
  },
})

const InvoiceDeliveryDocument = ({
  data,
  user,
}) => {

  const { t } = useTranslation()

  const getOrders = () => {
    if (!data || !data.rows || data.rows.length <= 0) return ''
    const orderList = []
    data.rows.forEach(x => {
      if (x.production && x.production.KLIENT_ORDER) orderList.push(x.production.KLIENT_ORDER)
    })
    return orderList.join(', ')
  }

  if (!data || !data.client) return <></>

  return (
    <Document>
      <Page size="A4" style={styles.page} orientation={'portrait'}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <View
            style={{
              width: '20%',
              marginRight: 20,
            }}
          >
            <Image
              style={styles.image}
              source={require('../assets/logo.png')}
            />
          </View>
          <Text style={styles.title}>Multipakend Tootmine</Text>
        </View>
        <View
          style={[
            styles.infoRow,
            {
              fontFamily: 'Roboto',
              justifyContent: 'space-between',
              fontWeight: 700,
              marginBottom: 20,
              marginTop: 20,
            },
          ]}
        >
          <Text>{ `DELIVERY NOTE: ${data.ORDERNO}/1` }</Text>
          <Text>{ `${formatDate(new Date(data.CREATED_DATE))}` }</Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        > 
            <View
              style={[
                styles.infoRow,
                {
                  marginRight: 20,
                },
              ]}
            >
              <View style={styles.label}>
                <Text>{ `${t('Exporter')}:` }</Text>
              </View>
              <View>
                <Text style={[styles.value, styles.strong]}>{ `OÜ MULTIPAKEND TOOTMINE` }</Text>
                <Text style={styles.value}>{ `Reg. No 12843560` }</Text>
                <Text style={styles.value}>{ `Tuuliku tee 4 C` }</Text>
                <Text style={styles.value}>{ `10621 Tallinn` }</Text>
                <Text style={styles.value}>{ `Estonia` }</Text>
                <Text style={styles.value}>{ `` }</Text>
                <Text style={styles.value}>{ `Bank: Swedbank, code 767` }</Text>
                <Text style={styles.value}>{ `SWIFT code: HABA EE2X` }</Text>
                <Text style={styles.value}>{ `IBAN: EE622200221061907439` }</Text>
                <Text style={styles.value}>{ `VAT no: EE101789738` }</Text>
              </View>
            </View>

            <View
              style={styles.infoRow}
            >
              <View style={styles.label}>
                <Text>{ `${t('Consignee')}:` }</Text>
              </View>
              {
                data && data.client ?
                <View>
                  <Text style={[styles.value, styles.strong]}>{ data.client.NAME }</Text>
                  <Text style={styles.value}>{ data.client.ADDRESS }</Text>
                  <Text style={[styles.value]}>{ `VAT no: ${data.client.VAT}` }</Text>
                  <Text style={[styles.value]}>{ `Your order: ${getOrders()}` }</Text>
                </View>
                :
                <></>
              }
            </View>
        </View>

        <View>
          <View style={[styles.tableRow, { borderBottomWidth: 1, borderBottomColor: '#000', marginTop: 20 }]}>
            <View style={styles.col1}>
              <Text style={styles.tableHeadLabel}>{ t('Code') }</Text>
            </View>
            <View style={styles.col2}>
              <Text style={styles.tableHeadLabel}>{ t('FEFCO') }</Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.tableHeadLabel}>{ t('Corrugated boxes/sizes') }</Text>
            </View>
            <View style={styles.col4}>
              <Text style={styles.tableHeadLabel}>{ t('Quantity') }</Text>
            </View>
          </View>
          {
            data && data.rows.map((row) =>
            <View key={row.ID} style={[styles.tableRow, { borderBottomWidth: 0, borderBottomColor: '#000' }]}>
              <View style={styles.col1}>
                <Text style={styles.tableColText}>{ '48191000' }</Text>
              </View>
              <View style={styles.col2}>
                <Text style={styles.tableColText}>{ row.production.BOX }</Text>
              </View>
              <View style={styles.col3}>
                <Text style={styles.tableColText}>{ row.production.MOOT }</Text>
              </View>
              <View style={styles.col4}>
                <Text style={styles.tableColText}>{ row.production.RKOGUS }</Text>
              </View>
            </View>
            )
          }
          { data.PALLETS ?
            <View style={[styles.tableRow, { borderBottomWidth: 0, borderBottomColor: '#000' }]}>
              <View style={styles.col1}>
                <Text style={styles.tableColText}>{ '44152020' }</Text>
              </View>
              <View style={styles.col2}>
                <Text style={styles.tableColText}>{  }</Text>
              </View>
              <View style={styles.col3}>
                <Text style={styles.tableColText}>{ t('Pallets') }</Text>
              </View>
              <View style={styles.col4}>
                <Text style={styles.tableColText}>{ data.PALLETS }</Text>
              </View>
            </View>
            :
            <></>
          }
        </View>
        <View style={{ marginTop: 30 }}>
          <View style={styles.infoRow}>
            <Text style={{ width: 100, marginBottom: 8 }}>{ `${t('Netto weight:')} ` }</Text>
            <Text>{ `${data.NETTO} kg` }</Text>
          </View>
          <View style={styles.infoRow}>
            <Text style={{ width: 100, marginBottom: 8 }}>{ `${t('Gross weight:')} ` }</Text>
            <Text>{ `${data.GROSS} kg` }</Text>
          </View>
        </View>
        <View
          style={{
            marginVertical: 30,
          }}
        >
          <Text style={{ marginBottom: 8 }}>{ `${user ? user.name : ''}` }</Text>
          <Text style={{ marginBottom: 8 }}>{ `Multipakend Tootmine OÜ` }</Text>
        </View>
        <View style={{ marginTop: 20, alignItems: 'flex-end' }}>
          <Text>{ `${t(`Customer's signature`)}: ..............................................` }</Text>
        </View>
      </Page>
    </Document>
  )
}

export default InvoiceDeliveryDocument