import React, { useState, useRef } from 'react'
import './style.scss'
import { MaterialOrderRowsQuery } from '../../graphql/queries'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import FilterTable from '../../components/FilterTable'
import { formatDate, removeTags } from '../../util/format'
import writeXlsxFile from 'write-excel-file'

const includeFields = [
    {
        value: 'materialOrder.CREATED_DATE',
        label: 'Tellimus',
    },
    {
        value: 'DELIVERY_DATE',
        label: 'Saabumine',
    },
    {
        value: 'factoryOrder.COMPLETED',
        label: 'Kinnitus',
    },
    {
        value: 'materialOrder.factory.NAME',
        label: 'Tehas',
    },
    {
        value: 'materialOrder.ORDER_NUMBER',
        label: 'Tellimus',
    },
    {
        value: 'SIZE',
        label: 'Suurus',
    },
    {
        value: 'NUUT',
        label: 'Nuudid',
    },
    {
        value: 'MARK',
        label: 'Mark',
    },
    {
        value: 'AMOUNT',
        label: 'Tellitud',
    },
    {
        value: 'arrived',
        label: 'Saabunud',
    },
    {
        value: 'inTransit',
        label: 'Puudub',
    },
    {
        value: 'production.client.NAME',
        label: 'Klient',
    },
    {
        value: 'production.DEADLINE',
        label: 'Tellimuse tähtaeg',
    },
    {
        value: 'production.MOOT',
        label: 'Karbi mõõt',
    },
    {
        value: 'production.BOX',
        label: 'Karbi tüüp',
    },
]

const MaterialReportScreen = () => {

    const { t } = useTranslation()
    const tableRef = useRef()
    const [selectedRow, setSelectedRow] = useState()

    const fieldConditions = (row, field) => {
        switch (field) {
            case 'materialOrder.CREATED_DATE':
                return formatDate(new Date(row?.materialOrder?.CREATED_DATE || null))
            case 'DELIVERY_DATE':
                return formatDate(new Date(row[field]))
            case 'materialOrder.factory.NAME':
                return row?.materialOrder?.factory?.NAME
            case 'production.client.NAME':
                return row?.production?.client?.NAME
            case 'materialOrder.ORDER_NUMBER':
                return row?.materialOrder?.ORDER_NUMBER
            case 'production.DEADLINE':
                return formatDate(new Date(row?.production?.DEADLINE || null))
            case 'factoryOrder.COMPLETED':
                return formatDate(new Date(row?.factoryOrder?.COMPLETED || null))
            case 'production.MOOT':
                return row?.production?.MOOT
            case 'production.BOX':
                return row?.production?.BOX
        }
    }

    const handleRowClick = (item) => {
        setSelectedRow(item)
    }

    const handleRowDoubleClick = (item) => {
        if (!item?.materialOrder?.ID) return
        window.open(`/material-order/${item.materialOrder.ID}`)
    }

    const handleExcel = async () => {
        if (!tableRef.current) return
        const dataset = {
            columns: [],
            data: [],
        }
        Array.from(tableRef.current?.getTableRows()).map((row, index) => {
            const items = []
            Array.from(row.cells).map((cell, cellIndex) => {
                if (index === 0) {
                    items.push({
                        value: removeTags(cell.innerHTML),
                        fontWeight: 'bold',
                    })
                } else items.push({
                    value: removeTags(cell.innerHTML),
                })
            })

            if (index === 0) {
                dataset.columns = items
            }
            else dataset.data.push(items)
        })
        
        await writeXlsxFile([
            dataset.columns,
            ...dataset.data,
        ], {
            fileName: `material-report-${new Date().getTime()}.xlsx`,
            stickyRowsCount: 1
        })
        
    }

    return (
        <div className='material-report'>
            <div className='inner'>
                <div className='inner-title'>
                    <h2>{ t('Laekumiste aruanne') }</h2>
                    <div className='inner-title--actions'>
                        <Button
                            label={t('Export')}
                            onClick={handleExcel}
                        />
                    </div>
                </div>
                <div className='filter-table--container'>
                    <FilterTable
                        ref={tableRef}
                        query={MaterialOrderRowsQuery}
                        queryKey={'materialOrderRowsQuery'}
                        fieldConditions={fieldConditions}
                        includeFields={includeFields}
                        initialOrderBy={'materialOrder.CREATED_DATE'}
                        statusColorEnabled={true}
                        activeRows={selectedRow ? [selectedRow] : []}
                        onRowClick={handleRowClick}
                        onRowDoubleClick={handleRowDoubleClick}
                        ignoredKeys={['inTransit', 'arrived']}
                    />
                </div>
            </div>
        </div>
    )
}

export default MaterialReportScreen