import { useMutation } from '@apollo/client'
import { pdf, PDFViewer } from '@react-pdf/renderer'
import { saveAs } from 'file-saver'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import DeliveryNoteDocument from '../documents/deliverynote-document'
import DeliveryNoteInvoiceDocument from '../documents/deliverynote-invoice-document'
import MaterialOrderEngDocument from '../documents/material-order-en'
import MaterialOrderInternalDocument from '../documents/material-order-internal'
import MaterialOrderRusDocument from '../documents/material-order-ru'
import OrderDocument from '../documents/order-document'
import OrderAltDocument from '../documents/order-document-alt'
import PrintoutDocument from '../documents/printout-document'
import QuotationDocument from '../documents/quotation-document'
import QuotationDocumentEng from '../documents/quotation-document-en'
import QuotationDocumentFin from '../documents/quotation-document-fi'
import QuotationDocumentMark from '../documents/quotation-document-mark'
import QuotationDocumentMarkFin from '../documents/quotation-document-mark-fi'
import { formatDate } from '../util/format'
import Button from './Button'
import ActivityIndicator from './ActivityIndicator'

const PdfViewer = ({
    document,
    data,
    saveBtn,
    showLoading,
    onSendEmail,
}) => {

    const { t } = useTranslation()

    const getDocument = () => {
        switch (document) {
            case 'order':
                return <OrderDocument data={data} />
            case 'order-alt':
                return <OrderAltDocument data={data} />
            case 'material-eng':
                return <MaterialOrderEngDocument data={data} />
            case 'material-rus':
                return <MaterialOrderRusDocument data={data} />
            case 'material-internal':
                return <MaterialOrderInternalDocument data={data} />
            case 'printout':
                return <PrintoutDocument data={data?.production} type={data?.type} printout={data?.printout} />
            case 'quotation':
                return <QuotationDocument data={data?.order} rows={data?.rows} />
            case 'quotation-fin':
                return <QuotationDocumentFin data={data?.order} rows={data?.rows} />
            case 'quotation-eng':
                return <QuotationDocumentEng data={data?.order} rows={data?.rows} />
            case 'quotation-mark':
                return <QuotationDocumentMark data={data?.order} rows={data?.rows} />
            case 'quotation-mark-fin':
                return <QuotationDocumentMarkFin data={data?.order} rows={data?.rows} />
            case 'delivery-note':
                return <DeliveryNoteDocument data={data} />
            case 'delivery-note-invoice':
                return <DeliveryNoteInvoiceDocument data={data} />
        }
    }

    const handleSave = async () => {
        let fileName = 'Tellimus.pdf'
        switch (document) {
            case 'material-eng':
                fileName = `${data?.ORDER_NUMBER}-${formatDate(new Date(data?.CREATED_DATE))}-en.pdf`
            case 'material-rus':
                fileName = `${data?.ORDER_NUMBER}-${formatDate(new Date(data?.CREATED_DATE))}-ru.pdf`
            case 'material-internal':
                fileName = `${data?.ORDER_NUMBER}-${formatDate(new Date(data?.CREATED_DATE))}-sisemine.pdf`
        }
        const blob = await pdf(getDocument()).toBlob()
        saveAs(blob, fileName)
    }

    return (
        <div className='pdf-viewer'>
            {
                showLoading &&
                <div className='loading-view'>
                    <ActivityIndicator />
                </div>
            }
            <div className='inner'>
                <div className='pdf-viewer--actions'>
                    {
                        ['order', 'order-alt'].includes(document) ?
                        <Button
                            label={t('Saada email')}
                            onClick={onSendEmail}
                        />
                        :
                        <></>
                    }
                    {
                        saveBtn &&
                        <Button
                            label={t('Salvesta')}
                            onClick={handleSave}
                        />
                    }
                </div>
                <PDFViewer>
                    { getDocument() }
                </PDFViewer>
            </div>
        </div>
    )
}

export default PdfViewer