import React from 'react'
import ReactDOM from 'react-dom'
import 'react-datepicker/dist/react-datepicker.css'
import './styles/colors.scss'
import './styles/classes.scss'
import './styles/global.scss'
import './styles/elements.scss'
import './styles/header.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ApolloClient, InMemoryCache, from, ApolloProvider, split } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { onError } from '@apollo/client/link/error'
import { setContext } from 'apollo-link-context'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import I18NextHttpBackend from 'i18next-http-backend'
import 'react-activity/dist/Bounce.css'
import ErrorBoundary from './components/ErrorBoundary'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { getMainDefinition } from '@apollo/client/utilities'

i18n
  .use(I18NextHttpBackend)
  .use(initReactI18next)
  .init({
    lng: 'et',
    fallbackLng: 'et',

    interpolation: {
      escapeValue: false
    }
})

const wsLink = new GraphQLWsLink(createClient({
  url: process.env.NODE_ENV !== 'development' ? process.env.REACT_APP_WS : 'ws://localhost:4000/subscriptions',
  connectionParams: {
    authToken: () => {
      const token = localStorage.getItem('token')
      return token
    },
  },
}))

const errorLink = onError(({ graphQLErrors, networkError, response, operation }) => {
  console.log(graphQLErrors)
  if (graphQLErrors?.length > 0 && graphQLErrors[0].message === 'Invalid credentials') throw new Error('Invalid credentials') 
})

const httpLink = createUploadLink({
  uri: process.env.NODE_ENV !== 'development' ? process.env.REACT_APP_GRAPHQL : 'http://localhost:3050/graphql',
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token')
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink,
)

const client = new ApolloClient({
  link: from([
    authLink,
    errorLink,
    splitLink,
  ]),
  connectToDevTools: process.env.NODE_ENV === 'development',
  cache: new InMemoryCache(),
})

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
