import React, { useRef, useState } from 'react'
import './style.scss'
import { useMutation, useQuery } from '@apollo/client'
import { GetAllClients, GetClient, GetUsers, SearchClientsAll } from '../../graphql/queries'
import { useTranslation } from 'react-i18next'
import ActivityIndicator from '../../components/ActivityIndicator'
import { CreateClient, RemoveClient, UpdateClient } from '../../graphql/mutations'
import Pagination from '../../components/Pagination'
import TableInput from '../../components/TableInput'
import IconButton from '../../components/IconButton'
import Button from '../../components/Button'
import MinusIcon from '../../components/icons/minus'
import PlusIcon from '../../components/icons/plus'
import Modal from '../../components/Modal'
import Input from '../../components/Input'
import Textarea from '../../components/Textarea'
import Checkbox from '../../components/Checkbox'
import SelectInput from '../../components/SelectInput'
import Search from '../../components/Search'
import { useNavigate, useParams } from 'react-router-dom'
import TransportAddresses from '../../components/TransportAddresses'
import ClientContacts from '../../components/ClientContacts'

const ClientScreen = () => {

    const { t } = useTranslation()
    const { id } = useParams()
    const navigate = useNavigate()
    const [client, setClient] = useState()
    const [users, setUsers] = useState([])

    const { loading, refetch } = useQuery(GetClient, {
        variables: {
            id: parseInt(id),
        },
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            const { getClient } = data
            if (getClient) {
                setClient(getClient)
            }
        }
    })

    useQuery(GetUsers, {
        onCompleted: (data) => {
            const { getUsers } = data
            if (getUsers && getUsers.length > 0) setUsers(getUsers)
        },
    })
    
    const [updateItem] = useMutation(UpdateClient)
    const [removeItem] = useMutation(RemoveClient)

    const deleteItem = async () => {
        if (!client) return
        try {
            const { data } = await removeItem({
                variables: {
                    id: parseInt(client.ID)
                },
            })
            if (data.removeClient) {
                navigate(-1)
            }
        } catch (err) {
            console.log('Error deleting client', err)
        }
    }

    const setField = (field, value) => {
        setClient({
            ...client,
            [field]: value,
        })

        updateProperty(field, value)
    }

    const updateProperty = async (field, value) => {
        try {
            await updateItem({
                variables: {
                    id: parseInt(id),
                    data: {
                        [field]: value,
                    },
                },
            })
        } catch (err) {
            console.log('updateProperty:', err)
        }
    }

    if (loading) return (
        <div className='loading-view'>
            <ActivityIndicator />
        </div>
    )

    if (!client) return (
        <div className='empty-result'>
            <span>{ t('Klienti ei leitud') }</span>
        </div>
    )

    return (
        <div className='client'>
            <div className='inner'>
                <div className='inner-title'>
                    <h2>{ t('Klient') }</h2>
                    <div className='inner-title--actions'>
                        <IconButton
                            label={'Kustuta klient'}
                            icon={<MinusIcon />}
                            onClick={deleteItem}
                        />
                    </div>
                </div>
                <div className='client-data'>
                    <div className='field-row'>
                        <div className='field-col'>
                            <Input
                                label={t('Nimi')}
                                value={client.NAME}
                                onChange={(e) => setField('NAME', e.target.value)}
                            />
                            <Textarea
                                label={t('Aadress')}
                                value={client.ADDRESS}
                                onChange={(e) => setField('ADDRESS', e.target.value)}
                            />
                            <Input
                                label={t('Transpordi firma')}
                                value={client.TRANSFIRMA}
                                onChange={(e) => setField('TRANSFIRMA', e.target.value)}
                            />
                            <div className='field-row'>
                                <Input
                                    label={t('Reg. number')}
                                    value={client.REGNO}
                                    onChange={(e) => setField('REGNO', e.target.value)}
                                />
                                <Input
                                    label={t('VAT number')}
                                    value={client.VAT}
                                    onChange={(e) => setField('VAT', e.target.value)}
                                />
                            </div>
                            <Input
                                label={t('Email')}
                                value={client.EMAIL}
                                onChange={(e) => setField('EMAIL', e.target.value)}
                            />
                            <TransportAddresses
                                clientId={id}
                            />
                        </div>
                        <div className='field-col client-checkboxes'>
                            <div className='field-row align-items--center'>
                                <Checkbox
                                    label={t('Ettemaks vajalik')}
                                    value={client.ETTEMAKS ? true : false}
                                    onChange={(e) => setField('ETTEMAKS', e.target.checked ? 1 : 0)}
                                />
                                <Checkbox
                                    label={t('Aktiivne')}
                                    value={client.ACTIVE ? true : false}
                                    onChange={(e) => setField('ACTIVE', e.target.checked ? 1 : 0)}
                                />
                                <Input
                                    label={t('Ettemaksu %')}
                                    value={client.PREPAID}
                                    type={'number'}
                                    onChange={(e) => setField('PREPAID', parseInt(e.target.value))}
                                />

                            </div>
                            <div className='field-row align-items--center'>
                                <Checkbox
                                    label={t('Ühine firma')}
                                    value={client.COMMON ? true : false}
                                    onChange={(e) => setField('COMMON', e.target.checked ? 1 : 0)}
                                />
                                <Input
                                    label={t('Maksetähtaeg')}
                                    value={client.MAKSEAEG}
                                    type={'number'}
                                    onChange={(e) => setField('MAKSEAEG', e.target.value)}
                                />
                            </div>
                            <div className='field-row align-items--center'>
                                <Checkbox
                                    label={t('Võlglane')}
                                    value={client.DEPT ? true : false}
                                    onChange={(e) => setField('DEPT', e.target.checked ? 1 : 0)}
                                />
                                <Input
                                    label={t('Riik')}
                                    value={client.COUNTRY}
                                    onChange={(e) => setField('COUNTRY', e.target.value)}
                                />
                            </div>
                            {
                                users.length > 0 &&
                                <SelectInput
                                    label={`${t('Haldaja')}:`}
                                    options={users}
                                    onChange={(option) => setField('MANAGER_ID', parseInt(option.ID))}
                                    value={users.find(x => x.ID === client.MANAGER_ID)}
                                    getOptionLabel={(option) => option.RNAME}
                                    getOptionValue={(option) => option.ID}
                                />
                            }
                            <ClientContacts
                                clientId={id}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientScreen