import React, { useEffect, useState } from 'react'
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer'
import { formatDate } from '../util/format'

import UbuntuRegular from '../assets/fonts/Ubuntu/Ubuntu-Regular.ttf'
import UbuntuBold from '../assets/fonts/Ubuntu/Ubuntu-Bold.ttf'
import { useTranslation } from 'react-i18next'

Font.register(
  {
    family: 'Roboto',
    fonts: [
      {
        src: UbuntuRegular,
        fontWeight: 400,
      },
      {
        src: UbuntuBold,
        fontWeight: 700,
      },
    ],
  },
)

const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontSize: 12,
    justifyContent: 'flex-start',
  },
  title: {
    fontSize: 16,
    fontWeight: 700,
    fontFamily: 'Roboto',
    marginRight: 60,
  },
  titleSection: {
    flexDirection: 'row',
    marginBottom: 20,
  },
  strong: {
    fontFamily: 'Roboto',
    fontWeight: 700,
  },
  contactLine: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 2,
  },
  label: {
    width: 160,
  },
  value: {
    flex: 1,
  },
  tableRow: {
    flexDirection: 'row',
    paddingVertical: 4,
  },
  tableHeadLabel: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 10,
    textAlign: 'center',
  },
  tableExtraLabel: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 10,
    textAlign: 'center',
    marginRight: 70,
    marginBottom: 10,
    marginTop: 10,
  },
  tableColText: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 10,
    textAlign: 'center',
  },
  col1: {
    width: '5%',
    paddingRight: 3,
  },
  col2: {
    width: '8%',
    paddingRight: 3,
  },
  col3: {
    width: '20%',
    paddingRight: 3,
  },
  col4: {
    width: '10%',
    paddingRight: 3,
  },
  col5: {
    width: '12.5%',
    paddingRight: 3,
  },
  col6: {
    width: '7.5%',
    paddingRight: 3,
  },
  col7: {
    width: '10%',
    paddingRight: 3,
  },
  col8: {
    width: '5%',
    paddingRight: 3,
  },
  col9: {
    width: '6%',
    paddingRight: 3,
  },
  col10: {
    width: '6%',
    paddingRight: 3,
  },
  col11: {
    width: '10%',
  },
})

const OrderAltDocument = ({
  data,
}) => {

  const { t } = useTranslation()
  const [client, setClient] = useState()
  const [order, setOrder] = useState()

  useEffect(() => {
    if (data && data.length > 0) {
      setClient(data[0].client)
      setOrder(data[0])
    }
  }, [])

  if (!client || !order) return (
    <Document></Document>
  )

  return (
    <Document>
      <Page size="A4" style={styles.page} orientation={'landscape'}>
        <View style={styles.titleSection}>
          <Text style={styles.title}>{ `TELLIMUS Nr ${order && order.TELLIMUSNO}` }</Text>
          <Text>{ formatDate(new Date(order.ORDER_DATE)) }</Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <View
            style={{
              flex: 1,
              justifyContent: 'space-between',
              marginRight: 40,
            }}
          >
            <View>
              <View style={styles.contactLine}>
                <Text style={[styles.strong, styles.label]}>{ `${t('Kliendi tellimuse nr.')}: ` }</Text>
                <Text style={styles.value}>{ order.KLIENT_ORDER }</Text>
              </View>
              <View style={styles.contactLine}>
                <Text style={[styles.strong, styles.label]}>{ `${t('Kliendi nimi')}: ` }</Text>
                <Text style={styles.value}>{ client.NAME }</Text>
              </View>
              <View style={styles.contactLine}>
                <Text style={[styles.strong, styles.label]}>{ `${t('Tellija andmed')}: ` }</Text>
                <View
                  style={{
                    flex: 1,
                  }}
                >
                  {
                    data?.length > 0 && data[0].contact &&
                    <>
                      <Text>{ data[0].contact.PHONE }</Text>
                      <Text>{ data[0].contact.EMAIL }</Text>
                    </>
                  }
                  <Text>{ client.ADDRESS }</Text>
                </View>
              </View>
            </View>
            <View style={[styles.contactLine, { marginTop: 30 }]}>
              <Text style={[styles.strong, styles.label]}>{ `${t('Reg. no. / VAT')}` }</Text>
              <Text style={styles.value}>{ `${client.REGNO ? client.REGNO : ''} ${client?.VAT ? '/ ' + client.VAT : ''}` }</Text>
            </View>
          </View>
          <View
            style={{
              flex: 1,
              justifyContent: 'space-between',
            }}
          >
            <View>
              <View style={styles.contactLine}>
                <Text style={[styles.strong, styles.label]}>{ `${t('Transport')}: ` }</Text>
                <Text style={styles.value}>{ order.TRANSPORDIFIRMA }</Text>
              </View>
              <View style={[styles.contactLine, { marginBottom: 30 }]}>
                <Text style={[styles.strong, styles.label]}>{ `${t('Tarne aadress')}: ` }</Text>
                <Text style={styles.value}>{ order.ADDRESS }</Text>
              </View>
            </View>
            <View>
              <View style={styles.contactLine}>
                <Text style={[styles.strong, styles.label]}>{ `${t('Maksetähtaeg')}: ` }</Text>
                <Text style={styles.value}>{ client.ETTEMAKS ? t('Ettemaks') : client.MAKSEAEG }</Text>
              </View>
              <View style={styles.contactLine}>
                <Text style={[styles.strong, styles.label]}>{ `${t('Sisestaja')}: ` }</Text>
                <Text style={styles.value}>{ order.creator && order.creator.RNAME ? order.creator.RNAME : '' }</Text>
              </View>
              <View style={styles.contactLine}>
                <Text style={[styles.strong, styles.label]}>{ `${t('Müügihaldur')}: ` }</Text>
                <Text style={styles.value}>{ client.manager && client.manager.RNAME ? client.manager.RNAME : '' }</Text>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            marginTop: 20,
            borderBottomWidth: 1,
            borderBottomColor: '#000',
          }}
        >
          <View style={[styles.tableRow, { borderBottomWidth: 1, borderBottomColor: '#000' }]}>
            <View style={styles.col1}>
              <Text style={styles.tableHeadLabel}>{ t('Jrk.') }</Text>
            </View>
            <View style={styles.col2}>
              <Text style={styles.tableHeadLabel}>{ t('Rida') }</Text>
            </View>
            <View style={styles.col3}>
              <Text style={{...styles.tableHeadLabel, textAlign: 'left'}}>{ t('Nimetus') }</Text>
            </View>
            <View style={styles.col4}>
              <Text style={styles.tableHeadLabel}>{ t('Toote kood') }</Text>
            </View>
            <View style={styles.col5}>
              <Text style={styles.tableHeadLabel}>{ t('Kasti mõõt') }</Text>
            </View>
            <View style={styles.col6}>
              <Text style={styles.tableHeadLabel}>{ t('FEFCO') }</Text>
            </View>
            <View style={styles.col7}>
              <Text style={styles.tableHeadLabel}>{ t('LP mark') }</Text>
            </View>
            <View style={styles.col8}>
              <Text style={styles.tableHeadLabel}>{ t('Trk.') }</Text>
            </View>
            <View style={styles.col9}>
              <Text style={styles.tableHeadLabel}>{ t('Kogus') }</Text>
            </View>
            <View style={styles.col10}>
              <Text style={styles.tableHeadLabel}>{ t('Hind') }</Text>
            </View>
            <View style={styles.col11}>
              <Text style={styles.tableHeadLabel}>{ t('Tarne kpv.') }</Text>
            </View>
          </View>
          {
            data.sort((a, b) => a.ORDERNO - b.ORDERNO).map((row, index) =>
            <View key={row.ID} style={{ borderBottomWidth: 1, borderBottomColor: '#000' }}>
              <View style={[styles.tableRow]}>
                <View style={styles.col1}>
                  <Text style={styles.tableColText}>{ index + 1 }</Text>
                </View>
                <View style={styles.col2}>
                  <Text style={styles.tableColText}>{ row.ORDERNO }</Text>
                </View>
                <View style={styles.col3}>
                  <Text style={{...styles.tableColText, textAlign: 'left'}}>{ row.KLIENT_BOXCODE }</Text>
                </View>
                <View style={styles.col4}>
                  <Text style={styles.tableColText}>{ row.PRODUCTION_CODE }</Text>
                </View>
                <View style={styles.col5}>
                  <Text style={styles.tableColText}>{ row.MOOT }</Text>
                </View>
                <View style={styles.col6}>
                  <Text style={styles.tableColText}>{ row.BOX }</Text>
                </View>
                <View style={styles.col7}>
                  <Text style={styles.tableColText}>{ row.MATERIAL }</Text>
                </View>
                <View style={styles.col8}>
                  <Text style={styles.tableColText}>{ row.TRYKK }</Text>
                </View>
                <View style={styles.col9}>
                  <Text style={styles.tableColText}>{ row.KOGUS }</Text>
                </View>
                <View style={styles.col10}>
                  <Text style={styles.tableColText}>{ row.PRICE }</Text>
                </View>
                <View style={styles.col11}>
                  <Text style={styles.tableColText}>{ formatDate(new Date(row.DEADLINE)) }</Text>
                </View>
              </View>
              <View style={{ flexDirection: 'row' }}>
                  {
                    row.STANTS &&
                    <Text style={styles.tableExtraLabel}>{ `Stants: ${row.STANTS}` }</Text>
                  }
                  {
                    row.KLISHEE &&
                    <Text style={styles.tableExtraLabel}>{ `Klishee: ${row.KLISHEE}` }</Text>
                  }
                  {
                    row.TRANSPORT &&
                    <Text style={styles.tableExtraLabel}>{ `Transport: ${row.TRANSPORT}` }</Text>
                  }
                </View>
            </View>
            )
          }
        </View>
        <View
          style={{
            flexDirection: 'row',
            marginTop: 20,
          }}
        >
          <View>
            <Text style={[styles.strong]}>{ `${t('Kommentaar')}:` }</Text>
          </View>
          <View style={{ marginLeft: 20 }}>
            {
              data.sort((a, b) => a.ORDERNO - b.ORDERNO).map((row, index) => {
                if (row.KOMMENTAAR) return (
                  <View key={`comment-${row.ID}`}>
                    <Text style={styles.tableColText}>{ `${index + 1}. ${row.KOMMENTAAR}` }</Text>
                  </View>
                )
              }
              )
            }
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default OrderAltDocument