import React, { useRef, useState } from 'react'
import './style.scss'
import { useMutation, useQuery } from '@apollo/client'
import { GetInvoiceRows, GetInvoices} from '../../graphql/queries'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ActivityIndicator from '../../components/ActivityIndicator'
import { CreateInvoice } from '../../graphql/mutations'
import Button from '../../components/Button'
import Pagination from '../../components/Pagination'
import Checkbox from '../../components/Checkbox'
import { useAuth } from '../../providers/Auth'
import { formatDate } from '../../util/format'
import { BlobProvider } from '@react-pdf/renderer'
import InvoiceDocument from '../../documents/invoice-document'
import InvoiceDeliveryDocument from '../../documents/invoice-delivery-document'

const InvoicesScreen = () => {

    const { t } = useTranslation()
    const { constants, user } = useAuth()
    const page = useRef(0)
    const limit = useRef(100)
    const navigate = useNavigate()
    const [selectedRow, setSelectedRow] = useState(null)

    const { data, loading, refetch } = useQuery(GetInvoices, {
        variables: {
          page: page.current,
          limit: limit.current,
        },
        fetchPolicy: 'no-cache',
    })
    
    const [createInvoice] = useMutation(CreateInvoice)
    const { refetch: getRows } = useQuery(GetInvoiceRows, { skip: true })

    const getPreviousPage = () => {
        page.current = page.current - 1
        refetch({
            page: page.current,
            limit: limit.current,
        })
    }
    
    const getNextPage = () => {
        page.current = page.current + 1
        refetch({
            page: page.current,
            limit: limit.current,
        })
    }

    const open = (id) => {
        navigate(`/invoice/${id}`)
    }

    const handleRowSelect = async (row, checked) => {
        if (checked) {
            const selected = row
            selected.PALLETPRICE = constants.PALLETPRICE

            try {
                const { data } = await getRows({
                    invoiceId: parseInt(row.ID),
                })
                if (data && data.getInvoiceRows) {
                    selected.rows = data.getInvoiceRows
                }
                setSelectedRow(selected)
            } catch (err) {
                console.log('Error selecting row:', err)
                setSelectedRow(row)
            }

            return
        }
        setSelectedRow(null)
    }

    const newInvoice = async () => {
        try {
            const { data } = await createInvoice()

            if (data && data.createInvoice) {
                open(data.createInvoice)
            }
        } catch (err) {
            console.log('Error creating invoice', err)
        }
    }

    const openPdf = (url) => {
        window.open(url, '_blank')
    }

    if (loading) return (
        <div className='loading-view'>
            <ActivityIndicator />
        </div>
    )

    return (
        <div className='invoices'>
            <div className='inner'>
                <div className='inner-title'>
                    <h2>{ t('Arved') }</h2>
                    <div className='inner-title--actions'>
                        <Button
                            label={t('Uus arve')}
                            onClick={newInvoice}
                        />
                        {
                            selectedRow ?
                            <BlobProvider document={<InvoiceDocument data={selectedRow} user={user} />}>
                                {
                                    ({url}) => {
                                        return (
                                            <Button
                                                label={t('Prindi')}
                                                onClick={() => openPdf(url)}
                                            />
                                        )
                                    }
                                }
                            </BlobProvider>
                            :
                            <Button
                                disabled
                                label={t('Prindi')}
                            />
                        }
                        {
                            selectedRow ?
                            <BlobProvider document={<InvoiceDeliveryDocument data={selectedRow} user={user} />}>
                                {
                                    ({url}) => {
                                        return (
                                            <Button
                                                label={t('Saatelehe printimine')}
                                                onClick={() => openPdf(url)}
                                            />
                                        )
                                    }
                                }
                            </BlobProvider>
                            :
                            <Button
                                disabled
                                label={t('Saatelehe printimine')}
                            />
                        }
                    </div>
                </div>
                { data && data.getInvoices && data.getInvoices.rows && data.getInvoices.rows.length > 0 ?
                    <div className='grid-list invoices-list'>
                        <div className='grid-list-item invoices-item grid-list-item--header'>
                            <div className='grid-list-item--col'></div>
                            <div className='grid-list-item--col'>{ t('Arve nr') }</div>
                            <div className='grid-list-item--col'>{ t('Kuupäev') }</div>
                            <div className='grid-list-item--col'>{ t('Maksetähtaeg') }</div>
                            <div className='grid-list-item--col'>{ t('Klient') }</div>
                            <div className='grid-list-item--col'>{ t('Aluseid') }</div>
                            <div className='grid-list-item--col'>{ t('Kohti') }</div>
                            <div className='grid-list-item--col'>{ t('Netokaal') }</div>
                            <div className='grid-list-item--col'>{ t('Brutokaal') }</div>
                            <div className='grid-list-item--col'>{ t('Koostaja') }</div>
                            <div className='grid-list-item--col'>{ t('VAT') }</div>
                            <div className='grid-list-item--col'>{ t('Goods') }</div>
                            <div className='grid-list-item--col'>{ t('Ettemaks') }</div>
                        </div>
                        {
                            data.getInvoices.rows.map((item) =>
                                <div
                                    key={item.ID}
                                    className={`grid-list-item invoices-item${item.ORDERED === 1 ? ' ordered': ''}`}
                                    onClick={(e) => {
                                        if (
                                            e.target.classList.contains('input-wrapper--checkbox-box')
                                            || e.target.type === 'checkbox'
                                        ) {
                                            return
                                        }
                                        open(item.ID)
                                    }}
                                >
                                    <div className='grid-list-item--col grid-list-item--col-checkbox'>
                                        <Checkbox
                                            value={selectedRow && selectedRow.ID === item.ID ? true : false}
                                            onChange={(e) => handleRowSelect(item, e.target.checked)}
                                        />
                                    </div>
                                    <div className='grid-list-item--col invoices-item--number'>{ item.ORDERNO }</div>
                                    <div className='grid-list-item--col invoices-item--date'>{ formatDate(new Date(item.CREATED_DATE)) }</div>
                                    <div className='grid-list-item--col invoices-item--deadline'>{ formatDate(new Date(item.PAYMENT_DATE)) }</div>
                                    <div className='grid-list-item--col invoices-item--creator'>{ item.client && item.client.NAME }</div>
                                    <div className='grid-list-item--col invoices-item--pallets'>{ item.PALLETS }</div>
                                    <div className='grid-list-item--col invoices-item--places'>{ item.PLACES }</div>
                                    <div className='grid-list-item--col invoices-item--net'>{ item.NETTO }</div>
                                    <div className='grid-list-item--col invoices-item--gross'>{ item.GROSS }</div>
                                    <div className='grid-list-item--col invoices-item--author'>{ item.author && item.author.RNAME }</div>
                                    <div className='grid-list-item--col invoices-item--vat'>{ item.client && item.client.VAT }</div>
                                    <div className='grid-list-item--col invoices-item--goods'>{ item.GOODS }</div>
                                    <div className='grid-list-item--col invoices-item--prepaid'>{ item.PREPAID_AMOUNT }</div>
                                </div>
                            )
                        }
                    </div>
                    :
                    <div className='empty-result'>
                        <p>{ t('Arveid ei leitud') }</p>
                    </div>
                }
                <Pagination total={data.getInvoices.count} onPrev={getPreviousPage} onNext={getNextPage} current={page.current} limit={limit.current} />
            </div>
        </div>
    )
}

export default InvoicesScreen