import { BrowserRouter } from 'react-router-dom'
import { ProvideAuth } from './providers/Auth'
import Routing from './Routing'
import { Suspense } from 'react'
import { NotificationProvider } from './providers/Notification'
import ActivityIndicator from './components/ActivityIndicator'

const renderFallback = () => (
  <div className='loading-view'>
    <ActivityIndicator />
  </div>
)

const App = () => {
  return (
    <Suspense fallback={renderFallback()}>
      <NotificationProvider>
        <BrowserRouter>
          <ProvideAuth>
            <Routing />
          </ProvideAuth>
        </BrowserRouter>
      </NotificationProvider>
    </Suspense>
  )
}

export default App
