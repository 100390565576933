import React from 'react'
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer'
import { formatDate } from '../util/format'

import UbuntuRegular from '../assets/fonts/Ubuntu/Ubuntu-Regular.ttf'
import UbuntuBold from '../assets/fonts/Ubuntu/Ubuntu-Bold.ttf'
import { useTranslation } from 'react-i18next'

Font.register(
  {
    family: 'Roboto',
    fonts: [
      {
        src: UbuntuRegular,
        fontWeight: 400,
      },
      {
        src: UbuntuBold,
        fontWeight: 700,
      },
    ],
  },
)

const styles = StyleSheet.create({
  page: {
    padding: 40,
    flexDirection: 'column-reverse',
  },
  wrapper: {
    borderWidth: 1,
    borderColor: '#000',
  },
  title: {
    fontSize: 16,
    fontWeight: 700,
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'stretch',
    borderColor: '#000',
  },
  col: {
    flex: 1,
    borderColor: '#000',
  },
  colLast: {
    flex: 1,
    borderRightWidth: 0,
  },
  label: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    textTransform: 'uppercase',
    fontSize: 14,
  },
  value: {
    fontFamily: 'Roboto',
    fontWeight: 700,
  },
  info: {
    fontSize: 10,
  },
  image: {
    width: 70,
    height: 70,
  },
})

const PrintoutDocument = ({
  data,
  printout,
  type,
}) => {

  const { t } = useTranslation()

  console.log(data)

  const renderPages = () => {
    const pages = []
    for (let i = 0; i < printout.count; i++) {
      pages.push(
        <Page key={`page-${i}`} size="A4" style={styles.page} orientation={'portrait'}>
        <View
          style={styles.wrapper}
        >

          <View style={[
            styles.row,
            {
              height: 100,
              borderBottomWidth: 1,
              paddingHorizontal: 6,
              paddingVertical: 3,
            },
          ]}>
            <View>
              <View>
                <Text style={[
                  styles.label,
                  {
                    fontSize: 20,
                  },
                ]}>
                  { `${t('Klient')}:` }
                </Text>
              </View>
              <View>
                <Text
                  style={{
                    fontSize: 26,
                    fontFamily: 'Roboto',
                    fontWeight: 700,
                  }}
                >
                  { `${data?.client?.NAME}` }
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.row}>
            <View style={[
              styles.col,
              {
                borderBottomWidth: 0,
                borderLeftWidth: 0,
                borderRightWidth: 0,
                paddingVertical: 3,
                paddingHorizontal: 6,
                flexDirection: 'row',
              },
            ]}>
              <View>
                <Text style={styles.label}>{ `${t('Tarne aadress')}:` }</Text>
              </View>
              <View>
                <Text style={{
                  ...styles.value,
                  fontSize: 12,
                  marginLeft: 10,
                }}>{ `${data?.ADDRESS ? data.ADDRESS : ''}` }</Text>
              </View>
            </View>
          </View>

          <View style={[
            styles.row,
            {
              justifyContent: 'space-between',
              borderBottomWidth: 1,
              borderTopWidth: 1,
              paddingHorizontal: 6,
              paddingVertical: 3,
            },
          ]}>
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <Text style={styles.label}>{ `${t('Kliendi tellimus')}: ` }</Text>
              <Text style={styles.value}>{ `${data?.KLIENT_ORDER}` }</Text>
            </View>
            <View>
              <Text style={styles.value}>{ `${formatDate(new Date(data?.ORDER_DATE))}` }</Text>
            </View>
          </View>

          <View style={[
            styles.row,
            {
              minHeight: 90,
              borderBottomWidth: 1,
              paddingVertical: 3,
              paddingHorizontal: 6,
            },
          ]}>
            <View style={[
                styles.col,
                {
                  
                },
              ]}>
              <Text style={styles.label}>{ `${t('Toote nimetus / Mõõdud')}:` }</Text>
              <View style={[styles.row, { justifyContent: 'space-between' }]}>
                <Text style={[styles.value, { fontSize: 24 }]}>{ `${data?.MOOT}` }</Text>
                <Text style={[styles.value, { fontSize: 24 }]}>{ `${data?.KLIENT_BOXCODE ? data.KLIENT_BOXCODE : ''}` }</Text>
              </View>
            </View>
          </View>

          <View style={[styles.row, { borderBottomWidth: 1 }]}>
            <View style={[styles.row, { paddingHorizontal: 6, paddingVertical: 3, borderRightWidth: 1, flex: 1 }]}>
              <Text style={styles.label}>{ `${t('Materjal')}:` }</Text>
              <Text style={styles.value}>{ `${data?.TYPE ? data.TYPE : ''}` }</Text>
            </View>
            <View style={[{ paddingHorizontal: 6, paddingVertical: 3, width: '33.2%' }]}>
              <Text style={styles.label}>{ `${t('Tellitud kogus')}:` }</Text>
              <Text style={styles.value}>{ `${data?.KOGUS}` }</Text>
            </View>
          </View>

          <View style={[styles.row, { borderBottomWidth: 1 }]}>
            <View style={[
                styles.col,
                {
                  borderRightWidth: 1,
                  width: '33.333%',
                  paddingHorizontal: 6,
                  paddingVertical: 3,
                },
            ]}>
              <Text style={styles.label}>{ `${t('Aluse nr')}` }</Text>
              <View style={{ flexDirection: 'row' }}>
                <View style={{ width: 50 }} />
                <Text style={styles.value}>{ `${i + 1} / ${printout?.count ? printout.count : ''}` }</Text>
              </View>
            </View>
            <View style={[
                styles.col,
                {
                  borderRightWidth: 1,
                  width: '33.333%',
                  paddingHorizontal: 6,
                  paddingVertical: 3,
                },
            ]}>
              <Text style={styles.label}>{ `${t('Aluse kõrgus')}` }</Text>
              <Text style={styles.value}>{ `${printout?.height ? printout.height : ''}` }</Text>
            </View>
            <View style={[
                styles.col,
                {
                  width: '33.333%',
                  paddingHorizontal: 6,
                  paddingVertical: 3,
                },
            ]}>
              <Text style={styles.label}>{ `${t('Tarneaeg')}` }</Text>
              <Text style={styles.value}>{ `${formatDate(new Date(data?.DEADLINE))}` }</Text>
            </View>
          </View>

          <View style={[styles.row, { height: 70 }]}>
            <View style={[
                styles.col,
                {
                  borderRightWidth: 1,
                  paddingHorizontal: 6,
                  paddingVertical: 3,
                  width: '33.333%',
                },
            ]}>
              <Text style={styles.label}>{ `${t('Tk/Alusel')}` }</Text>
              <Text style={styles.value}>{ printout?.items }</Text>
            </View>
            <View style={[
                styles.col,
                {
                  borderRightWidth: 1,
                  paddingHorizontal: 6,
                  paddingVertical: 3,
                  width: '33.333%',
                },
            ]}>
              <Text style={styles.label}>{ `${t('Aluse mõõt')}` }</Text>
              <Text style={styles.value}>{ `${printout?.bottom ? printout.bottom : ''}` }</Text>
            </View>
            <View style={[
                styles.col,
                {
                  paddingHorizontal: 6,
                  paddingVertical: 3,
                  width: '33.333%',
                },
            ]}>
              <Text style={styles.label}>{ `${t('Pakkija')}` }</Text>
              <Text style={styles.value}>{ `` }</Text>
            </View>
          </View>
          <View
            style={[
              styles.row,
              {
                height: 200,
                borderTopWidth: 1,
              },
            ]}
          >
            {
              type === 'Multipakend' || type === 'Pitsa' ?
              <View
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexDirection: 'row',
                  width: '100%',
                  paddingVertical: 3,
                  paddingHorizontal: 6,
                }}
              >
                <View
                  style={{
                    width: '60%',
                    flex: 1,
                    marginRight: 20,
                  }}
                >
                  <View>
                    <Text style={styles.title}>{ t('OÜ Multipakend Tootmine') }</Text>
                  </View>
                  <View>
                    <Text style={styles.info}>{ `Tuuliku tee 4` }</Text>
                    <Text style={styles.info}>{ `10621 Tallinn` }</Text>
                    <Text style={styles.info}>{ `Telefon: +372 6802 730, +372 6562 666` }</Text>
                    <Text style={styles.info}>{ `E-mail: info@multipakend.ee` }</Text>
                  </View>
                </View>
                {
                  type === 'Pitsa' &&
                  <View
                    style={{
                      width: '20%',
                      marginRight: 20,
                    }}
                  >
                    <Image
                      style={styles.image}
                      source={require('../assets/food-safe.png')}
                    />
                  </View>
                }
                <View
                  style={{
                    width: '20%',
                    marginRight: 20,
                  }}
                >
                  <Image
                    style={styles.image}
                    source={require('../assets/logo.png')}
                  />
                </View>
              </View>
              :
              <></>
            }
          </View>
        </View>
      </Page>
      )
    }

    return pages
  }

  return (
    <Document>
      {
        printout?.count < 2 ?
        <Page size="A4" style={styles.page} orientation={'portrait'}>
          <View
            style={styles.wrapper}
          >

            <View style={[
              styles.row,
              {
                height: 100,
                borderBottomWidth: 1,
                paddingHorizontal: 6,
                paddingVertical: 3,
              },
            ]}>
              <View>
                <View>
                  <Text style={[
                    styles.label,
                    {
                      fontSize: 20,
                    },
                  ]}>
                    { `${t('Klient')}:` }
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      fontSize: 26,
                      fontFamily: 'Roboto',
                      fontWeight: 700,
                    }}
                  >
                    { `${data?.client?.NAME}` }
                  </Text>
                </View>
              </View>
            </View>

            <View style={styles.row}>
              <View style={[
                styles.col,
                {
                  borderBottomWidth: 0,
                  borderLeftWidth: 0,
                  borderRightWidth: 0,
                  paddingVertical: 3,
                  paddingHorizontal: 6,
                  flexDirection: 'row',
                },
              ]}>
                <View>
                  <Text style={styles.label}>{ `${t('Tarne aadress')}:` }</Text>
                </View>
                <View>
                  <Text style={{
                    ...styles.value,
                    fontSize: 12,
                    marginLeft: 10,
                  }}>{ `${data?.ADDRESS ? data.ADDRESS : ''}` }</Text>
                </View>
              </View>
            </View>

            <View style={[
              styles.row,
              {
                justifyContent: 'space-between',
                borderBottomWidth: 1,
                borderTopWidth: 1,
                paddingHorizontal: 6,
                paddingVertical: 3,
              },
            ]}>
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                <Text style={styles.label}>{ `${t('Kliendi tellimus')}: ` }</Text>
                <Text style={styles.value}>{ `${data?.KLIENT_ORDER}` }</Text>
              </View>
              <View>
                <Text style={styles.value}>{ `${formatDate(new Date(data?.ORDER_DATE))}` }</Text>
              </View>
            </View>

            <View style={[
              styles.row,
              {
                minHeight: 90,
                borderBottomWidth: 1,
                paddingVertical: 3,
                paddingHorizontal: 6,
              },
            ]}>
              <View style={[
                  styles.col,
                  {
                    
                  },
                ]}>
                <Text style={styles.label}>{ `${t('Toote nimetus / Mõõdud')}:` }</Text>
                <View style={[styles.row, { justifyContent: 'space-between' }]}>
                  <Text style={[styles.value, { fontSize: 24 }]}>{ `${data?.MOOT}` }</Text>
                  <Text style={[styles.value, { fontSize: 24 }]}>{ `${data?.KLIENT_BOXCODE ? data.KLIENT_BOXCODE : ''}` }</Text>
                </View>
              </View>
            </View>

            <View style={[styles.row, { borderBottomWidth: 1 }]}>
              <View style={[styles.row, { paddingHorizontal: 6, paddingVertical: 3, borderRightWidth: 1, flex: 1 }]}>
                <Text style={styles.label}>{ `${t('Materjal')}:` }</Text>
                <Text style={styles.value}>{ `${data?.TYPE ? data.TYPE : ''}` }</Text>
              </View>
              <View style={[{ paddingHorizontal: 6, paddingVertical: 3, width: '33.2%' }]}>
                <Text style={styles.label}>{ `${t('Tellitud kogus')}:` }</Text>
                <Text style={styles.value}>{ `${data?.KOGUS}` }</Text>
              </View>
            </View>

            <View style={[styles.row, { borderBottomWidth: 1 }]}>
              <View style={[
                  styles.col,
                  {
                    borderRightWidth: 1,
                    width: '33.333%',
                    paddingHorizontal: 6,
                    paddingVertical: 3,
                  },
              ]}>
                <Text style={styles.label}>{ `${t('Aluse nr')}` }</Text>
                <View style={{ flexDirection: 'row' }}>
                  <View style={{ width: 50 }} />
                  <Text style={styles.value}>{ `1 / 1` }</Text>
                </View>
              </View>
              <View style={[
                  styles.col,
                  {
                    borderRightWidth: 1,
                    width: '33.333%',
                    paddingHorizontal: 6,
                    paddingVertical: 3,
                  },
              ]}>
                <Text style={styles.label}>{ `${t('Aluse kõrgus')}` }</Text>
                <Text style={styles.value}>{ `${printout?.height ? printout.height : ''}` }</Text>
              </View>
              <View style={[
                  styles.col,
                  {
                    width: '33.333%',
                    paddingHorizontal: 6,
                    paddingVertical: 3,
                  },
              ]}>
                <Text style={styles.label}>{ `${t('Tarneaeg')}` }</Text>
                <Text style={styles.value}>{ `${formatDate(new Date(data?.DEADLINE))}` }</Text>
              </View>
            </View>

            <View style={[styles.row, { height: 70 }]}>
              <View style={[
                  styles.col,
                  {
                    borderRightWidth: 1,
                    paddingHorizontal: 6,
                    paddingVertical: 3,
                    width: '33.333%',
                  },
              ]}>
                <Text style={styles.label}>{ `${t('Tk/Alusel')}` }</Text>
                <Text style={styles.value}>{ printout?.items }</Text>
              </View>
              <View style={[
                  styles.col,
                  {
                    borderRightWidth: 1,
                    paddingHorizontal: 6,
                    paddingVertical: 3,
                    width: '33.333%',
                  },
              ]}>
                <Text style={styles.label}>{ `${t('Aluse mõõt')}` }</Text>
                <Text style={styles.value}>{ `${printout?.bottom ? printout.bottom : ''}` }</Text>
              </View>
              <View style={[
                  styles.col,
                  {
                    paddingHorizontal: 6,
                    paddingVertical: 3,
                    width: '33.333%',
                  },
              ]}>
                <Text style={styles.label}>{ `${t('Pakkija')}` }</Text>
                <Text style={styles.value}>{ `` }</Text>
              </View>
            </View>
            <View
              style={[
                styles.row,
                {
                  height: 200,
                  borderTopWidth: 1,
                },
              ]}
            >
              {
                type === 'Multipakend' || type === 'Pitsa' ?
                <View
                  style={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexDirection: 'row',
                    width: '100%',
                    paddingVertical: 3,
                    paddingHorizontal: 6,
                  }}
                >
                  <View
                    style={{
                      width: '60%',
                      flex: 1,
                      marginRight: 20,
                    }}
                  >
                    <View>
                      <Text style={styles.title}>{ t('OÜ Multipakend Tootmine') }</Text>
                    </View>
                    <View>
                      <Text style={styles.info}>{ `Tuuliku tee 4` }</Text>
                      <Text style={styles.info}>{ `10621 Tallinn` }</Text>
                      <Text style={styles.info}>{ `Telefon: +372 6802 730, +372 6562 666` }</Text>
                      <Text style={styles.info}>{ `E-mail: info@multipakend.ee` }</Text>
                    </View>
                  </View>
                  {
                    type === 'Pitsa' &&
                    <View
                      style={{
                        width: '20%',
                        marginRight: 20,
                      }}
                    >
                      <Image
                        style={styles.image}
                        source={require('../assets/food-safe.png')}
                      />
                    </View>
                  }
                  <View
                    style={{
                      width: '20%',
                      marginRight: 20,
                    }}
                  >
                    <Image
                      style={styles.image}
                      source={require('../assets/logo.png')}
                    />
                  </View>
                </View>
                :
                <></>
              }
            </View>
          </View>
        </Page>
        :
        renderPages()
      }
    </Document>
  )
}

export default PrintoutDocument