import { useMutation, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CreatePrintout, RemovePrintout } from '../graphql/mutations'
import { GetPallets, GetPrintouts } from '../graphql/queries'
import Button from './Button'
import Input from './Input'
import Modal from './Modal'
import SelectInput from './SelectInput'
import PdfViewer from './PdfViewer'
import { useNotification } from '../providers/Notification'

const initialPrintout = {
    items: 0,
    count: 0,
    height: 0,
    bottom: '',
}

const Printouts = ({ production, onUpdateField }) => {

    const { t } = useTranslation()
    const { dispatch } = useNotification()
    const [printouts, setPrintouts] = useState([])
    const [rowHeaders, setRowHeaders] = useState([
        {
            value: 'count',
            label: t('Aluseid'),
        },
        {
            value: 'bottom',
            label: t('Pindala2'),
        },
        {
            value: 'height',
            label: t('Kõrgus'),
        },
        {
            value: 'items',
            label: t('Tk. alusel'),
        },
    ])
    const [printoutTypes, setPrintoutTypes] = useState([
        {
            value: 'Logota',
            label: t('Logota'),
        },
        {
            value: 'Multipakend',
            label: 'Multipakend',
        },
        {
            value: 'Pitsa',
            label: t('Pitsa'),
        },
    ])
    const [selectedType, setSelectedType] = useState(production.PRINTOUT)
    const [showModal, setShowModal] = useState(false)
    const [newPrintout, setNewPrintout] = useState(initialPrintout)
    const [pallets, setPallets] = useState([])
    const [showPdfModal, setShowPdfModal] = useState(false)

    const [createPrintout] = useMutation(CreatePrintout)
    const [removePrintout] = useMutation(RemovePrintout)

    const { refetch } = useQuery(GetPrintouts, {
        variables: {
            prodId: parseInt(production.ID)
        },
        onCompleted: (data) => {
            const { getPrintouts } = data
            if (getPrintouts) setPrintouts(getPrintouts)
        }
    })

    useQuery(GetPallets, {
        onCompleted: (data) => {
            const { getPallets } = data
            if (getPallets && getPallets.length > 0) setPallets(getPallets)
        }
    })

    const handleAddPrintout = async () => {
        try {
            const res = await createPrintout({
                variables: {
                    data: {
                        ...newPrintout,
                        bottom: newPrintout.bottom.name,
                        prod_id: parseInt(production.ID),
                    },
                },
            })
            closeModal()
            console.log('handleAddPrintout', res)
        } catch (err) {
            console.log('handleAddPrintout', err)
        }
    }

    const handleDeletePrintout = async (id) => {
        try {
            const res = await removePrintout({
                variables: {
                    id: parseInt(id),
                },
            })

            console.log('handleDeletePrintout', res)
            refetch()
        } catch (err) {
            console.log('handleDeletePrintout', err)
        }
    }

    const closeModal = () => {
        setNewPrintout(initialPrintout)
        setShowModal(false)
        refetch()
    }

    const setField = (field, value) => {
        setNewPrintout({
            ...newPrintout,
            [field]: value,
        })
    }

    const renderModalActions = () => {
        return (
            <div className='modal-actions justify-content--flex-end'>
                <Button
                    label={t('Add printout')}
                    onClick={handleAddPrintout}
                />
            </div>
        )
    }

    const handleClosePdfModal = () => {
        setShowPdfModal(false)
    }

    const handlePrint = () => {
        if (printouts.length < 1) {
            dispatch({
                type: 'ADD',
                payload: {
                    content: t('Etikette pole lisatud'),
                    type: 'error',
                },
            })
            return
        }
        setShowPdfModal(true)
    }

    return (
        <div className='printouts'>
            <div className='printouts-title'>
                <h5>{ t('Etiketid') }</h5>
                <Button
                    label={t('Lisa etikett')}
                    onClick={() => setShowModal(true)}
                />
            </div>
            <div className='printouts-list'>
                { printouts.length > 0 ?
                    <table className='order-rows'>
                        <thead
                            className='order-rows--header'
                            style={{
                                gridTemplateColumns: `repeat(${rowHeaders.length}, 1fr)`
                            }}
                        >
                            <tr>
                            {
                                rowHeaders.map((headerItem) =>
                                    <th key={`header-${headerItem.value}`} className='order-rows--header-item'>
                                        { headerItem.label }
                                    </th>
                                )
                            }
                            <th></th>
                            </tr>
                        </thead>
                        <tbody className='order-rows--content'>
                            {
                                printouts.map((row, index) =>
                                    <tr
                                        key={`order-row-${index}`}
                                        className={`order-row`}
                                        style={{
                                            gridTemplateColumns: `repeat(${rowHeaders.length}, 1fr)`
                                        }}
                                    >
                                        {
                                            rowHeaders.map((headerItem, itemIndex) =>
                                                <td
                                                    key={`order-row-${index}-${itemIndex}`}
                                                    className='order-row--item'
                                                >
                                                    { row[headerItem.value] }
                                                </td>
                                            )
                                        }
                                        <td>
                                            <Button
                                                label={t('Kustuta')}
                                                onClick={() => handleDeletePrintout(row.id)}
                                            />
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                    :
                    <div className='empty-result'>
                        { t('Etikette pole lisatud') }
                    </div>
                }
            </div>
            <div className='printouts-actions'>
                <SelectInput
                    label={t('Etikett')}
                    value={printoutTypes.find(x => x.value === selectedType)}
                    options={printoutTypes}
                    onChange={(option) => {
                        setSelectedType(option.value)
                        if (onUpdateField) onUpdateField('PRINTOUT', option.value)
                    }}
                />
                <Button
                    label={t('Prindi')}
                    onClick={handlePrint}
                />
            </div>

            <Modal
                title={t('Lisa etikett')}
                show={showModal}
                close={() => {
                    setNewPrintout(initialPrintout)
                    setShowModal(false)
                }}
                renderActions={renderModalActions}
            >
                <SelectInput
                    label={`${t('Pindala')}:`}
                    options={pallets}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.name}
                    onChange={(option) => setField('bottom', option)}
                    value={newPrintout.bottom}
                />
                <Input
                    type={'number'}
                    label={t('Aluseid')}
                    value={newPrintout.count}
                    onChange={(e) => setField('count', parseInt(e.target.value))}
                />
                <Input
                    type={'number'}
                    label={t('Tk. alusel')}
                    value={newPrintout.items}
                    onChange={(e) => setField('items', parseInt(e.target.value))}
                />
                <Input
                    type={'number'}
                    label={t('Kõrgus')}
                    value={newPrintout.height}
                    onChange={(e) => setField('height', parseInt(e.target.value))}
                />
            </Modal>

            <Modal
                show={showPdfModal}
                className={'make-order-modal'}
                close={handleClosePdfModal}
            >
                {
                    printouts?.length > 0 &&
                    <PdfViewer showLoading={true} document={'printout'} data={{
                        production,
                        printout: printouts.length > 0 ? printouts[0] : null,
                        type: selectedType,
                    }} />
                }
            </Modal>
        </div>
    )
}

export default Printouts