import React from 'react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'

const SelectInput = ({
  label,
  value,
  options,
  onChange,
  error,
  filterOption,
  getOptionLabel,
  getOptionValue,
  loadingMessage,
  noOptionsMessage,
  defaultInputValue,
  defaultValue,
  load,
  formatOptionLabel,
  components,
  placeholder,
}) => {

  return (
    <div className={`input-wrapper${error ? ' input-wrapper--error' : ''}`}>
      <label>
        { label && <span>{ label }</span> }
        {
          load ?
          <AsyncSelect
            loadOptions={load}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            loadingMessage={loadingMessage}
            noOptionsMessage={noOptionsMessage}
            value={value}
            onChange={onChange}
            defaultInputValue={defaultInputValue}
            defaultValue={defaultValue}
            formatOptionLabel={formatOptionLabel}
            components={components}
            placeholder={placeholder ? placeholder : 'Vali'}
          />
          :
          <Select
            options={options}
            onChange={onChange}
            value={value}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            loadingMessage={loadingMessage}
            noOptionsMessage={noOptionsMessage}
            filterOption={filterOption}
            formatOptionLabel={formatOptionLabel}
            components={components}
            placeholder={placeholder ? placeholder : 'Vali'}
          />
        }
      </label>
      { error &&
        <div className="input-error">{ error }</div>
      }
    </div>
  )
}

export default SelectInput