import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/Button'
import Input from '../../components/Input'
import { useAuth } from '../../providers/Auth'
import { useNotification } from '../../providers/Notification'
import './style.scss'

const LoginScreen = () => {

    const { user, signIn } = useAuth()
    const { dispatch } = useNotification()
    const navigate = useNavigate()
    const [username, setUsername] = useState()
    const [password, setPassword] = useState()

    useEffect(() => {
        if (user) {
            navigate('/', {
                replace: true,
            })
        }
    }, [])

    const login = async () => {
        const res = await signIn(username, password)
        if (!res) {
            dispatch({
                type: 'ADD',
                payload: {
                    type: 'error',
                    content: 'Invalid credentials',
                },
            })
            return
        }
        dispatch({
            type: 'REMOVE_ALL',
        })
    }

    const handleEnter = ({ key }) => {
        if (key === 'Enter') login()
    }

    return (
        <div className='login'>
            <div className='inner'>
                <div className='brand'>
                    <div className='brand-logo'></div>
                </div>
                <Input
                    label={t('Username')}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <Input
                    label={t('Password')}
                    type={'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyUp={handleEnter}
                />
                <div className='login-actions'>
                    <Button
                        label={t('Sign in')}
                        onClick={login}
                    />
                </div>
            </div>
        </div>
    )
}

export default LoginScreen