const Constants = {
    Failure: 2.0,
    PrintPrice: 0.019,
    LabourPlaceholder: 0,
    OfsetPrice: 0.192,
}

const Status = {
    All: 'all',
    InProduction: 'inProduction',
    Sent: 'sent',
    Ready: 'ready',
    PrepaymentNotDone: 'notPrepayed',
    Overdue: 'overdue',
    Pending: 'pending',
    Debt: 'debt',
    ReadyNotSent: 'readyNotSent',
    TransportOrdered: 'transport',
}

export {
    Constants,
    Status,
}