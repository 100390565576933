import React, { useRef, useState } from 'react'
import './style.scss'
import { useMutation, useQuery } from '@apollo/client'
import { GetOrderByNumber, GetProduction, SearchClients } from '../../graphql/queries'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import DateInput from '../../components/DateInput'
import Input from '../../components/Input'
import Checkbox from '../../components/Checkbox'
import Textarea from '../../components/Textarea'
import BoxWorkflow from '../../components/BoxWorkflow'
import { DuplicateProductionItem, SendOrderEmail, SetProductionToOrder, UpdateProductionItem } from '../../graphql/mutations'
import ActivityIndicator from '../../components/ActivityIndicator'
import { parseDate } from '../../util/format'
import BackButton from '../../components/BackButton'
import Button from '../../components/Button'
import SelectInput from '../../components/SelectInput'
import CreatableInputEdit from '../../components/CreatableInputEdit'
import Printouts from '../../components/Printouts'
import EmailLog from '../../components/EmailLog'
import Modal from '../../components/Modal'
import PdfViewer from '../../components/PdfViewer'
import { useNotification } from '../../providers/Notification'
import OrderAltDocument from '../../documents/order-document-alt'

import { pdf } from '@react-pdf/renderer'

const SalesScreen = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const { id } = useParams()
    const { dispatch } = useNotification()

    const initialTransportOptions = [
        {
            value: 'Multipakend',
            label: t('Multipakend'),
        },
        {
            value: 'Klient',
            label: t('Klient'),
        },
        {
            value: 'Tellida',
            label: t('Tellida'),
        },
    ]
    
    const [prodData, setProdData] = useState(null)
    const [transportOptions, setTransportOptions] = useState(initialTransportOptions)
    const [clientTransports, setClientTransports] = useState([])
    const [clientContacts, setClientContacts] = useState([])
    const [orderNumber, setOrderNumber] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [orderData, setOrderData] = useState(null)

    const [makeOrder] = useMutation(SetProductionToOrder)
    const [duplicateRow] = useMutation(DuplicateProductionItem)
    const [sendEmail] = useMutation(SendOrderEmail)

    const { loading, refetch } = useQuery(GetProduction, {
        variables: {
          id: parseInt(id)
        },
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            const { getProduction } = data
            if (getProduction) {
                setProdData(getProduction)
                if (getProduction.client) {
                    if (getProduction.client.contacts) {
                        setClientContacts(getProduction.client.contacts)
                    }
                    if (getProduction.client.transports) {
                        setClientTransports(getProduction.client.transports)
                    }
                    if (getProduction.client.TRANSFIRMA) {
                        setTransportOptions([
                            {
                                label: getProduction.client.TRANSFIRMA,
                                value: getProduction.client.TRANSFIRMA,
                            },
                            ...transportOptions,
                        ])
                    }
                }
                setOrderNumber(getProduction.order && getProduction.order.ORDERNO ? getProduction.order.ORDERNO : null)
            }
        },
    })

    const { refetch: searchClients } = useQuery(SearchClients, {
        variables: {
            searchInput: '',
        },
    })

    const { refetch: getOrderByNumber } = useQuery(GetOrderByNumber, {
        skip: true,
    })

    const [updateProductionItem] = useMutation(UpdateProductionItem)

    const setField = (field, value) => {
        setProdData({
            ...prodData,
            [field]: value,
        })

        const finalValue = field === 'PRICE' ? parseFloat(value) : value
        updateProperty(field, finalValue)
    }

    const handleClientChange = (client) => {
        if (!client || !client.ID) return

        setProdData({
            ...prodData,
            client,
        })
        updateProperty('KLIENT_ID', client.ID)
    }

    const duplicate = async () => {
        try {
            const { data } = await duplicateRow({
                variables: {
                    id: parseInt(prodData.ID),
                },
            })
            if (data && data.duplicateProductionItem !== 'Fail') {
                dispatch({
                    type: 'ADD',
                    payload: {
                        content: t('Tellimus kopeeritud'),
                        type: 'success',
                    },
                })
                navigate(`/sale/${data.duplicateProductionItem}`)
            }
        } catch (err) {
            console.log('duplicate', err)
        }
    }

    const loadClients = async (input) => {
        if (input.length < 3) return
        const res = await searchClients({
            searchInput: input,
        })
        return res.data.searchClients
    }

    const updateProperty = async (field, value) => {
        try {
            const result = await updateProductionItem({
                variables: {
                    id: parseInt(id),
                    data: {
                        [field]: value,
                    },
                },
            })

            if (field === 'FIN_DATE') {
                if (!['Fail', 'Success'].includes(result?.data?.updateProductionItem) && parseInt(result?.data?.updateProductionItem) > 0) {
                    dispatch({
                        type: 'ADD',
                        payload: {
                            content: `Selle tellimuse ${result?.data?.updateProductionItem} rida on veel täitmata`,
                            type: 'info',
                        },
                    })
                    return
                }
                refetch()
            }
        } catch (err) {
            console.log('updateProperty:', err)
        }
    }

    const viewQuotation = async () => {
        try {
            const { data } = await getOrderByNumber({
                orderNumber: parseInt(orderNumber),
            })

            if (data && data.getOrderByNumber) {
                navigate(`/order/${data.getOrderByNumber.ID}`)
            }
        } catch (err) {
            console.log('Error getting quotation', err)
        }
    }

    const handleQuotationNumberChange = async (e) => {
        try {
            setOrderNumber(e.target.value)

            if (!e.target.value) return

            const { data } = await getOrderByNumber({
                orderNumber: parseInt(e.target.value)
            })
            
            if (data && data.getOrderByNumber) {
                setField('ORDER_ID', data.getOrderByNumber.ID)
            }
        } catch (err) {
            console.log('handleQuotationNumberChange', err)
        }
    }

    const handleMakeOrderPreview = () => {
        const updatedData = [
            {
                ...prodData,
                contact: clientContacts.find(x => x.ID === prodData.CONTACT_ID),
            },
        ]
        setOrderData(updatedData)

        handleShowModal()
    }

    const handleMakeOrder = async () => {
        if (!prodData.ORDER_DATE || !prodData.KLIENT_ORDER || prodData.KLIENT_ORDER === '0') {
            dispatch({
                type: 'ADD',
                payload: {
                    content: t('Kontrolli kliendi tellimuse numbrit ja kuupäeva'),
                    type: 'error',
                },
            })
            return
        }
        try {
            const { data } = await makeOrder({
                variables: {
                    ids: [parseInt(id)],
                },
            })

            if (data?.setProductionToOrder) {
                handleCloseModal()
                const updatedData = [
                    {
                        ...prodData,
                        TELLIMUSNO: data.setProductionToOrder,
                        contact: clientContacts.find(x => x.ID === prodData.CONTACT_ID),
                    },
                ]
                setOrderData(updatedData)
                handleShowModal()

                handleEmail(updatedData, parseInt(data.setProductionToOrder))
            }
        } catch (err) {
            console.log('openPdf:', err)
        }
    }

    const handleEmail = async (documentData, orderNo) => {
        if (!documentData || !orderNo) return

        try {
            const pdfFile = pdf(<OrderAltDocument data={documentData} />)
            pdfFile.updateContainer(<OrderAltDocument data={documentData} />)
            const blob = await pdfFile.toBlob()
            const file = new File([blob], 'file.pdf', { type: 'application/pdf'})

            const res = await sendEmail({
                variables: {
                    orderNo,
                    file,
                },
            })

            if (res?.data?.sendOrderEmail === 'Success') {
                dispatch({
                    type: 'ADD',
                    payload: {
                        type: 'success',
                        content: t('Email saadetud')
                    },
                })
            } else {
                dispatch({
                    type: 'ADD',
                    payload: {
                        type: 'error',
                        content: t('Emaili saatmine ebaõnnestus')
                    },
                })
            }
        } catch (err) {
            console.log('handleEmail:', err)
            dispatch({
                type: 'ADD',
                payload: {
                    type: 'error',
                    content: t('Emaili saatmine ebaõnnestus')
                },
            })
        }
    }

    const handleCopyContact = () => {
        const contact = clientContacts.find(x => x.ID === prodData.CONTACT_ID)
        navigator.clipboard.writeText(contact.EMAIL)
        
        dispatch({
            type: 'ADD',
            payload: {
                type: 'success',
                content: t('Kontakt kopeeritud')
            },
        })
    }

    const handleTransportChange = (option) => {
        setProdData({
            ...prodData,
            TRANSPORDIFIRMA: option.value,
        })
        updateProperty('TRANSPORDIFIRMA', option.value)
    }

    const handleShowModal = () => {
        setShowModal(true)
    }

    const handleCloseModal = () => {
        setShowModal(false)
    }

    if (loading) return (
        <div className='loading-view'>
            <ActivityIndicator />
        </div>
    )

    return (
        <div className='sales'>
            <div className='inner'>
                <div className='inner-title'>
                    <h2>{ t('Müük') }</h2>
                    <div className='inner-title--actions'>
                        <Button
                            label={t('Tee tellimusest koopia')}
                            onClick={duplicate}
                        />
                        <Button
                            label={t('Koosta tellimus')}
                            onClick={() => handleMakeOrderPreview()}
                        />
                    </div>
                </div>
                { prodData ?
                    <div className='sales-fields'>
                        <div className='editable-fields'>
                            <div className='field-row top-fields'>
                                <div className='field-col'>
                                    <Input
                                        label={t('Number')}
                                        type={'number'}
                                        value={prodData.ORDERNO}
                                        onChange={(e) => setField('ORDERNO', parseInt(e.target.value))}
                                    />
                                    <DateInput
                                        label={t('Tellimuse kuupäev')}
                                        value={prodData.ORDER_DATE && prodData.ORDER_DATE !== '0000-00-00' ? new Date(prodData.ORDER_DATE) : null}
                                        onChange={(val) => setField('ORDER_DATE', parseDate(val))}
                                    />
                                    <DateInput
                                        label={t('Tähtaeg')}
                                        value={prodData.DEADLINE && prodData.DEADLINE !== '0000-00-00' ? new Date(prodData.DEADLINE) : null}
                                        onChange={(val) => setField('DEADLINE', parseDate(val))}
                                    />
                                    <DateInput
                                        label={t('Reaalne tähtaeg')}
                                        value={prodData.NEW_DEADLINE && prodData.NEW_DEADLINE !== '0000-00-00' ? new Date(prodData.NEW_DEADLINE) : null}
                                        onChange={(val) => setField('NEW_DEADLINE', parseDate(val))}
                                    />
                                    <DateInput
                                        label={t('Tootmise algus')}
                                        value={prodData.START_DATE && prodData.START_DATE !== '0000-00-00' ? new Date(prodData.START_DATE) : null}
                                        onChange={(val) => setField('START_DATE', parseDate(val))}
                                    />
                                </div>
                                <div className='field-col'>
                                    <DateInput
                                        label={t('Tootmise lõpp')}
                                        value={prodData.FIN_DATE && prodData.FIN_DATE !== '0000-00-00' ? new Date(prodData.FIN_DATE) : null}
                                        onChange={(val) => setField('FIN_DATE', parseDate(val))}
                                    />
                                    <DateInput
                                        label={t('Väljastatud')}
                                        value={prodData.SHIPPED  && prodData.SHIPPED !== '0000-00-00' ? new Date(prodData.SHIPPED) : null}
                                        onChange={(val) => setField('SHIPPED', parseDate(val))}
                                    />
                                    <Input
                                        label={t('Aluseid')}
                                        type={'text'}
                                        value={prodData.ALUSEID}
                                        onChange={(e) => setField('ALUSEID', e.target.value)}
                                    />
                                    <Input
                                        label={t('Tegelik kogus')}
                                        type={'number'}
                                        value={prodData.RKOGUS}
                                        onChange={(e) => setField('RKOGUS', parseInt(e.target.value))}
                                    />
                                </div>
                                <div className='field-col'>
                                    <div className='related-order'>
                                        <Input
                                            label={t('Hinnapakkumine')}
                                            type={'number'}
                                            value={orderNumber}
                                            onChange={handleQuotationNumberChange}
                                        />
                                        <Button
                                            label={t('Hinnapakkumine')}
                                            onClick={viewQuotation}
                                        />
                                    </div>
                                    <Textarea
                                        label={t('Kommentaar')}
                                        value={prodData.KOMMENTAAR}
                                        onChange={(e) => setField('KOMMENTAAR', e.target.value)}
                                    />
                                    <Input
                                        label={t('Mõõt')}
                                        type={'text'}
                                        value={prodData.ALUS_MOOT}
                                        onChange={(e) => setField('ALUS_MOOT', e.target.value)}
                                    />
                                </div>
                                <div className='field-col'>
                                    <h5>{ t('Maksumused') }</h5>
                                    <Input
                                        label={t('Stants')}
                                        type={'number'}
                                        value={prodData.STANTS}
                                        onChange={(e) => setField('STANTS', parseFloat(e.target.value))}
                                    />
                                    <Input
                                        label={t('Klishee')}
                                        type={'number'}
                                        value={prodData.KLISHEE}
                                        onChange={(e) => setField('KLISHEE', parseFloat(e.target.value))}
                                    />
                                    <Input
                                        label={t('Transport')}
                                        type={'number'}
                                        value={prodData.TRANSPORT}
                                        onChange={(e) => setField('TRANSPORT', parseFloat(e.target.value))}
                                    />
                                    <Input
                                        label={t('Alus')}
                                        type={'number'}
                                        value={prodData.PALLET}
                                        onChange={(e) => setField('PALLET', parseFloat(e.target.value))}
                                    />
                                </div>
                            </div>
                            <div className='field-row'>
                                <div className='field-col'>
                                    <div className='box-fields'>
                                        <Input
                                            label={t('Kogus')}
                                            value={prodData.KOGUS}
                                            type={'number'}
                                            onChange={(e) => setField('KOGUS', parseInt(e.target.value))}
                                        />
                                        <Input
                                            label={t('Hind')}
                                            value={prodData.PRICE}
                                            type={'number'}
                                            onChange={(e) => setField('PRICE', e.target.value)}
                                        />
                                        <div className='box-fields-item'>
                                            <span className='box-fields-item--label'>{ t('Tüüp') }</span>
                                            <span className='box-fields-item--value'>{ prodData.BOX }</span>
                                        </div>
                                        <div className='box-fields-item'>
                                            <span className='box-fields-item--label'>{ t('Trükk') }</span>
                                            <span className='box-fields-item--value'>{ prodData.TRYKK }</span>
                                        </div>
                                        <div className='box-fields-item'>
                                            <span className='box-fields-item--label'>{ t('Materjal') }</span>
                                            <span className='box-fields-item--value'>{ prodData.MATERIAL }</span>
                                        </div>
                                        <div className='box-fields-item'>
                                            <span className='box-fields-item--label'>{ t('Mõõt') }</span>
                                            <span className='box-fields-item--value'>{ prodData.MOOT }</span>
                                        </div>
                                        <div className='box-fields-item'>
                                            <span className='box-fields-item--label'>{ t('Toote kirjeldus') }</span>
                                            <span className='box-fields-item--value'>{ prodData.KLIENT_BOXCODE }</span>
                                        </div>
                                        <div className='box-fields-item'>
                                            <span className='box-fields-item--label'>{ t('Toote kood') }</span>
                                            <span className='box-fields-item--value'>{ prodData.PRODUCTION_CODE }</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='field-col'>
                                        <Printouts
                                            production={prodData}
                                            onUpdateField={updateProperty}
                                        />
                                    </div>
                            </div>
                        </div>

                        <div className='client-fields'>
                            <div className='field-row'>
                                <div className='field-col field-col--client'>
                                    <h5>{ t('Klient') }</h5>
                                    <div className='field-row'>
                                        <div className='field-col'>
                                            <SelectInput
                                                label={`${t('Nimi')}`}
                                                value={prodData.client}
                                                load={loadClients}
                                                getOptionLabel={(option) => option.NAME}
                                                getOptionValue={(option) => option.ID}
                                                loadingMessage={() => t('Otsin kliente')}
                                                noOptionsMessage={() => t('Otsi..')}
                                                onChange={handleClientChange}
                                                defaultValue={prodData.client}
                                            />
                                            <Input
                                                label={t('Tellimuse number')}
                                                value={prodData.KLIENT_ORDER}
                                                onChange={(e) => setField('KLIENT_ORDER', e.target.value)}
                                            />
                                            <div className='field-row'>
                                                <Checkbox
                                                    label={t('Võlgnik')}
                                                    value={prodData.client.DEPT === 1}
                                                    disabled={true}
                                                />
                                                <Checkbox
                                                    label={t('Multipakendi firma')}
                                                    value={prodData.client.COMMON === 1}
                                                    disabled={true}
                                                />
                                            </div>
                                            <div className='field-row'>
                                                <Checkbox
                                                    label={t('Ettemaks on tehtud')}
                                                    value={prodData.ETTEMAKS === 1}
                                                    onChange={(e) => setField('ETTEMAKS', e.target.checked ? 1 : 0)}
                                                />
                                                <Checkbox
                                                    label={t('Ettemaks on vajalik')}
                                                    value={prodData.client && prodData.client.ETTEMAKS === 1}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                        <div className='field-col'>
                                            <div className='field-row field-row--contact'>
                                                <SelectInput
                                                    label={`${t('Kontakt')}`}
                                                    value={clientContacts.find(x => x.ID === prodData.CONTACT_ID)}
                                                    options={clientContacts}
                                                    getOptionLabel={(option) => option.EMAIL}
                                                    getOptionValue={(option) => option.ID}
                                                    onChange={(val) => setField('CONTACT_ID', val.ID)}
                                                />
                                                <Button
                                                    label={t('Kopeeri kontakt')}
                                                    onClick={handleCopyContact}
                                                />
                                            </div>
                                            <CreatableInputEdit
                                                label={`${t('Transport')}`}
                                                value={prodData.TRANSPORDIFIRMA ? {
                                                    label: prodData.TRANSPORDIFIRMA,
                                                    value: prodData.TRANSPORDIFIRMA,
                                                } : null}
                                                options={transportOptions}
                                                noOptionsMessage={() => t('Lisa uus transport')}
                                                onChange={handleTransportChange}
                                                placeholder={'Vali või sisesta'}
                                            />
                                            <DateInput
                                                label={t('Transport tellitud')}
                                                value={prodData.TRANSPORT_DATE && prodData.TRANSPORT_DATE !== '0000-00-00' ? new Date(prodData.TRANSPORT_DATE) : null}
                                                onChange={(val) => setField('TRANSPORT_DATE', parseDate(val))}
                                            />
                                            <Checkbox
                                                label={t('Klient teavitatud')}
                                                value={prodData.KLIENT_INFORMED === 1}
                                                onChange={(e) => setField('KLIENT_INFORMED', e.target.checked ? 1 : 0)}
                                            />
                                        </div>
                                        <div className='field-col'>
                                            {
                                                clientTransports && clientTransports.length > 0 && clientTransports[0].TRANSPORT ?
                                                <SelectInput
                                                    label={t('Aadressid')}
                                                    value={clientTransports.find(x => x.TRANSPORT === prodData.ADDRESS)}
                                                    options={clientTransports}
                                                    getOptionLabel={(option) => option.TRANSPORT}
                                                    getOptionValue={(option) => option.TRANSPORT}
                                                    onChange={(option) => setField('ADDRESS', option.TRANSPORT)}
                                                />
                                                :
                                                <></>
                                            }
                                            <Textarea
                                                label={t('Aadress')}
                                                value={prodData.ADDRESS}
                                                onChange={(e) => setField('ADDRESS', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <EmailLog
                            emails={prodData.emails}
                        />
                    </div>
                    :
                    <div className='empty-result'>
                        <p>{ t('Production not found') }</p>
                    </div>
                }
            </div>
            <Modal
                show={showModal}
                className={'make-order-modal'}
                close={handleCloseModal}
            >
                {
                    orderData &&
                    <PdfViewer document={'order-alt'} data={orderData} onSendEmail={handleMakeOrder} />
                }
            </Modal>
        </div>
    )
}

export default SalesScreen