import React, { useRef, useState } from 'react'
import './style.scss'
import { useMutation, useQuery } from '@apollo/client'
import { GetAllMaterials, SearchAllMaterial, SearchMaterial } from '../../graphql/queries'
import { useTranslation } from 'react-i18next'
import ActivityIndicator from '../../components/ActivityIndicator'
import { CreateMaterial, RemoveMaterial, UpdateMaterial } from '../../graphql/mutations'
import TableInput from '../../components/TableInput'
import IconButton from '../../components/IconButton'
import Button from '../../components/Button'
import MinusIcon from '../../components/icons/minus'
import PlusIcon from '../../components/icons/plus'
import Modal from '../../components/Modal'
import Input from '../../components/Input'
import { useNotification } from '../../providers/Notification'
import Search from '../../components/Search'
import Pagination from '../../components/Pagination'

const MaterialsScreen = () => {

    const { t } = useTranslation()
    const page = useRef(0)
    const limit = useRef(100)
    const [tableHeaders, setTableHeaders] = useState([])
    const [selectedRow, setSelectedRow] = useState()
    const [showModal, setShowModal] = useState(false)
    const [newItem, setNewItem] = useState({})
    const [materials, setMaterials] = useState({
        count: 0,
        rows: [],
    })

    const { loading, refetch } = useQuery(GetAllMaterials, {
        variables: {
            page: page.current,
            limit: limit.current,
          },
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            const { getAllMaterials } = data
            if (getAllMaterials?.rows?.length > 0) {
                let keys = []
                for (const key in getAllMaterials.rows[0]) {
                    if (['__typename', 'ID'].includes(key)) continue
                    keys.push(key)
                }
                setTableHeaders(keys)
                setMaterials(getAllMaterials)
            }
        }
    })

    const { refetch: searchMaterial } = useQuery(SearchAllMaterial, {
        skip: true,
    })
    
    const [updateItem] = useMutation(UpdateMaterial)
    const [createItem] = useMutation(CreateMaterial)
    const [removeItem] = useMutation(RemoveMaterial)

    const getPreviousPage = () => {
        page.current = page.current - 1
        refetch({
            page: page.current,
            limit: limit.current,
        })
    }
    
    const getNextPage = () => {
        page.current = page.current + 1
        refetch({
            page: page.current,
            limit: limit.current,
        })
    }

    const handleSearch = async (input) => {
        if (input.length <= 0) {
            const { data } = await refetch({
                page: page.current,
                limit: limit.current,
            })
            if (data && data.getAllMaterials) {
                setMaterials(data.getAllMaterials)
            }
            return
        }
        if (input.length < 2) return
        const { data } = await searchMaterial({
            searchInput: input,
        })
        if (data && data.searchMaterial) {
            const item = {
                count: 0,
                rows: data.searchMaterial,
            }
            setMaterials(item)
        }
    }

    const addItem = async () => {
        if (!validateFields()) return
        try {
            console.log('adding:', newItem)
            const { data } = await createItem({
                variables: {
                    data: newItem,
                },
            })
            if (data.createMaterial) {
                setShowModal(false)
                setNewItem({})
                refetch()
            }
        } catch (err) {
            console.log('Error creating material', err)
        }
    }

    const deleteItem = async () => {
        if (!selectedRow) return
        try {
            const { data } = await removeItem({
                variables: {
                    factory: selectedRow.FACTORY,
                    mark: selectedRow.MARK,
                },
            })
            setSelectedRow(null)
            console.log('remove:', data)
            if (data.removeMaterial) refetch()
        } catch (err) {
            console.log('Error deleting material', err)
        }
    }

    const updateProperty = async (item, field, value) => {
        try {
            let parsedValue = value
            if (field === 'PRICE') parsedValue = parseFloat(value)
            if (['LAYER', 'ACTIVE', 'DENSITY'].includes(field)) parsedValue = parseInt(value)

            await updateItem({
                variables: {
                    factory: item.FACTORY,
                    mark: item.MARK,
                    data: {
                        [field]: parsedValue,
                    },
                },
            })
        } catch (err) {
            console.log('updateProperty:', err)
        }
    }

    const validateFields = () => {
        if (!newItem.FACTORY || !newItem.PRICE || !newItem.MARK || !newItem.LAYER) return false
        return true
    }

    if (loading) return (
        <div className='loading-view'>
            <ActivityIndicator />
        </div>
    )

    return (
        <div className='materials-screen'>
            <div className='inner'>
                <div className='inner-title'>
                    <h2>{ t('Materjalid') }</h2>
                    <div className='inner-title--actions'>
                        <IconButton
                            label={'Lisa'}
                            icon={<PlusIcon />}
                            onClick={() => setShowModal(true)}
                        />
                        <IconButton
                            label={'Kustuta'}
                            icon={<MinusIcon />}
                            disabled={!selectedRow}
                            onClick={deleteItem}
                        />
                    </div>
                </div>
                <Search
                    search={handleSearch}
                    placeholder={t('Otsi materjali')}
                />
                { tableHeaders && tableHeaders.length > 0 ?
                    <div className='table-wrapper'>
                        <table className='input-table'>
                            <thead>
                                <tr>
                                {
                                    tableHeaders.map((x, i) =>
                                        <th key={`table-header-col-${i}`} className='grid-list-item--col'>{ t(x) }</th>
                                    )
                                }
                                </tr>
                            </thead>
                            <tbody>
                            {
                                materials.rows?.map((item) =>
                                    <tr
                                        key={`${item.FACTORY}-${item.MARK}`}
                                        onClick={() => setSelectedRow(item)}
                                        className={`table-row${selectedRow && (selectedRow.FACTORY === item.FACTORY && selectedRow.MARK === item.MARK) ? ' table-row--selected' : ''}`}
                                    >
                                        {
                                            tableHeaders.map((x) =>
                                                <td key={`${item.FACTORY}-${item.MARK}-${x}`}>
                                                    {
                                                        x === 'FACTORY' || x === 'MARK' ?
                                                        <TableInput
                                                            initialValue={item[x]}
                                                            label={x}
                                                            onBlur={(field, value) => {
                                                                updateProperty(item, field, value)
                                                            }}
                                                        />
                                                        :
                                                        <TableInput
                                                            initialValue={item[x]}
                                                            label={x}
                                                            fieldUpdated={(field, value) => {
                                                                updateProperty(item, field, value)
                                                            }}
                                                        />
                                                    }
                                                </td>
                                            )
                                        }
                                    </tr>
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                    :
                    <div className='empty-result'>
                        <p>{ t('Materjale ei leitud') }</p>
                    </div>
                }
                <Pagination total={materials?.count} onPrev={getPreviousPage} onNext={getNextPage} current={page.current} limit={limit.current} />
            </div>
            <Modal
                title={t('Uus materjal')}
                show={showModal}
                close={() => setShowModal(false)}
              >
                <h5>{ t('Lisa uus materjal') }</h5>
                <Input
                    label={`${t('Tehas')}*:`}
                    value={newItem.FACTORY}
                    onChange={(e) => setNewItem({
                        ...newItem,
                        FACTORY: e.target.value,
                    })}
                />
                <Input
                    label={`${t('Mark')}*:`}
                    value={newItem.MARK}
                    onChange={(e) => setNewItem({
                        ...newItem,
                        MARK: e.target.value,
                    })}
                />
                <Input
                    label={`${t('Mark 2')}:`}
                    value={newItem.MARK2}
                    onChange={(e) => setNewItem({
                        ...newItem,
                        MARK2: e.target.value,
                    })}
                />
                <Input
                    label={`${t('Tüüp')}:`}
                    value={newItem.TYPE}
                    onChange={(e) => setNewItem({
                        ...newItem,
                        TYPE: e.target.value,
                    })}
                />
                <Input
                    label={`${t('Kood')}:`}
                    value={newItem.CODE}
                    onChange={(e) => setNewItem({
                        ...newItem,
                        CODE: e.target.value,
                    })}
                />
                <Input
                    label={`${t('Hind')}*:`}
                    value={newItem.PRICE}
                    type={'number'}
                    onChange={(e) => setNewItem({
                        ...newItem,
                        PRICE: parseFloat(e.target.value),
                    })}
                />
                <Input
                    label={`${t('Kihid')}*:`}
                    value={newItem.LAYER}
                    type={'number'}
                    onChange={(e) => setNewItem({
                        ...newItem,
                        LAYER: parseInt(e.target.value),
                    })}
                />
                <Input
                    label={`${t('Aktiivne')}:`}
                    value={newItem.ACTIVE}
                    type={'number'}
                    onChange={(e) => setNewItem({
                        ...newItem,
                        ACTIVE: parseInt(e.target.value),
                    })}
                />
                <Input
                    label={`${t('Tihedus')}:`}
                    value={newItem.DENSITY}
                    type={'number'}
                    onChange={(e) => setNewItem({
                        ...newItem,
                        DENSITY: parseInt(e.target.value),
                    })}
                />
                <Button
                    label={t('Lisa materjal')}
                    onClick={addItem}
                    disabled={!validateFields()}
                />
            </Modal>
        </div>
    )
}

export default MaterialsScreen