import React, { useRef, useState } from 'react'
import './style.scss'
import { useMutation, useQuery } from '@apollo/client'
import { GetConstants } from '../../graphql/queries'
import { useTranslation } from 'react-i18next'
import ActivityIndicator from '../../components/ActivityIndicator'
import { UpdateConstant } from '../../graphql/mutations'
import Input from '../../components/Input'
import { useNotification } from '../../providers/Notification'

const ConstantsScreen = () => {

    const { t } = useTranslation()
    const { dispatch } = useNotification()
    const [consts, setConsts] = useState({})

    const { data, loading, refetch } = useQuery(GetConstants, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            const { getConstants } = data
            if (getConstants) setConsts(getConstants)
        }
    })
    
    const [updateItem] = useMutation(UpdateConstant)

    const setField = (field, value) => {
        setConsts({
            ...consts,
            [field]: value,
        })

        updateProperty(field, value)
    }

    const updateProperty = async (field, value) => {
        try {
            await updateItem({
                variables: {
                    id: parseInt(data.getConstants.ID),
                    data: {
                        [field]: value,
                    },
                },
            })
        } catch (err) {
            console.log('updateProperty:', err)
        }
    }

    if (loading) return (
        <div className='loading-view'>
            <ActivityIndicator />
        </div>
    )

    return (
        <div className='constants'>
            <div className='inner'>
                <div className='inner-title'>
                    <h2>{ t('Parameetrid') }</h2>
                </div>
                { consts ?
                    <div className='constants-wrapper'>
                        <div className='field-row'>
                            <div className='field-col'>
                                <Input
                                    type={'number'}
                                    label={t('Praagiprotsent')}
                                    value={consts.FAILURE}
                                    onChange={(e) => setField('FAILURE', parseFloat(e.target.value))}
                                />
                                <Input
                                    type={'number'}
                                    label={t('Trükikulud')}
                                    value={consts.PRINTPRICE}
                                    onChange={(e) => setField('PRINTPRICE', parseFloat(e.target.value))}
                                />
                                <Input
                                    type={'number'}
                                    label={t('Offseti hind')}
                                    value={consts.OFFSET}
                                    onChange={(e) => setField('OFFSET', parseFloat(e.target.value))}
                                />
                                <Input
                                    type={'number'}
                                    label={t('Järgmine pakkumise number')}
                                    value={consts.ORDERNO}
                                    onChange={(e) => setField('ORDERNO', parseInt(e.target.value))}
                                />
                                <Input
                                    type={'number'}
                                    label={t('Järgmine toodangu number')}
                                    value={consts.PRODNO}
                                    onChange={(e) => setField('PRODNO', parseInt(e.target.value))}
                                />
                                <Input
                                    type={'number'}
                                    label={t('Järgmine arve number')}
                                    value={consts.INVOICENO}
                                    onChange={(e) => setField('INVOICENO', parseInt(e.target.value))}
                                />
                                <Input
                                    type={'number'}
                                    label={t('Välismaise aluse hind')}
                                    value={consts.PALLETPRICE}
                                    onChange={(e) => setField('PALLETPRICE', parseFloat(e.target.value))}
                                />
                            </div>
                            <div className='field-col'>
                                <Input
                                    type={'number'}
                                    label={t('Kodumaise aluse hind')}
                                    value={consts.PALLETPRICE_DOM}
                                    onChange={(e) => setField('PALLETPRICE_DOM', parseFloat(e.target.value))}
                                />
                                <Input
                                    type={'number'}
                                    label={t('Järgmine tellimuse number')}
                                    value={consts.TELLIMUSNO}
                                    onChange={(e) => setField('TELLIMUSNO', parseInt(e.target.value))}
                                />
                                <Input
                                    label={t('Tööpäeva algus')}
                                    value={consts.DSTART}
                                    onChange={(e) => setField('DSTART', e.target.value)}
                                />
                                <Input
                                    label={t('Tööpäeva lõpp')}
                                    value={consts.DEND}
                                    onChange={(e) => setField('DEND', e.target.value)}
                                />
                                <Input
                                    type={'number'}
                                    label={t('Järgmine saatelehe number')}
                                    value={consts.INVOICENO_DOM}
                                    onChange={(e) => setField('INVOICENO_DOM', parseInt(e.target.value))}
                                />
                            </div>
                        </div>
                        <div className='field-row'>
                            <div className='field-col'>
                                <h5>{ t('Töö kestvus') }</h5>
                                <Input
                                    label={t('1. värv (min)')}
                                    type={'number'}
                                    value={consts.COLOR1}
                                    onChange={(e) => setField('COLOR1', parseInt(e.target.value))}
                                />
                                <Input
                                    label={t('2. värv (min)')}
                                    type={'number'}
                                    value={consts.COLOR2}
                                    onChange={(e) => setField('COLOR2', parseInt(e.target.value))}
                                />
                                <Input
                                    label={t('Rotatsioon')}
                                    type={'number'}
                                    value={consts.ROTAT}
                                    onChange={(e) => setField('ROTAT', parseInt(e.target.value))}
                                />
                                <Input
                                    label={t('Ajakoef. (%)')}
                                    type={'number'}
                                    value={consts.KOEF}
                                    onChange={(e) => setField('KOEF', parseInt(e.target.value))}
                                />
                            </div>
                            <div className='field-col'>
                                <h5>{ t('E-mail') }</h5>
                                <Input
                                    label={t('SMTP Server')}
                                    value={consts.SMTP_SERVER}
                                    onChange={(e) => setField('SMTP_SERVER', e.target.value)}
                                />
                                <Input
                                    label={t('DNS Server')}
                                    value={consts.DNS}
                                    onChange={(e) => setField('DNS', e.target.value)}
                                />
                                <Input
                                    label={t('Port')}
                                    type={'number'}
                                    value={consts.PORT}
                                    onChange={(e) => setField('PORT', parseInt(e.target.value))}
                                />
                                <Input
                                    label={t('SSL')}
                                    value={consts.SSL_TYPE}
                                    onChange={(e) => setField('SSL_TYPE', e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    :
                    <div className='empty-result'>
                        <p>{ t('Parameetreid ei leitud') }</p>
                    </div>
                }
            </div>
        </div>
    )
}

export default ConstantsScreen