import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Input from './Input'
import { useMutation, useQuery } from '@apollo/client'
import SelectInput from './SelectInput'
import Checkbox from './Checkbox'
import { ConstructorConfirm, UpdateOrderRow } from '../graphql/mutations'
import { Constants } from '../util/const'
import { calculateArea, calculateWeight, getSpread, getMaterialPrice, getConsumption, getMaterialTotalPrice, calculatePrice, calculateDelta } from '../util/calc'
import BoxMaterialFields from './BoxMaterialFields'
import BoxWorkflow from './BoxWorkflow'
import { GetOrderRow, GetOrderRowByName, GetUsers } from '../graphql/queries'
import BoxColorFields from './BoxColorFields'
import BoxProjectWorkflow from './BoxProjectWorkflow'
import Button from './Button'
import FilesUpload from './FilesUpload'
import { useAuth } from '../providers/Auth'
import { formatDate } from '../util/format'
import { useNotification } from '../providers/Notification'
import debounce from 'lodash.debounce'

const initialBoxState = {
    AMOUNT: '',
    CLASSTYPE: undefined,
    BOX: undefined,
    BOXNAME: '',
    PRINT: undefined,
    ROTATS: false,
    FACTORY: undefined,
    MATERIAL: undefined,
    FPRICE: '',
    LPRICE: '',
    PRODUCTION_CODE: '',
    L: '',
    B: '',
    H: '',
    H_EXTRA: '',
    AL: '',
    AB: '',
    MAT_PRICE: '',
    DENSITY: '',
    PROFIT: '',
    TRANSPORT: '',
    PER_SHEET: '',
    KLISHEE: '',
    MATRIITS: '',
    COMMENT: '',
    COLOR1: '',
    COLOR2: '',
    COLOR3: '',
    COLOR4: '',
    PANTONE1: '',
    PANTONE2: '',
    PANTONE3: '',
    PANTONE4: '',
    SLITTER: '',
    FLEXO: '',
    TIGEL: '',
    SRE: '',
    INLINE: '',
    SLOTTER: '',
    KLIIM: '',
    ALIIM: '',
    _3LIIM: '',
    LAMINAATOR: '',
    TOOTLEMINE: '',
    EKSTSEN: '',
    AFD: '',
    FPR: '',
    PREMAID: '',
    TITAN: '',
    VERONA: '',
    MAT_TYPE: '',
    TT_L: '',
    TT_B: '',
    LP_L: '',
    LP_B: '',
}

const matTypes = [
    {
        value: 'B',
        label: 'B',
    },
    {
        value: 'C',
        label: 'C',
    },
    {
        value: 'E',
        label: 'E',
    },
    {
        value: 'BB',
        label: 'BB',
    },
    {
        value: 'BC',
        label: 'BC',
    },
    {
        value: 'BE',
        label: 'BE',
    },
]

const BoxFields = ({ orderRowId, clientId, boxes, classes, factories, materials, prints, onProductionClick }) => {

    const { t } = useTranslation()
    const { user } = useAuth()
    const { dispatch } = useNotification()
    const [loading, setLoading] = useState(false)
    const [box, setBox] = useState(initialBoxState)
    const [factoryMaterials, setFactoryMaterials] = useState([])
    const [currentTab, setCurrentTab] = useState('order')
    const [constructors, setConstructors] = useState([])

    const [updateOrderRow] = useMutation(UpdateOrderRow)
    const [constructorConfirm] = useMutation(ConstructorConfirm)
    
    useQuery(GetOrderRow, {
        fetchPolicy: 'no-cache',
        variables: {
            id: parseInt(orderRowId),
        },
        onCompleted: (data) => {
            let boxItem = initialBoxState

            if (data?.getOrderRow) {
                const rowData = data.getOrderRow
                const factory = factories.find(x => x.value === rowData['FACTORY'])
                const material = materials.find(x => x.MARK === rowData['MATERIAL'])

                if (materials && factory) {
                    const materialItems = materials.reduce((acc, curr) => {
                        if (curr.FACTORY === factory.value) {
                            acc.push(curr)
                        }
                        return acc
                    }, [])
        
                    setFactoryMaterials(materialItems)
                }

                const classType = classes.find(x => x.ID === rowData['CLASSTYPE'])
                const print = prints.find(x => x.value === rowData['PRINT'])
                const boxType = boxes.find(x => x.NAME === rowData['BOX'])
                const matType = matTypes.find(x => x.value === rowData['MAT_TYPE'])
    
                boxItem = {
                    ...boxItem,
                    ...rowData,
                    FACTORY: factory,
                    MATERIAL: material,
                    CLASSTYPE: classType,
                    PRINT: print,
                    BOX: boxType,
                    MAT_TYPE: matType,
                }
            }
            
            setBox(boxItem)
        },
    })
    
    useQuery(GetUsers, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            const { getUsers } = data
            if (getUsers?.length < 1) return
            setConstructors(getUsers.filter(x => x.USERCODE > 0))
        }
    })

    const { refetch: getOrderRowByName } = useQuery(GetOrderRowByName, {
        skip: true,
        fetchPolicy: 'no-cache',
    })

    useEffect(() => {
        return () => {
            setBox(initialBoxState)
        }
    }, [])

    const setField = (field, value) => {
        const data = {
            ...box,
            [field]: value,
        }
        const newPrice = calculatePrice(data, materials)
        setBox({
            ...data,
            FPRICE: newPrice,
        })

        debouncedUpdate(field, value, newPrice)
    }

    const handleConstructorConfirm = async () => {
        setBox({
            ...box,
            CONSTR_DATE: new Date().toISOString(),
            CONSTR_NAME: user.name,
        })
        
        const res = await constructorConfirm({
            variables: {
                receiver: box.RECEIVER,
                id: parseInt(orderRowId),
            },
        })

        if (res?.data?.constructorConfirm === 'Success') {
            dispatch({
                type: 'ADD',
                payload: {
                    type: 'success',
                    content: t('Haldurile email saadetud')
                },
            })
        } else {
            dispatch({
                type: 'ADD',
                payload: {
                    type: 'error',
                    content: t('Emaili saatmine ebaõnnestus')
                },
            })
        }
    }

    const updatePrice = (price) => {
        setBox({
            ...box,
            FPRICE: price ? price : 0,
        })

        debouncedUpdate('FPRICE', parseFloat(price))
    }

    const updateLabourPrice = (price) => {
        setBox({
            ...box,
            LPRICE: price ? price : 0,
        })

        debouncedUpdate('LPRICE', parseFloat(price))
    }

    const updateTransportPrice = (price) => {
        setBox({
            ...box,
            TRANSPORT: price ? price : 0,
        })

        debouncedUpdate('TRANSPORT', parseFloat(price))
    }

    const updateProperty = async (field, value, price) => {
        try {
            const updateItem = {
                ...box,
                [field]: value,
            }

            const spread = getSpread(updateItem)

            const updateData = {
                [field]: value,
                AL: spread.length,
                AB: spread.width,
            }

            if (price) {
                updateData.FPRICE = parseFloat(price)
            }

            await updateOrderRow({
                variables: {
                    id: parseInt(orderRowId),
                    data: updateData
                },
            })
        } catch (err) {
            console.log('updateProperty:', err)
        }
    }

    const debouncedUpdate = useMemo( () => debounce(updateProperty, 300), [orderRowId, box])

    const changeFactory = async (factory) => {
        setBox({
            ...box,
            FACTORY: factory,
            MATERIAL: null,
        })
        
        const factoryName = factory ? factory.value : ''

        if (materials && factory) {
            const materialItems = materials.reduce((acc, curr) => {
                if (curr.FACTORY === factory.value) {
                    acc.push(curr)
                }
                return acc
            }, [])

            setFactoryMaterials(materialItems)
        }
        
        debouncedUpdate('FACTORY', factoryName)
    }

    const changeMaterial = async (material) => {
        const materialFields = {
            MATERIAL: material.MARK,
            MAT_PRICE: material.PRICE,
            DENSITY: material.DENSITY,
        }

        setBox({
            ...box,
            ...materialFields,
            MATERIAL: material,
        })

        await updateOrderRow({
            variables: {
                id: parseInt(orderRowId),
                data: materialFields,
            },
        })
    }

    const changeClassType = async (option) => {
        setBox({
            ...box,
            CLASSTYPE: option,
        })

        await debouncedUpdate('CLASSTYPE', option.ID)
    }

    const changeMatType = async (option) => {
        setBox({
            ...box,
            MAT_TYPE: option,
        })

        debouncedUpdate('MAT_TYPE', option.value)
    }

    const changePrint = async (option) => {
        setBox({
            ...box,
            PRINT: option,
        })

        debouncedUpdate('PRINT', option.value)
    }

    const changeBoxType = async (option) => {
        setBox({
            ...box,
            BOX: option,
        })

        updateProperty('BOX', option.NAME)

        const spread = getSpread(option)
        updateProperty('AB', spread.width)
        updateProperty('AL', spread.length)
    }

    const getWeight = () => {
        const spread = getSpread(box)
        if (!spread.length || !spread.width) return 0

        const material = materials.find(x => x.MARK === box.MATERIAL.MARK)
        return material ? calculateWeight(spread.length, spread.width, material.DENSITY) : 0
    }

    const calculateLabourPercentage = () => {
        if (!getSpread(box).length || !getSpread(box).width) return 0
        const materialTotal = getMaterialTotalPrice(box, materials)
        const price = materialTotal + Constants.LabourPlaceholder
        const profit = price * parseFloat(box.PROFIT) * 0.01

        return ((profit + Constants.LabourPlaceholder) / calculatePrice(box, materials) * 100).toFixed(4)
    }

    const calculateMaterialPercentage = () => {
        return parseFloat(getMaterialTotalPrice(box, materials) / calculatePrice(box, materials) * 100).toFixed(4)
    }

    const handleSearchDescription = async () => {
        try {
            const { data } = await getOrderRowByName({
                input: box.BOXNAME,
                clientId: parseInt(clientId),
                rowId: parseInt(orderRowId),
            })
            const prod = data?.getOrderRowByName
            if (prod) {
                const boxOption = boxes.find(x => x.NAME === prod.BOX)
                const classType = classes.find(x => x.ID === prod.CLASSTYPE)
                const materialOption = materials.find(x => x.MARK === prod.MATERIAL)

                const fields = {
                    L: prod.L,
                    B: prod.B,
                    H: prod.H,
                    CLASSTYPE: prod.CLASSTYPE,
                    FPRICE: prod.FPRICE,
                    H_EXTRA: prod.H_EXTRA,
                    BOX: prod.BOX,
                    FACTORY: prod.FACTORY,
                    PRINT: prod.PRINT,
                    DENSITY: prod.DENSITY,
                    MATERIAL: prod.MATERIAL,
                    AL: prod.AL,
                    AB: prod.AB,
                }

                await updateOrderRow({
                    variables: {
                        id: parseInt(orderRowId),
                        data: fields,
                    },
                })

                const newBox = {
                    ...box,
                    ...fields,
                    CLASSTYPE: classType,
                    BOX: boxOption,
                    FACTORY: {
                        value: prod.FACTORY,
                        label: prod.FACTORY,
                    },
                    MATERIAL: materialOption,
                    PRINT: {
                        value: prod.PRINT,
                        label: prod.PRINT,
                    },
                }

                setBox(newBox)
            }
        } catch (err) {
            console.log('Error getting production by name', err)
        }
    }

    const renderMaterialOptions = ({ innerProps, innerRef, data, isFocused, isSelected }) => {
        return (
            <div
                ref={innerRef}
                {...innerProps}
                className={
                    `select-option${isFocused ? ' select-option--focused' : ''}${isSelected ? ' select-option--selected' : ''} select-option--material-option`
                }
            >
                <div className='flex align-items--center'>
                    <span>
                        { t(data.MARK) }
                    </span>
                    <span>
                        { `${data.PRICE.toFixed(3)} €/m2` }
                    </span>
                </div>
            </div>
        )
    }

    const handlePriceCalc = () => {
        const newPrice = calculatePrice(box, materials)
        setField('FPRICE', parseFloat(newPrice))
    }

    const displayLB = () => {
        if (box.LP_B || box.LP_L) {
            return (
                <span>{ `${box.LP_L ? box.LP_L : '0'} x ${box.LP_B ? box.LP_B : '0'} K` }</span>
            )
        }

        if (box.BOX && (box.BOX.NAME === 'Stants' || !box.BOX.FORMULA_L)) {
            return (
                <div className='box-fields--spread-input'>
                    <Input
                        type={'number'}
                        value={box.AL || '0'}
                        placeholder={t('Pikkus')}
                        onChange={(e) => setField('AL', parseInt(e.target.value))}
                    />
                    <span>{ ' x ' }</span>
                    <Input
                        type={'number'}
                        value={box.AB || '0'}
                        placeholder={t('Laius')}
                        onChange={(e) => setField('AB', parseInt(e.target.value))}
                    />
                </div>
            )
        }

        return (
            <span>{ `${getSpread(box).length} x ${getSpread(box).width}` }</span>
        )
    }

    const handleProductionClick = () => {
        if (onProductionClick) onProductionClick()
    }
    
    if (!orderRowId) return (
        <div className='empty-result'>
            <span>{ t('Karpi pole valitud') }</span>
        </div>
    )

    if (loading) return (
        <div className='empty-result'>
            <span>{ t('Laen karbi andmeid') }</span>
        </div>
    )

    return (
        <div className='box-fields--tabs'>
            <div className='box-fields--tabs-header'>
                <div
                    className={`box-fields--tabs-header-item ${currentTab === 'order' ? 'active' : ''}`}
                    onClick={() => setCurrentTab('order')}
                >
                    { t('Tellimus') }
                </div> 
                <div
                    className={`box-fields--tabs-header-item ${currentTab === 'material' ? 'active' : ''}`}
                    onClick={() => setCurrentTab('material')}
                >
                    { t('Materjal') }
                </div>
                <div
                    className={`box-fields--tabs-header-item ${currentTab === 'files' ? 'active' : ''}`}
                    onClick={() => setCurrentTab('files')}
                >
                    { t('Failid') }
                </div>
                <div
                    className={`box-fields--tabs-header-item ${currentTab === 'workflow' ? 'active' : ''}`}
                    onClick={() => setCurrentTab('workflow')}
                >
                    { t('Töökäik') }
                </div>
                <div
                    className={`box-fields--tabs-header-item ${currentTab === 'project' ? 'active' : ''}`}
                    onClick={() => setCurrentTab('project')}
                >
                    { t('Projekt') }
                </div>
            </div>
            <div className={`box-fields--tabs-content box-fields--tabs-content--order${currentTab === 'order' ? ' show' : ''}`}>
                <div className='box-fields box-fields--main'>
                    <div className='field-row'>
                        <div className='field-col'>
                            <div className='box-fields--row'>
                                <Input
                                    placeholder={t('Kogus')}
                                    label={`${t('Kogus')}:`}
                                    value={box.AMOUNT}
                                    type={'number'}
                                    onChange={(e) => setField('AMOUNT', parseInt(e.target.value))}
                                />
                                <SelectInput
                                    label={`${t('Klass')}:`}
                                    options={classes}
                                    getOptionLabel={(option) => option.NAME}
                                    getOptionValue={(option) => option.NAME}
                                    onChange={changeClassType}
                                    value={box.CLASSTYPE}
                                />
                            </div>
                            <SelectInput
                                    label={`${t('Tüüp')}:`}
                                    options={boxes}
                                    onChange={changeBoxType}
                                    value={box.BOX}
                                    getOptionLabel={(option) => option.NAME}
                                    getOptionValue={(option) => option.NAME}
                            />
                            <div className='box-fields--description'>
                                <Input
                                    placeholder={t('Toote kirjeldus')}
                                    label={`${t('Toote kirjeldus')}:`}
                                    value={box.BOXNAME}
                                    onChange={(e) => setField('BOXNAME', e.target.value)}
                                />
                                <Button
                                    label={t('Täida andmed')}
                                    onClick={handleSearchDescription}
                                />
                            </div>
                            <div className='box-fields--row'>
                                <SelectInput
                                    label={`${t('Trükk')}:`}
                                    options={prints}
                                    onChange={changePrint}
                                    value={box.PRINT}
                                />
                                <Checkbox
                                    label={`${t('Rotatsioon')}`}
                                    value={box.ROTATS === 1}
                                    onChange={(e) => setField('ROTATS', e.target.checked ? 1 : 0)}
                                />
                            </div>
                            <div className='box-fields--row'>
                                <SelectInput
                                    label={`${t('Materjali tehas')}:`}
                                    options={factories}
                                    onChange={changeFactory}
                                    value={box.FACTORY}
                                />
                            </div>
                            <div className='box-fields--material'>
                                <SelectInput
                                    label={`${t('Materjal')}:`}
                                    options={factoryMaterials}
                                    getOptionLabel={(option) => option.MARK}
                                    getOptionValue={(option) => option.MARK}
                                    onChange={changeMaterial}
                                    value={box.MATERIAL}
                                    components={{
                                        Option: renderMaterialOptions,
                                    }}
                                />
                                <div className='box-fields--material-price'>
                                    { `${getMaterialPrice(box.MATERIAL?.MARK, box.FACTORY?.value, materials)} €/m2` }
                                </div>
                            </div>
                        </div>
                        <div className='field-col'>
                            <div className='box-fields--measurements'>
                                <span className='input-label'>{ `${t('Mõõdud (LxBxH)')}:` }</span>
                                <div className='box-fields--measurements-fields'>
                                    <Input
                                        placeholder={t('Pikkus')}
                                        value={box.L}
                                        type={'number'}
                                        onChange={(e) => setField('L', parseInt(e.target.value))}
                                    />
                                    <Input
                                        placeholder={t('Laius')}
                                        value={box.B}
                                        type={'number'}
                                        onChange={(e) => setField('B', parseInt(e.target.value))}
                                    />
                                    <Input
                                        placeholder={t('Kõrgus')}
                                        value={box.H}
                                        type={'number'}
                                        onChange={(e) => setField('H', parseInt(e.target.value))}
                                    />
                                    <span className='divider'>/</span>
                                    <Input
                                        placeholder={t('')}
                                        value={box.H_EXTRA}
                                        type={'number'}
                                        onChange={(e) => setField('H_EXTRA', e.target.value)}
                                    />
                                </div>
                                <div className='box-fields--measurement box-fields--spread'>
                                    <span>{ `${t('Laotus (LxB)')}:` }</span>
                                    { box.L && box.B &&
                                        <span className='box-fields--spread-result'>
                                            {
                                                displayLB()
                                            }
                                            <span>{ `${box.PER_SHEET} ${t('tk./lehest')}` }</span>
                                        </span>
                                    }
                                </div>
                                <div className='box-fields--calculations'>
                                    <div className='box-fields--measurement box-fields--area'>
                                        <span>{ `${t('Pindala')}:` }</span>
                                        { box.L && box.B &&
                                            <span className='box-fields--area-result'>
                                                { `${calculateArea(getSpread(box).length, getSpread(box).width).toFixed(4) || '0'} m2` }
                                            </span>
                                        }
                                    </div>
                                    <div className='box-fields--measurement box-fields--consumption'>
                                        <span>{ `${t('Kulunorm')}:` }</span>
                                        { box.L && box.B &&
                                            <span className='box-fields--consumption-result'>
                                                { `${getConsumption(box)} m2` }
                                            </span>
                                        }
                                    </div>
                                    <div className='box-fields--measurement box-fields--material-total'>
                                        <span>{ `${t('Mat. maksumus')}:` }</span>
                                        { box.L && box.B && box.MATERIAL &&
                                            <span className='box-fields--material-total-result'>
                                                { `${getMaterialTotalPrice(box, materials)} EUR` }
                                            </span>
                                        }
                                    </div>
                                    <div className='box-fields--measurement box-fields--weight'>
                                        <span>{ `${t('Kaal')}:` }</span>
                                        { box.L && box.B && box.MATERIAL &&
                                            <span className='box-fields--weight-result'>
                                                { `${getWeight()}g` }
                                            </span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='field-row field-row--calculations'>

                    <div className='box-fields--totals'>
                        <div className='box-price-fields'>
                            <div className='box-price-fields--items'>
                                <div className='box-price-fields--item'>
                                    <Button
                                        className={'calculate-price'}
                                        label={t('Arvuta hind')}
                                        onClick={handlePriceCalc}
                                    />
                                </div>
                                <div className='box-price-fields--item'>
                                    <Input
                                        type={'number'}
                                        label={ `${t('Töö kulu')}:` }
                                        value={box.LPRICE}
                                        onChange={(e) => updateLabourPrice(e.target.value)}
                                    />
                                </div>
                                <div className='box-price-fields--item'>
                                    <Input
                                        type={'number'}
                                        label={ `${t('Kasum')}:` }
                                        value={box.PROFIT}
                                        onChange={(e) => setField('PROFIT', parseFloat(e.target.value))}
                                        suffix={'%'}
                                    />
                                </div>
                                <div className='box-price-fields--item'>
                                    <Input
                                        type={'number'}
                                        label={ `${t('Hind')}:` }
                                        value={box.FPRICE}
                                        onChange={(e) => updatePrice(e.target.value)}
                                        suffix={'EUR'}
                                    />
                                </div>
                                <div className='box-price-fields--item'>
                                    <span className='input-label'>{ `${t('Materjal')} / ${t('Hind')}:` }</span>
                                    <span>{ calculateMaterialPercentage() > 0 ? calculateMaterialPercentage() : 0 }</span>
                                </div>
                                <div className='box-price-fields--item'>
                                    <span className='input-label'>{ `${t('Töö + Kasum / Hind')}:` }</span>
                                    <span>{ calculateLabourPercentage() }</span>
                                </div>
                                <div className='box-price-fields--item'>
                                    <span className='input-label'>{ `${t('Delta')}:` }</span>
                                    <span>{ calculateDelta(box, materials) }</span>
                                </div>
                                <div className='box-price-fields--item'>
                                    <Input
                                        type={'number'}
                                        label={ `${t('Transpordi hind')}:` }
                                        value={box.TRANSPORT}
                                        onChange={(e) => updateTransportPrice(e.target.value)}
                                    />
                                </div>
                            </div>
                            <Button
                                label={t('Tootmisesse')}
                                onClick={handleProductionClick}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`box-fields--tabs-content${currentTab === 'material' ? ' show' : ''}`}>
                <BoxMaterialFields
                    color1={box.COLOR1}
                    color2={box.COLOR2}
                    color3={box.COLOR3}
                    color4={box.COLOR4}
                    pantone1={box.PANTONE1}
                    pantone2={box.PANTONE2}
                    pantone3={box.PANTONE3}
                    pantone4={box.PANTONE4}
                    matrix={box.MATRIITS}
                    perSheet={box.PER_SHEET}
                    printingPress={box.KLISHEE}
                    comment={box.COMMENT}
                    onColorChange={setField}
                    onMatrixChange={setField}
                    onPerSheetChange={setField}
                    onPrintingPressChange={setField}
                    onCommentChange={setField}
                />
            </div>
            <div className={`box-fields--tabs-content${currentTab === 'files' ? ' show' : ''}`}>
                <FilesUpload orderRowId={orderRowId} />
            </div>
            <div className={`box-fields--tabs-content box-fields--tabs-workflow ${currentTab === 'workflow' ? ' show' : ''}`}>
                <BoxWorkflow
                    onCheckboxChange={setField}
                    checkboxes={[
                        {
                            field: 'SLITTER',
                            value: box.SLITTER,
                        },
                        {
                            field: 'FLEXO',
                            value: box.FLEXO,
                        },
                        {
                            field: 'TIGEL',
                            value: box.TIGEL,
                        },
                        {
                            field: 'SRE',
                            value: box.SRE,
                        },
                        {
                            field: 'INLINE',
                            value: box.INLINE,
                        },
                        {
                            field: 'SLOTTER',
                            value: box.SLOTTER,
                        },
                        {
                            field: 'KLIIM',
                            value: box.KLIIM,
                        },
                        {
                            field: 'ALIIM',
                            value: box.ALIIM,
                        },
                        {
                            field: '_3LIIM',
                            value: box._3LIIM,
                        },
                        {
                            field: 'LAMINAATOR',
                            value: box.LAMINAATOR,
                        },
                        {
                            field: 'TOOTLEMINE',
                            value: box.TOOTLEMINE,
                        },
                        {
                            field: 'EKSTSEN',
                            value: box.EKSTSEN,
                        },
                        {
                            field: 'AFD',
                            value: box.AFD,
                        },
                        {
                            field: 'FPR',
                            value: box.FPR,
                        },
                        {
                            field: 'PREMAID',
                            value: box.PREMAID,
                        },
                        {
                            field: 'TITAN',
                            value: box.TITAN,
                        },
                        {
                            field: 'VERONA',
                            value: box.VERONA,
                        },
                    ]}
                /> 
            </div>
            <div className={`box-fields--tabs-content box-fields--tabs-project${currentTab === 'project' ? ' show' : ''}`}>
                <div className='box-fields'>
                    <div className='box-fields--box'>
                        <div className='field-row'>
                            <div className='field-col'>
                                <SelectInput
                                    label={`${t('Klass')}:`}
                                    options={classes}
                                    getOptionLabel={(option) => option.NAME}
                                    getOptionValue={(option) => option.NAME}
                                    onChange={changeClassType}
                                    value={box.CLASSTYPE}
                                />
                                <Input
                                    placeholder={t('Kogus')}
                                    label={`${t('Kogus')}:`}
                                    value={box.AMOUNT}
                                    onChange={(e) => setField('AMOUNT', parseInt(e.target.value))}
                                    type={'number'}
                                />
                                <SelectInput
                                    label={`${t('Tüüp')}:`}
                                    options={boxes}
                                    onChange={changeBoxType}
                                    value={box.BOX}
                                    getOptionLabel={(option) => option.NAME}
                                    getOptionValue={(option) => option.NAME}
                                />
                                <SelectInput
                                    label={`${t('Trükk')}:`}
                                    options={prints}
                                    onChange={changePrint}
                                    value={box.PRINT}
                                />
                                <SelectInput
                                    label={`${t('Materjal')}:`}
                                    options={matTypes}
                                    onChange={changeMatType}
                                    value={box.MAT_TYPE}
                                />
                                <span className='input-label'>{ `${t('Mõõdud')}:` }</span>
                                <div className='box-fields--measurements-fields'>
                                    <Input
                                        placeholder={t('Pikkus')}
                                        value={box.L}
                                        onChange={(e) => setField('L', parseInt(e.target.value))}
                                        type={'number'}
                                    />
                                    <Input
                                        placeholder={t('Laius')}
                                        value={box.B}
                                        onChange={(e) => setField('B', parseInt(e.target.value))}
                                        type={'number'}
                                    />
                                    <Input
                                        placeholder={t('Kõrgus')}
                                        value={box.H}
                                        onChange={(e) => setField('H', parseInt(e.target.value))}
                                        type={'number'}
                                    />
                                </div>
                                <span>{ `${t('Terast terani (LxB)')}:` }</span>
                                <div className='box-fields--measurements-fields'>
                                    <Input
                                        value={box.TT_L}
                                        onChange={(e) => setField('TT_L', parseInt(e.target.value))}
                                        type={'number'}
                                    />
                                    <Input
                                        value={box.TT_B}
                                        onChange={(e) => setField('TT_B', parseInt(e.target.value))}
                                        type={'number'}
                                    />
                                </div>
                                <span>{ `${t('LP toorik (LxB)')}:` }</span>
                                <div className='box-fields--measurements-fields'>
                                    <Input
                                        value={box.LP_L}
                                        onChange={(e) => setField('LP_L', parseInt(e.target.value))}
                                        type={'number'}
                                    />
                                    <Input
                                        value={box.LP_B}
                                        onChange={(e) => setField('LP_B', parseInt(e.target.value))}
                                        type={'number'}
                                    />
                                    <span className='divider'>/</span>
                                    <Input
                                        value={box.PER_SHEET}
                                        onChange={(e) => setField('PER_SHEET', parseInt(e.target.value))}
                                        type={'number'}
                                    />
                                </div>
                            </div>
                            <div className='field-col'>
                                <BoxColorFields
                                    color1={box.COLOR1}
                                    color2={box.COLOR2}
                                    color3={box.COLOR3}
                                    color4={box.COLOR4}
                                    pantone1={box.PANTONE1}
                                    pantone2={box.PANTONE2}
                                    pantone3={box.PANTONE3}
                                    pantone4={box.PANTONE4}
                                    onColorChange={setField}
                                />
                            </div>
                            <div className='field-col'>
                                <BoxProjectWorkflow
                                    onFieldChange={setField}
                                    tigel={box.TIGEL}
                                    verona={box.VERONA}
                                    titan={box.TITAN}
                                    sre={box.SRE}
                                    inline={box.INLINE}
                                    slotter={box.SLOTTER}
                                    flexo={box.FLEXO}
                                />
                            </div>
                        </div>
                        <div className='field-row'>
                            <div className='field-col'>
                                <div className="box-fields--constructor">
                                    <div>
                                        <p>{ `${t('Konstruktor')}:` }</p>
                                    </div>
                                    <div>
                                        <p>{ box.CONSTR_NAME }</p>
                                        <p>{ formatDate(new Date(box.CONSTR_DATE)) }</p>
                                    </div>
                                </div>
                            </div>
                            <div className='field-col'>
                                <SelectInput
                                    label={`${t('Vali saaja')}:`}
                                    options={constructors}
                                    onChange={(option) => setField('RECEIVER', option.EMAIL)}
                                    value={constructors.find(x => x.EMAIL === box.RECEIVER)}
                                    getOptionLabel={(option) => option.RNAME}
                                    getOptionValue={(option) => option.EMAIL}
                                />
                                <Button
                                    label={t('Kinnita')}
                                    onClick={handleConstructorConfirm}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BoxFields