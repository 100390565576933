import ReactDOM from 'react-dom';
import React, { useEffect } from 'react';

const Modal = ({ show, close, title, children, className, renderActions, renderTabs }) => {

  useEffect(() => {
    document.body.addEventListener('keyup', handleEsc)

    return () => {
      document.body.removeEventListener('keyup', handleEsc)
    }
  }, [])

  const handleEsc = (e) => {
    if (e.key === 'Escape') {
      close()
    }
  }

  return ReactDOM.createPortal(
    <>
      { show &&
        <>
          <div onClick={close} className="modal-background"></div>
          <div
            className={`modal-inner${className ? ' ' + className : ''}`}
          >
            <div className='modal-header'>
              <div className='modal-header--inner'>
                <button className="modal-close" onClick={close}></button>
                <h2 className="modal-title">{title}</h2>
              </div>
              { renderTabs ? renderTabs() : <></>}
            </div>
            <div className="modal-content">{children}</div>
            {
              renderActions ?
              renderActions()
              :
              <></>
            }
          </div>
        </>
      }
    </>,
    document.getElementById('modal'),
  );
};

export default Modal;
